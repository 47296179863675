<template>
  <div class="h-full flex justify-center items-center relative">
    <div v-if="this.loaderBefore" class="h-full w-full flex flex-col justify-center items-center bg-white absolute z-10">
      <i class="fi fi-rr-spinner flex justify-center items-center text-6xl text-primario animate-spin"></i>
      <p class="w-full max-w-xs text-center mt-5 animate-bounce text-xl">Estamos cargando esta linda familia para ti</p>
    </div>
    <div>
      <div class="flex items-center">
        <img class="mr-5 w-12" src="../../../../assets/logo.png" alt="">
        <p class="text-5xl text-texto font-bold">PROXIMAMENTE</p>
      </div>
      <div class="mt-5 flex justify-center">
        <button @click="$emit('closeCategory')" class="py-2 px-10 flex items-center text-primario border border-primario rounded-xl">
          <i class="mr-2 flex items-center text-3xl fi fi-rr-arrow-small-left"></i>
          <p class="text-xl">Atras</p>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../../supabase/init"
export default {
  data() {
    return {
      loaderBefore: false,
      currentUser: {}
    }
  },

  async mounted() {
    this.loaderBefore = true
    await this.getUser()
    
  },

  methods: {
    async getUser(){
      let res = await supabase.from('agencies').select('*').eq('id', `${this.userAuth.user.id}`)
      this.currentUser = res.data[0]
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    },
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    },
  }
}
</script>
<style>
  
</style>