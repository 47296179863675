<template>
  <div class="pt-8 w-80 h-screen grid shadow-lg overflow-y-auto overflow-x-hidden" style="grid-template-rows: auto 1fr auto;">
    <div class="mb-8 px-8 w-80 h-16 grid justify-center items-center" style="grid-template-columns: auto 1fr;">
      <div v-if="this.src">
        <img :src="this.src" alt="profile" class="w-12 h-12 rounded-full border border-primario border-opacity-20 object-cover">
      </div>
      <div v-else class="w-12 h-12 flex justify-center items-center rounded-full bg-opacity-50 object-cover shadow-lg border border-primario border-opacity-20">
        <i class="flex justify-center items-center text-2xl font-bold text-primario fi fi-rr-user"></i>
      </div>
      <div v-if="this.res1">
        <p v-if="this.currentUser.fullname == null" class="pl-5 w-52 text-2xl text-texto font-bold truncate">-----------</p>
        <p v-else class="pl-5 w-52 text-2xl text-texto font-bold truncate">{{this.fullnameView}}</p>
      </div>
      <div v-else>
        <p class="pl-5 w-52 text-2xl text-texto font-bold truncate">{{this.fullnameView}}</p>
        <p class="pl-5 w-52 text-primario truncate">{{this.currentUser.rol}}</p>
      </div>
    </div>
    <div class="p-8 ">
      <div class="mb-7">
        <p class="mb-4 text-sm text-gray-500">Menu</p>
        <ul>
          <li class="mb-3 select-none" @click="currentColorRoute" v-for="(item, index) in this.optionsMenu" :key="index">
            <router-link :to="`/dashboard/${item.view}`" class="flex items-cente ">
              <i v-if="item.view == this.currentRoute" class="mr-5 flex items-center text-primario fi" :class="item.icon"></i>
              <i v-else class="mr-5 flex items-center text-texto fi" :class="item.icon"></i>
              <p v-if="item.view == this.currentRoute" class="text-primario ">{{item.name}}</p>
              <p v-else class="text-texto">{{item.name}}</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="p-8 max-w-xs flex flex-col justify-end items-center bg-white rounded-t-2xl z-10 sticky bottom-0" style="box-shadow: 0px -1px 19px 0px rgba(0,0,0,0.2); height: max-content;">

      <div class="flex items-center justify-between w-full ">
        <div @click="signOut" class="my-2 py-2 px-4 w-full flex justify-center cursor-pointer border-2 border-transparent hover:bg-red-300 rounded-lg hover:text-gris border-red-300 bg-transparent transform text-red-500 transition-all duration-300 ease-in-out">
          <i class="fi fi-rr-exit flex justify-center items-center mr-4"></i>  <p>Cerrar sesion</p>
        </div>     
      </div>
    </div>
  </div>
</template>
<script>
import router from '@/router'
import { supabase } from "../../supabase/init"

export default {
  /* props:{
    urlVencimiento: String,
  }, */
  data() {
    return {
      optionsMenu: [
        {name: "Mis Clientes", view: "clientes", icon: "fi-rr-users"},
        {name: "Vencimientos", view: "vencimientos", icon: "fi-rr-clock"},
        {name: "Mi perfil", view: "mi-perfil", icon: "fi fi-rr-user"},
        {name: "Asistencia", view: "asistencia", icon: "fi-rr-exclamation"},
        {name: "Notificaciones", view: "notificaciones", icon: "fi-rr-comment-alt"},
        {name: "Tickets", view: "tickets", icon: "fi-rr-ticket"},
        {name: "Mi equipo", view: "equipo", icon: "fi-rr-users-alt"},
        {name: "Produccion", view: "produccion", icon: "fi-rr-users-alt"},
        {name: "Ajustes", view: "ajustes", icon: "fi-rr-settings-sliders"},
      ],
      image:null,
      currentRoute: "",
      currentUser: {},

      tasksToDo:[],
      tasksDoing:[],
      tasksDone:[],

      res1: {},
      res2: {},

      currentAgency: {},
      fullnameView: '',

      src: "",
    }
  },

  async mounted() {
    await this.getUser()
    await this.downloadImage()
    this.currentRoute = this.$route.params.view
    this.currentColorRoute()
  },

  methods: {
    async getUser(){
      try{
        const { data, error } = await supabase.from('agencies').select('*').eq('id', this.userAuth.user.id)
        this.res1 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      }  

      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('id', this.userAuth.user.id)
        this.res2 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      } 

      if (this.res1) {
        this.currentUser = this.res1
        let name = this.currentUser.fullname.toUpperCase()
        this.fullnameView = name
      } else {
        this.currentUser = this.res2
        try{
          const { data, error } = await supabase.from('agencies').select('*').eq('id', this.currentUser.agency_id)
          this.currentAgency = data[0]
          let name = this.currentAgency.fullname.toUpperCase()
          this.fullnameView = name
          if(error) throw error;
        } catch (e){
          console.log(e.message)
        }  
      }
    },

    currentColorRoute(){
      this.currentRoute = this.$route.params.view
      this.$store.commit("save_route", this.currentRoute)
    },

    async downloadImage(){
      if (this.res1) {
        try {
          const { signedURL, error } = await supabase.storage.from('agencies').createSignedUrl(`${this.currentUser.id}/profile/imgprofile`, 60)
          this.imgProfile = {
            name: "imgprofile",
            url : signedURL
          }
          this.src = this.imgProfile.url
          if(error) throw error;
        } catch (error) {
          console.log(error.message)
        }
      } else {
        try {
          const { signedURL, error } = await supabase.storage.from('agencies').createSignedUrl(`${this.currentUser.agency_id}/profile/imgprofile`, 60)
          this.imgProfile = {
            name: "imgprofile",
            url : signedURL
          }
          this.src = this.imgProfile.url
          if(error) throw error;
        } catch (error) {
          console.log(error.message)
        }
      }
    },

    async signOut(){
      await supabase.auth.signOut()
      router.push("/login")
    },
  },


  computed:{
    userAuth(){
      return this.$store.state.userAuth
    },
  }
}
</script>
<style>
  
</style>