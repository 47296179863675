<template>
  <div class="p-10 h-screen overflow-y-auto relative">
    <div v-if="this.modalReset" class="w-full h-screen flex justify-center items-center bg-black bg-opacity-20 left-0 top-0 rounded-lg absolute z-10">
      <div class="p-8 w-96 bg-white rounded-lg">
        <p class="mb-3 text-lg text-primario text-center font-bold">Cambiar contraseña</p>
        <p class="mb-5 text-texto text-center">Se te enviara un link de confirmacion a tu correo electronico para realizar el cambio de contraseña.</p>
        <div class="mb-5 w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv" role="alert">
          <svg aria-hidden="true" :class="this.classText" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <div class="transition-all" :class="this.classText">
            <span class="font-medium">Alerta!</span>{{this.textAlert}}
          </div>
        </div>
        <div class="grid grid-cols-2">
          <button @click="this.modalReset = false" class="mr-1 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button v-if="this.viewLoader2 == 'reset'" @click="sendLink" class="ml-1 px-2 py-2 w-full text-white rounded-lg" :class="this.bg_button">Enviar</button>
          <button v-if="this.viewLoader2 == 'loader' || this.viewLoader2 == 'send' || this.viewLoader2 == 'error'" class="ml-1 w-full text-white rounded-xl" :class="this.bg_button2">
            <div v-if="this.viewLoader2 == 'loader'" class="px-2 py-2 w-full flex justify-center cursor-wait">
              <p class="mr-3">enviando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader2 == 'error'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
              <p class="mr-3">Error</p>
            </div>
            <div v-if="this.viewLoader2 == 'send'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
              <p class="mr-3">Enviado</p>
            </div>
          </button>
        </div>
      </div>
    </div>

    <autos v-else-if="this.viewClient == 'autos'" :currentClient="this.currentUser" :currentSection="this.policyVencimientos" @closeCategory="closeCategory"/>
    <vida v-else-if="this.viewClient == 'vida'" :currentClient="this.currentUser" :currentSection="this.policyVencimientos" @closeCategory="closeCategory"/>
    <hogar v-else-if="this.viewClient == 'hogar'" :currentClient="this.currentUser" :currentSection="this.policyVencimientos" @closeCategory="closeCategory"/>
    <salud v-else-if="this.viewClient == 'salud'" :currentClient="this.currentUser" :currentSection="this.policyVencimientos" @closeCategory="closeCategory"/>
    <generales v-else-if="this.viewClient == 'generales'" :currentClient="this.currentUser" :currentSection="this.policyVencimientos" @closeCategory="closeCategory"/>
    <cumplimiento v-else-if="this.viewClient == 'cumplimiento'" :currentClient="this.currentUser" :currentSection="this.policyVencimientos" @closeCategory="closeCategory"/>

    <div v-else>
      <div>
        <div class="mb-10 flex flex-col justify-center items-center">
          <div class="w-full">
            <div class="w-full flex justify-center">
              <div class="mb-5 h-40 w-40 flex justify-center items-center rounded-full bg-white border-4 border-white shadow-lg">
                <i class="flex justify-center items-center text-5xl font-bold text-primario fi fi-rr-user"></i>
              </div>
            </div>
            <div class="mb-5 w-full text-center">
              <p class="text-texto font-bold text-2xl">{{this.currentUser.first_name}} {{this.currentUser.second_name}} {{this.currentUser.first_last_name}} {{this.currentUser.second_last_name}}</p>
              <p class=" leading-none text-primario">{{this.currentUser.alias}}</p>
            </div>
          </div>
          
          <div class="flex justify-center">
            <div class="mr-5">
              <button @click="$emit('closeClient')" class="py-2 px-10 text-white bg-primario rounded-xl">Atras</button>
            </div>
            <div class="mr-5">
              <button @click="toggleData()" class=" py-2 px-5 flex items-center text-primario border border-primario rounded-xl">
                <p class="mr-3">Datos</p>
                <i v-if="this.classData == 'close'" class="flex justify-center items-center fi fi-rr-angle-down"></i>
                <i v-else class="flex justify-center items-center fi fi-rr-angle-up"></i>
              </button>
            </div>
            <div>
              <button v-if="this.viewLoader == 'update'" @click="updateInfo" class="ml-1 py-2 px-10 text-white rounded-xl" :class="this.bg_button">Actualizar</button>
              <button v-if="this.viewLoader == 'loader' || this.viewLoader == 'updated' || this.viewLoader == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button">
                <div v-if="this.viewLoader == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
                  <p class="mr-3">Actualizando</p>
                  <div class="flex justify-center items-center">
                    <span class="loader"></span>
                  </div>
                </div>
                <div v-if="this.viewLoader == 'updated'" class="px-10 py-2 w-full flex justify-center cursor-pointer">
                  <p class="">Actualizado</p>
                </div>
                <div v-if="this.viewLoader == 'error'" class="px-10 py-2 w-full flex justify-center cursor-pointer">
                  <p class="">error</p>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex-col w-4/5 mx-auto transition-all duration-1000" :class="this.classData">
          <div class="mb-10 grid grid-cols-2 w-full">
            <div class="flex flex-col w-full pr-3">
              <div class="grid grid-cols-2 w-full">
                <div class="flex flex-col w-full pr-3">
                  <label for="fullname" class="text-texto font-light text-sm">Primer nombre</label>
                  <input v-model="this.formData.first_name" type="text" id="fullname" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto"/>
                </div>
                <div class="flex flex-col w-full pl-3">
                  <label for="fullname" class="text-texto font-light text-sm">Segundo nombre</label>
                  <input v-model="this.formData.second_name" type="text" id="fullname" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto"/>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-full pr-3">
              <div class=" grid grid-cols-2 w-full">
                <div class="flex flex-col w-full pr-3">
                  <label for="fullname" class="text-texto font-light text-sm">Primer apellido</label>
                  <input v-model="this.formData.first_last_name" type="text" id="fullname" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto"/>
                </div>
                <div class="flex flex-col w-full pl-3">
                  <label for="fullname" class="text-texto font-light text-sm">Segundo apellido</label>
                  <input v-model="this.formData.second_last_name" type="text" id="fullname" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto"/>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-10 grid grid-cols-2 w-full">
            <div class="flex flex-col w-full pr-3">
              <label for="alias" class="text-texto font-light text-sm">Alias</label>
              <input v-model="this.formData.alias" type="text" id="alias" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto"/>
            </div>
            <div class="flex flex-col w-full pl-3">
              <label for="gender" class="text-texto font-light text-sm">Genero</label>
              <select v-model="this.formData.gender" name="gender" id="gender" class=" border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto rounded-t-lg">
                <option disabled value="seleccionar">Seleccionar</option>
                <option value="masculino">Masculino</option>
                <option value="femenino">femenino</option>
              </select>
            </div>
          </div>
          <div class="mb-10 grid grid-cols-2 w-full">
            <div class="flex flex-col w-full pr-3">
              <label for="type_document" class="text-texto font-light text-sm">Tipo de documento</label>
              <input v-model="this.formData.type_document" type="text" id="type_document" disabled class="bg-gray-100 border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto rounded-t-lg"/>
            </div>
            <div class="flex flex-col w-full pl-3">
              <label for="document" class="text-texto font-light text-sm">No. de documento</label>
              <input v-model="this.formData.document" type="text" id="document" disabled class="bg-gray-100 border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto rounded-t-lg"/>
            </div>
          </div>
          <div class="mb-10 grid grid-cols-2 w-full">
            <div class="flex flex-col w-full pr-3">
              <label for="contact_number" class="text-texto font-light text-sm">No. de contacto</label>
              <input v-model="this.formData.contact_number" type="text" id="contact_number" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto"/>
            </div>
            <div class="flex flex-col w-full pl-3">
              <label for="email" class="text-texto font-light text-sm">Correo electronico</label>
              <input v-model="this.formData.email" type="email" id="email" disabled class="bg-gray-100 border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto rounded-t-lg"/>
            </div>
          </div>
          <div class="mb-10 grid grid-cols-2 w-full">
            <div class="flex flex-col w-full pr-3">
              <label for="department_id" class="text-texto font-light text-sm">Departamento</label>
              <select @change="getCities()" v-model="this.formData.department_id" name="department_id" id="department_id" class=" border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto rounded-t-lg">
                <option disabled value="seleccionar">Seleccionar</option>
                <option v-for="(department, index) in this.departments" :key="index" :value="department.id">{{department.name.toUpperCase()}}</option>
              </select>
            </div>
            <div class="flex flex-col w-full pl-3">
              <label for="city_id" class="text-texto font-light text-sm">Ciudad</label>
              <select v-model="this.formData.city_id" name="city_id" id="city_id" class=" border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto rounded-t-lg">
                <option disabled value="seleccionar">Seleccionar</option>
                <option v-for="(city, index) in this.cities" :key="index" :value="city.id">{{city.name}}</option>
              </select>
            </div>
          </div>
          <div class="mb-10 grid grid-cols-2 w-full">
            <div class="flex flex-col w-full pl-3">
              <label for="date_of_birth" class="text-texto font-light text-sm">Fecha de nacimiento</label>
              <input v-model="this.formData.date_of_birth" type="date" id="date_of_birth" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto"/>
            </div>
          </div>
          <!-- <p class="mb-5 text-gris font-light my-5">Deseas actualizar tu contraseña?,<span @click="this.modalReset = true" class="text-primario font-bold cursor-pointer"> Actualizar contraseña</span></p> -->
          <div class="mb-5 w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv" role="alert">
            <svg aria-hidden="true" :class="this.classText" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <div class="transition-all" :class="this.classText">
              <span class="font-medium">Alerta!</span>{{this.textAlert}}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="p-10 w-full">
          <div v-if="this.sectionStatus == 'agregar'" class="mb-10 flex justify-between items-center">
            <div>
              <p class="text-texto text-3xl font-bold">Polizas</p>
            </div>
          </div>

          <div class="cards-container mb-10">
            <div v-for="(category, index) in this.categoriesPolicies" :key="index" class="flex flex-col justify-center items-center transform transition-all hover:scale-105 duration-300 ease-in-out cursor-pointer">
              <div @click="viewPolice(category.url)" class="h-32 xl:h-40 w-32 xl:w-40 bg-white shadow-lg rounded-2xl flex justify-center items-center">
                <img :src="category.img" class="w-14 xl:w-20 h-14 xl:h-20">
              </div>
              <p class="text-texto font-light xl:text-lg mt-3">{{category.name}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="p-10">
        <riesgos v-if="this.tableView =='riesgos'" :currentClient="this.currentUser" @changeSection="changeSection" @redirectSection="viewPolice"/>
        <polizas v-else-if="this.tableView =='polizas'" :currentClient="this.currentUser" @changeSection="changeSection" @redirectSection="viewPolice"/>
      </div>
    </div>

  </div>
</template>
<script>
import { supabase } from "../../../supabase/init"
import router from '@/router'
import Autos from '../Polizas/Autos/Autos.vue';
import Vida from '../Polizas/Vida/Vida.vue';
import Hogar from '../Polizas/Hogar/Hogar.vue';
import Salud from '../Polizas/Salud/Salud.vue';
import Generales from '../Polizas/Generales/Generales.vue';
import Cumplimiento from '../Polizas/Cumplimiento/Cumplimiento.vue';
import Riesgos from './Polizas-Riesgos/Riesgos.vue';
import Polizas from './Polizas-Riesgos/Polizas.vue';

export default {
  components: { Autos, Vida, Hogar, Salud, Generales, Riesgos, Polizas, Cumplimiento },
  
  props: {
    clientId: String,
  },
  data() {
    return {
      formData:{
        first_name: "",
        second_name: "",
        first_last_name: "",
        second_last_name: "",
        email: "",
        alias: "",
        gender: "",
        type_document: "",
        document: "",
        whatsapp: "",
        contact_number: "",
        date_of_birth: "",
        city_id: "seleccionar",
        department_id: "seleccionar",
        
      },

      viewLoader: 'update',
      bg_button: 'bg-primario',
      classDiv: "w-0 p-0",
      classText: "hidden",
      textAlert: "",

      viewLoader2: 'reset',
      bg_button2: 'bg-primario',
      classDiv2: "w-0 p-0",
      classText2: "hidden",
      textAlert2: "",

      classData: "close",
      currentUser: {},
      departments:[],
      cities:[],
      modalReset: false,
      policies: {},
      sectionStatus: "agregar",
      viewClient: '',
      tableView: 'polizas',

      categoriesPolicies:[
        {
          name:'Autos',
          url:'autos',
          img:'https://krsoztbtqokoxqpeajxe.supabase.co/storage/v1/object/sign/resousers/vehicles.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJyZXNvdXNlcnMvdmVoaWNsZXMucG5nIiwiaWF0IjoxNjY1MTE1MTIyLCJleHAiOjE5ODA0NzUxMjJ9.btr0u_I5V96NMZYZdSpBQ72jZMB3kP440LQV5cZUflE'
        },
        {
          name:'Vida',
          url:'vida',
          img:'https://krsoztbtqokoxqpeajxe.supabase.co/storage/v1/object/sign/resousers/health-insurance.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJyZXNvdXNlcnMvaGVhbHRoLWluc3VyYW5jZS5wbmciLCJpYXQiOjE2NjUxMTUxMjksImV4cCI6MTk4MDQ3NTEyOX0.gRmL6A8pdVHeKF5E1takb1PrUfFGqzFXBFrDU7QemMg'
        },
        {
          name:'Hogar',
          url:'hogar',
          img:'https://krsoztbtqokoxqpeajxe.supabase.co/storage/v1/object/sign/resousers/home.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJyZXNvdXNlcnMvaG9tZS5wbmciLCJpYXQiOjE2NjUxMTUxMjgsImV4cCI6MTk4MDQ3NTEyOH0.ioYhaYV6m91gsM-CEypy4oMryok8DHX4AgoJBCwyY58'
        },
        {
          name:'Salud',
          url:'salud',
          img:'https://krsoztbtqokoxqpeajxe.supabase.co/storage/v1/object/sign/resousers/first-aid-kit.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJyZXNvdXNlcnMvZmlyc3QtYWlkLWtpdC5wbmciLCJpYXQiOjE2NjUxMTUxMzEsImV4cCI6MTk4MDQ3NTEzMX0.EJCbYHYhQQvo7uEw-tySy6Upq_QjG8Jee2Od8lwHlcM'
        },
        {
          name:'Generales',
          url:'generales',
          img:'https://krsoztbtqokoxqpeajxe.supabase.co/storage/v1/object/sign/resousers/generales.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJyZXNvdXNlcnMvZ2VuZXJhbGVzLnBuZyIsImlhdCI6MTY2NjYyNjQ1NSwiZXhwIjoxOTgxOTg2NDU1fQ.xSwvrDBDZrttSCJDSgWmchtzm62i_vmA7prfnMCHrG8'
        },
        {
          name:'Cumplimiento',
          url:'cumplimiento',
          img:'https://krsoztbtqokoxqpeajxe.supabase.co/storage/v1/object/sign/resousers/cumplimiento.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJyZXNvdXNlcnMvY3VtcGxpbWllbnRvLnBuZyIsImlhdCI6MTY2NjYyNjQ2NSwiZXhwIjoxOTgxOTg2NDY1fQ.cg63yH8iDyLheOBQIOLLm2t6TF_ih7bbQHe9Nl3VFDw'
        },
      ],
    }
  },

  async mounted() {
    if (this.policyVencimientos) {
      await this.getUser()
      this.viewPolice(this.policyVencimientos.category)
    }
    await this.getUser()
    await this.getDepartments()
    await this.getCities()
  },

  methods: {
    async getUser(){
      let res = await supabase.from('clients_agencies').select('*').eq('id', `${this.clientId}`)
      this.currentUser = res.data[0]

      this.formData.first_name = this.currentUser.first_name
      this.formData.second_name = this.currentUser.second_name
      this.formData.first_last_name = this.currentUser.first_last_name
      this.formData.second_last_name = this.currentUser.second_last_name
      this.formData.email = this.currentUser.email
      this.formData.alias = this.currentUser.alias
      this.formData.gender = this.currentUser.gender
      this.formData.type_document = this.currentUser.type_document
      this.formData.document = this.currentUser.document
      this.formData.whatsapp = this.currentUser.whatsapp
      this.formData.contact_number = this.currentUser.contact_number
      this.formData.date_of_birth = this.currentUser.date_of_birth
      this.formData.department_id = this.currentUser.department_id
      this.formData.city_id = this.currentUser.city_id
    },

    async getDepartments(){
      try{
        const { data, error } = await supabase.from('departments').select('*').order('name', { ascending: true })
        this.departments = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getCities(){
      try{
        const { data, error } = await supabase.from('cities').select('*').eq('department_id', this.formData.department_id).order('name', { ascending: true })
        this.cities = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async updateInfo(){
      this.viewLoader = 'loader'

      if (this.formData.first_name != '' && this.formData.first_last_name != '' && this.formData.alias != '' && this.formData.gender != 'seleccionar' && this.formData.contact_number != '' && this.formData.date_of_birth != ''){
        try{
          let { data, error } = await supabase.from('clients_agencies').update({
            first_name: this.formData.first_name,
            second_name: this.formData.second_name,
            first_last_name: this.formData.first_last_name,
            second_last_name: this.formData.second_last_name,
            alias: this.formData.alias,
            gender: this.formData.gender,
            contact_number: this.formData.contact_number,
            date_of_birth: this.formData.date_of_birth,
            department_id: this.formData.department_id,
            city_id: this.formData.city_id,
          }).eq('id', this.currentUser.id)

          setTimeout(() => {
            this.viewLoader = 'updated'
            this.bg_button = 'bg-green-400'
    
            setTimeout(() => {
              this.viewLoader = 'update'
              this.bg_button = 'bg-primario'
              this.classDiv = "w-0 p-0"
              this.classText = "hidden"
              this.getUser()
            }, 3000);
          }, 1000);

          if(error) throw error;
        } catch(error){
          console.log(error.message)
        }
      } else {
        setTimeout(() => {
          this.viewLoader = 'error'
          this.bg_button = 'bg-red-400'
          this.classDiv = "w-72 p-4"
          this.classText = "block"
          this.textAlert = " No se admiten campos vacios."
  
          setTimeout(() => {
            this.viewLoader = 'update'
            this.bg_button = 'bg-primario'
            this.classDiv = "w-0 p-0"
            this.classText = "hidden"
          }, 3000);
        }, 1000);
      }
      
    },

    async sendLink(){
      this.viewLoader2 = 'loader'
      try {
        const { data, error } = await supabase.auth.api.resetPasswordForEmail(this.formData.email)
        setTimeout(() => {
          this.viewLoader2 = 'send'
          this.bg_button2 = 'bg-green-400'
          setTimeout(() => {
            this.viewLoader2 = 'reset'
            this.bg_button2 = 'bg-primario'
            this.modalReset = false
            
          }, 3000);
        }, 1000);
        
        if(error) throw error;
      } catch (error) {
        console.log(error.message);
      }
    },

    async getPolicies(){
      try{
        const { data, error } = await supabase.from('policies').select('*').eq('created_by', this.formData.id).order('created_at', { ascending: false })
        this.policies = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    openSectionCrear(){
      this.sectionStatus = "crear"
    },

    cancelar(){
      this.sectionStatus = "agregar"
    },

    toggleData(){
      if (this.classData == "close") {
        this.classData = "open"
      } else {
        this.classData = "close"
      }
    },

    viewPolice(url){
      this.viewClient = url
    },

    closeCategory(){
      this.viewClient = ''
    },

    changeSection(section){
      this.tableView = section
    },
  },

  computed: {
    policyVencimientos(){
      return this.$store.state.policyVencimientos
    },
  }
};
</script>
<style>
  .cards-container{
    width: 100%;
    display: grid;
    justify-items: center;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
    grid-gap: 20px;
  }
  @media (min-width: 1280px) {
    .cards-container{
      width: 100%;
      display: grid;
      justify-items: center;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      grid-gap: 50px;
    }
  }
  .close{
    height: 0px;
    
  }
  .close label {
    display: none;
  }
  .close input {
    display: none;
  }
  .close select {
    display: none;
  }
  .close p {
    display: none;
  }

  .open{
    height: max-content;
  }
  .open label {
    display: block;
  }
  .open input {
    display: block;
  }
  .open select {
    display: block;
  }
  .open p {
    display: block;
  }
</style>