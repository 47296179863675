<template >
  <div>
    <div>
      <p class="mb-5 text-texto text-3xl font-bold">Riesgos Totales</p>
    </div>
    <div class="mb-10 flex items-center justify-between">
      <div class="flex items-center">
        <div>
          <div class="mt-1 py-1 w-80">
            <label for="filter" class="pl-2 text-texto">Seleciona la categoria</label>
            <div class="py-1 w-full flex items-center border border-gray-300 rounded-xl">
              <select name="filter" id="filter" class="w-full border-none" v-model="this.documentFilter" @change="filterClient">
                <option selected disabled value="seleccionar">Seleccionar</option>
                <option value="Autos">Autos</option>
                <option value="Hogar">Hogar</option>
                <option value="Generales">Generales</option>
              </select>
            </div>
          </div>
          <!-- <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
            <input v-model="this.documentFilter" @keyup="toUpperCase" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
          </div> -->
          <p class="mt-1 text-red-500">{{this.alertFilter}}</p>
        </div>
        <div class="mt-6">
          <button  @click="filterClient" class="mx-2 py-2 px-2 text-primario border border-primario rounded-xl">
            <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
          </button>
        </div>
        <div class="mt-6">
          <button @click="removeFilter" v-if="this.filterStatus" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
            <p class="mr-2">Quitar filtro</p>
            <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
          </button>
        </div>
      </div>
      <div class="flex">
        <div class="mr-5">
          <button class="py-2 px-10 text-white bg-primario rounded-xl">Riesgos</button>
        </div>
        <div class="mr-5">
          <button @click="$emit('changeSection', 'polizas')" class="py-2 px-10 text-primario border border-primario bg-white rounded-xl">Polizas</button>
        </div>
      </div>
    </div>
    <div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-left">
          <thead class="text-texto bg-gray-50 ">
            <tr>
              <th scope="col" class="pr-6 py-3 text-center font-semibold">No.</th>
              <th scope="col" class="pr-6 py-3 text-center font-semibold">Nombre</th>
              <th scope="col" class="pr-6 py-3 text-center font-semibold">Categoria</th>
              <th scope="col" class="px-6 py-3 text-center font-semibold">Estado</th>
            </tr>
          </thead>
          <tbody v-if="this.risksFilter.length">
            <tr v-for="(risk, index) in this.risksFilter" :key="index" @click="redirectSection(risk)" class="bg-white text-texto border-b cursor-pointer">
              <td class="pr-6 py-4 text-center font-medium">{{index + 1}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{risk.fullname}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{risk.category}}</td>
              <td class="px-6 py-4 text-center">
                <div v-if="risk.status" class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">Activo</p></div>
                <div v-else class="py-1 px-4 rounded-xl bg-gray-100"><p class="text-gray-600">Inactivo</p></div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(risk, index) in this.risks" :key="index" @click="redirectSection(risk)" class="bg-white text-texto border-b cursor-pointer">
              <td class="pr-6 py-4 text-center font-medium">{{index + 1}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{risk.fullname}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{risk.category}}</td>
              <td class="px-6 py-4 text-center">
                <div v-if="risk.status" class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">Activo</p></div>
                <div v-else class="py-1 px-4 rounded-xl bg-gray-100"><p class="text-gray-600">Inactivo</p></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../../supabase/init"
export default {
  props: {
    currentClient: Object
  },
  data() {
    return {
      filterStatus: false,
      risks:[],
      risksFilter: [
        /* {
          license_plate_name: 'QWE123',
          product: 'SOAT',
          insurance_company: 'insurance_company',
          status: '+2 meses',
        } */
      ],
      documentFilter: 'seleccionar',
      alertFilter: ''
    }
  },

  async mounted() {
    setTimeout(async() => {
      await this.getRisksAutos()
      await this.getRisksHogar()
      await this.getRisksGenerales()
    }, 1000);
  },

  methods: {
    async getRisksAutos(){
      try{
        const { data, error } = await supabase.from('license_plates_agencies').select('*').eq('client_id', this.currentClient.id)
        data.forEach(async (risk)=> {
          risk.fullname = risk.license_plate
          risk.category = 'Autos'
          this.risks.unshift(risk)
        });

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getRisksHogar(){
      try{
        const { data, error } = await supabase.from('homes').select('*').eq('client_id', this.currentClient.id)
        data.forEach(async (risk)=> {
          risk.fullname = risk.address
          risk.category = 'Hogar'
          this.risks.unshift(risk)
        });

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getRisksGenerales(){
      try{
        const { data, error } = await supabase.from('general_risks').select('*').eq('client_id', this.currentClient.id)
        data.forEach(async (risk)=> {
          risk.fullname = risk.address
          risk.category = 'Generales'
          this.risks.unshift(risk)
        });

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    toUpperCase() {
        this.documentFilter = this.documentFilter.toUpperCase();
    },

    filterClient(){
      this.risksFilter = this.risks.filter(risk => risk.category == this.documentFilter)

      if (this.risksFilter.length) {
        this.filterStatus = true
      } else {
        let currenData = this.risks
        this.risks = []
        this.alertFilter = 'No se encontraron resultados'

        setTimeout(() => {
          this.risks = currenData
          this.alertFilter = ''
        }, 2000);
      }

    },

    removeFilter(){
      this.risksFilter = []
      this.documentFilter = 'seleccionar'
      this.filterStatus = false
    },

    redirectSection(risk){
      this.$emit("redirectSection", risk.category.toLowerCase())
    }

  },
}
</script>
<style >
  
</style>