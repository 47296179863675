<template>
  <div class="">
    <div class="mb-10 flex justify-between items-center">
      <div>
        <p class="text-texto text-3xl font-bold">Agregar Poliza</p>
      </div>
      <div>
        <div>
          <button v-if="!this.currentPoliceId" @click="$emit('cancelar')" class="mr-5 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button v-if="this.viewLoader2 == 'next' && !this.currentPoliceId" @click="createPolicy" class="ml-1 py-2 px-10 text-white bg-primario rounded-xl">Siguiente</button>
          <button v-if="this.viewLoader2 == 'loader' || this.viewLoader2 == 'created' || this.viewLoader2 == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button2">
            <div v-if="this.viewLoader2 == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
              <p class="mr-3">Creando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader2 == 'created'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Creado</p>
            </div>
            <div v-if="this.viewLoader2 == 'error'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Error</p>
            </div>
          </button>

          <button v-if="this.currentPoliceId" @click="delectPolice" class="mr-5 py-2 px-10 text-primario border border-primario rounded-xl">Atras</button>
          <button v-if="this.viewLoader == 'create' && this.currentPoliceId" @click="endCreation" class="ml-1 py-2 px-10 text-white bg-primario rounded-xl">Crear</button>
          <button v-if="this.viewLoader == 'loader' || this.viewLoader == 'created' || this.viewLoader == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button">
            <div v-if="this.viewLoader == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
              <p class="mr-3">Creando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader == 'created'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Creado</p>
            </div>
            <div v-if="this.viewLoader == 'error'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Error</p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div v-if="!this.currentPoliceId" class="mb-10 text-texto">
      <div class="pr-5">
        <div class="p-5 w-full border border-gray-50 rounded-xl shadow-lg">
          <div class="pb-2 mb-5 border-b border-gray-300">
            <p class="font-bold">Datos de la poliza</p>
          </div>

          <div class="mb-5 w-full flex">
            <div class="w-full">
              <div class="w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv" role="alert">
                <svg aria-hidden="true" :class="this.classText" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div class="transition-all" :class="this.classText">
                  <span class="font-medium">Alerta!</span>{{this.textAlert}}
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="risks" class="">Riesgo</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                    <select name="risks" id="risks" class="w-full border-none" @change="insuredFilter" v-model="this.formData.risks">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option v-for="(risk, index) in this.risks" :key="index" :value="risk.fullname">{{risk.fullname}}</option>
                    </select>
                  </div>
                  <div class="mt-1 flex">
                    <i class="mr-2 flex items-center text-sm text-yellow-300 fi fi-rr-exclamation"></i>
                    <p class=" text-sm text-yellow-300">Campo obligatorio</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="pl-5">
              <label for="product" class="">Producto</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.product" @keyup="toUpperCaseProducto" type="text" autocomplete="off" placeholder="transporte de mercancia, pyme, maquinaria, etc" name="product" id="product" class="w-full border-none">
              </div>
              <div class="mt-1 flex">
                <i class="mr-2 flex items-center text-sm text-yellow-300 fi fi-rr-exclamation"></i>
                <p class=" text-sm text-yellow-300">Campo obligatorio</p>
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="insured_name" class="text-texto">Nombre del asegurado</label>
              <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.insured_name" disabled type="text" autocomplete="off" placeholder="ejem... Sanchez Juan" name="insured_name" id="insured_name" class="w-full bg-gray-100 border-none">
              </div>
            </div>
            <div class="ml-5 h-full grid" style="grid-template-row: auto 1fr;">
              <div class="mb-auto">
                <label for="type_document_insured" class="text-texto">Tip. documento del asegurado</label>
                <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-id-badge"></i>
                  <select name="type_document_insured" id="type_document_insured" disabled class="w-full bg-gray-100 text-texto border-none" v-model="this.formData.type_document_insured">
                    <option selected disabled value="seleccionar">Seleccionar</option>
                    <option value="cc">CC</option>
                    <option value="ce">CE</option>
                    <option value="nit">NIT</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="insured_document" class="">Documento del asegurado</label>
              <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-hastag"></i>
                <input v-model="this.formData.insured_document" type="text" disabled autocomplete="off" placeholder="Ingresa el documento del asegurado" name="insured_document" id="insured_document" class="w-full bg-gray-100 border-none">
              </div>
            </div>
            <div class="pl-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="is_insurance_company" class="">Es aseguradora?</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                    <select name="is_insurance_company" id="is_insurance_company" class="w-full border-none" v-model="this.formData.is_insurance_company">
                      <option selected disabled :value="null">Seleccionar</option>
                      <option :value="true">Si</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div v-if="this.formData.is_insurance_company === null || this.formData.is_insurance_company === false" class="pr-5">
              <label for="brand" class="">Aseguradora</label>
              <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                <input type="text" autocomplete="off" name="brand" id="brand" disabled class="w-full bg-gray-100 border-none">
              </div>
            </div>
            <div v-else-if="this.formData.is_insurance_company" class="pr-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="insurance_company" class="">Aseguradora</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                    <select name="insurance_company" id="insurance_company" class="w-full border-none" v-model="this.formData.insurance_company" @change="setCurrentInsuranceCompany()">
                      <option selected disabled value="">Seleccionar</option>
                      <option v-for="(insurance, index) in this.insurance_companies" :key="index" :value="insurance.id" class="capitalize">{{insurance.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="pl-5">
              <label for="whatsapp_company" class="">Whatsapp de la aseguradora</label>
              <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-hastag"></i>
                <input v-model="this.formData.whatsapp_company" type="text" disabled autocomplete="off" placeholder="Ingresa el whatsapp de la aseguradora" name="whatsapp_company" id="whatsapp_company" class="w-full bg-gray-100 border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="start_creation" class="">Inicio de vigencia</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.start_creation" type="date" name="start_creation" id="start_creation" class="w-full border-none">
              </div>
              <div class="mt-1 flex">
                <i class="mr-2 flex items-center text-sm text-yellow-300 fi fi-rr-exclamation"></i>
                <p class=" text-sm text-yellow-300">Campo obligatorio</p>
              </div>
            </div>
            <div class="pl-5">
              <label for="expiration" class="">Fin de vigencia</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.expiration" type="date" name="expiration" id="expiration" class="w-full border-none">
              </div>
              <div class="mt-1 flex">
                <i class="mr-2 flex items-center text-sm text-yellow-300 fi fi-rr-exclamation"></i>
                <p class=" text-sm text-yellow-300">Campo obligatorio</p>
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="premium_excluding_vat" class="">Prima sin iva</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.premium_excluding_vat" type="text" @click="resetExcludingVat" @blur="formatedExcludingVat" autocomplete="off" placeholder="Ingresa el valor de la prima sin iva" name="premium_excluding_vat" id="premium_excluding_vat" class="w-full border-none">
              </div>
            </div>
            <div class="pl-5">
              <label for="premium_with_vat" class="">Prima con iva</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.premium_with_vat" @click="resetWithVat"  type="text" autocomplete="off" @blur="formatedWithVat" placeholder="Ingresa el valor de la prima con iva" name="premium_with_vat" id="premium_with_vat" class="w-full border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="number_policy" class="">Numero de poliza</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.number_policy" @keyup="toUpperCasePolicy" type="text" autocomplete="off" placeholder="Ingresa el numero de poliza" name="number_policy" id="number_policy" class="w-full border-none">
              </div>
            </div>
            <div class="pl-5">
              <label for="emergency_number" class="">Numero de emergencia</label>
              <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.emergency_number" type="text" disabled autocomplete="off" placeholder="Ingresa el numero de emergencia" name="emergency_number" id="emergency_number" class="w-full bg-gray-100 border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="business_manager_id" class="">Asesor Comercial</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                    <select @change="getAsesor" name="business_manager_id" id="business_manager_id" class="w-full border-none" v-model="this.formData.business_manager_id">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option v-for="(user, index) in this.team" :key="index" :value="user.id">{{user.fullname}}</option>
                    </select>
                  </div>
                  <div class="mt-1 flex">
                    <i class="mr-2 flex items-center text-sm text-yellow-300 fi fi-rr-exclamation"></i>
                    <p class=" text-sm text-yellow-300">Campo obligatorio</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="pl-5">
              <label for="number_business_manager" class="">Numero del asesor</label>
              <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.number_business_manager" disabled type="text" autocomplete="off" placeholder="Numero del asesor" name="number_business_manager" id="number_business_manager" class="w-full bg-gray-100 border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="insured_value" class="">Valor asegurado</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.insured_value" type="text" @click="resetInsured_value" @blur="formatedInsured_value" autocomplete="off" placeholder="Ingresa el valor asegurado" name="insured_value" id="insured_value" class="w-full border-none">
              </div>
            </div>
            <div class="pl-5">
              <label for="number_policy" class="">Observaciones</label>
              <textarea v-model="this.formData.observations" name="description" id="description"  rows="3" placeholder="Ingresa aqui las observaciones para el cliente" class="w-full text-sm border border-gray-300 rounded-xl"></textarea>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="is_financed" class="">Es financiada?</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                    <select name="is_financed" id="is_financed" class="w-full border-none" v-model="this.formData.is_financed">
                      <option selected disabled :value="null">Seleccionar</option>
                      <option :value="true">Si</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="this.formData.is_financed == null || this.formData.is_financed == false" class="pl-5">
              <label for="brand" class="">Financiera</label>
              <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                <input type="text" name="brand" id="brand" disabled class="w-full bg-gray-100 border-none">
              </div>
            </div>
            <div v-else-if="this.formData.is_financed" class="pl-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="financial" class="">Financiera</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                    <select name="financial" id="financial" class="w-full border-none" v-model="this.formData.financial">
                      <option selected disabled value="">Seleccionar</option>
                      <option v-for="(financiera, index) in this.financieras" :key="index" :value="financiera.id" class="capitalize">{{financiera.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="observations_financial" class="">Observaciones de la financiera</label>
              <textarea v-model="this.formData.observations_financial" name="description" id="observations_financial"  rows="3" placeholder="Ingresa aqui las observaciones para el cliente" class="w-full text-sm border border-gray-300 rounded-xl"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="mb-10 text-texto">
      <div class="pr-5">
        <div class="p-5 w-full border border-gray-50 rounded-xl shadow-lg">
          <div class="pb-2 mb-5 border-b border-gray-300">
            <p class="font-bold">Datos de la poliza</p>
          </div>
          <div class="flex flex-col items-center">
            <label for="uploadProfile" class="w-40 h-40 rounded-full">
              <div v-if="this.documentStatus" class="w-full h-full flex justify-center items-center bg-red-300 rounded-lg group cursor-pointer relative transition-all hover:opacity-80">
                <img src="https://krsoztbtqokoxqpeajxe.supabase.co/storage/v1/object/sign/resousers/pdf.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJyZXNvdXNlcnMvcGRmLnBuZyIsImlhdCI6MTY2ODUxODUxMCwiZXhwIjoxOTgzODc4NTEwfQ.cbNQNFHRf1KBVBELLYqRJd1QBZmd8yNGcvZyQJnfAk4" alt="profile" class="w-16 h-16">
                <div class="w-full h-full flex justify-center items-center absolute">
                  <div class="p-2 flex justify-center items-center bg-gray-50 rounded-full transition-all opacity-0 group-hover:opacity-80">
                    <i class="flex items-center text-xl fi fi-rr-cross "></i>
                  </div>
                </div>
              </div>
              <div v-else class="w-full h-full flex justify-center">
                <div class="w-full h-full flex flex-col items-center justify-center bg-gray-50 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100">
                  <i class="mb-2 flex items-center text-gray-500 text-lg fi fi-rr-upload"></i>
                  <p class="text-xs text-center text-gray-500">PDF<br>(MAX. 2 MB)</p>
                </div>
              </div>
            </label>
            <p class="mt-2 text-texto">{{ this.formData.file_name }}</p>
            <!-- <label for="uploadProfile" class="py-2 px-10 w-full text-primario border border-primario rounded-xl text-center cursor-pointer">Agregar foto</label> -->
            <input @change="uploadPdf" type="file" id="uploadProfile" class="hidden">
            <a v-if="this.documentStatus" :href="this.documentPolicy.url" target="_blank" class="mt-5 py-2 px-5 text-primario border border-primario rounded-xl cursor-pointer hover:bg-primario hover:bg-opacity-5">Ver documento</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../supabase/init"

export default {
  components: {},
  props: {
    currentClient: Object
  },
  data() {
    return {
      formData:{
        file_name: "",
        risks: "seleccionar",
        product: "",
        insured_name: "",
        type_document_insured: "seleccionar",
        insured_document: "",
        whatsapp_company: "",
        is_insurance_company: null,
        insurance_company: "",
        is_financed: null,
        financial: "",
        observations_financial: "",
        start_creation: "",
        expiration: "",
        number_policy: '',
        premium_excluding_vat: '',
        premium_with_vat: '',
        insured_value: '',
        observations: '',
        emergency_number: '',
        business_manager_id: 'seleccionar',
        number_business_manager: '',
        category: 'general',
        client_id: '',
        created_by: '',
      },

      risks:[],
      team:[],
      asesor:{},
      currentPoliceId: '',

      generalEvent: {},
      documentPolicy:{},
      documentStatus: false,

      viewLoader: 'create',
      bg_button: 'bg-primario',

      viewLoader2: 'next',
      bg_button2: 'bg-primario',

      classDiv: "w-0 p-0",
      classText: "hidden",
      textAlert: "",

      insurance_companies: [],
      currentInsuranceCompany: {},
      financieras: [],
    }
  },

  async mounted() {
    await this.getRisks()
    await this.getTeam()
    await this.getInsuranceCompanies()
    await this.getFinanceCompanies()
  },
  
  methods: {
    toUpperCaseProducto() {
      this.formData.product = this.formData.product.toUpperCase();
    },

    toUpperCasePolicy() {
      this.formData.number_policy = this.formData.number_policy.toUpperCase();
    },

    resetExcludingVat(){
      this.formData.premium_excluding_vat = this.formData.premium_excluding_vat_input
    }, 

    formatedExcludingVat(){
      this.formData.premium_excluding_vat_formatted = this.formData.premium_excluding_vat
      let formatted = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "COP"
      }).format(this.formData.premium_excluding_vat)
      this.formData.premium_excluding_vat_input = this.formData.premium_excluding_vat
      this.formData.premium_excluding_vat_formatted = formatted
      this.formData.premium_excluding_vat = this.formData.premium_excluding_vat_formatted
    },

    resetWithVat(){
      this.formData.premium_with_vat = this.formData.premium_with_vat_input
    },  

    formatedWithVat(){
      this.formData.premium_with_vat_formatted = this.formData.premium_with_vat
      let formatted = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "COP"
      }).format(this.formData.premium_with_vat)
      this.formData.premium_with_vat_input = this.formData.premium_with_vat
      this.formData.premium_with_vat_formatted = formatted
      this.formData.premium_with_vat = this.formData.premium_with_vat_formatted
    },

    resetInsured_value(){
      this.formData.insured_value = this.formData.insured_value_input
    },  

    formatedInsured_value(){
      this.formData.insured_value_formatted = this.formData.insured_value
      let formatted = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "COP"
      }).format(this.formData.insured_value)
      this.formData.insured_value_input = this.formData.insured_value
      this.formData.insured_value_formatted = formatted
      this.formData.insured_value = this.formData.insured_value_formatted
    },
  
    async getRisks(){
      try{
        const { data, error } = await supabase.from('general_risks').select('*').eq('client_id', this.currentClient.id).order('created_at', { ascending: true })
        this.risks = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getInsuranceCompanies(){
      try{
        const { data, error } = await supabase.from('insurance_companies').select('*').order('name', { ascending: true })
        this.insurance_companies = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },
  
    async setCurrentInsuranceCompany(){
      try{
        const { data, error } = await supabase.from('insurance_companies').select('*').eq('id', this.formData.insurance_company)
        this.currentInsuranceCompany = data[0]
        this.formData.whatsapp_company = data[0].whatsapp_number
        this.formData.emergency_number = data[0].emergency_number

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getFinanceCompanies(){
      try{
        const { data, error } = await supabase.from('finance_companies').select('*').order('created_at', { ascending: true })
        this.financieras = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getTeam(){
      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('agency_id', this.userAuth.user.id).order('created_at', { ascending: false })
        this.team = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getAsesor(){
      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('id', this.formData.business_manager_id,)
        this.asesor = data[0]
        this.formData.number_business_manager = this.asesor.whatsapp

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async insuredFilter(){
      try{
        const { data, error } = await supabase.from('general_risks').select('*').eq('fullname', this.formData.risks,)
        if(error) throw error;
        this.formData.insured_name = data[0].insured_name
        this.formData.type_document_insured = data[0].type_document_insured
        this.formData.insured_document = data[0].insured_document
      } catch (error){
        console.log(error.message)
      }
    },

    async createPolicy(){
      this.viewLoader2 = 'loader'
      if (this.formData.risks != 'seleccionar' && this.formData.product != '' && this.formData.start_creation != '' && this.formData.expiration != '' && this.formData.business_manager_id != 'seleccionar') {
        
        this.formData.client_id = this.currentClient.id
        this.formData.created_by = this.userAuth.user.id

        if (this.formData.insurance_company == '') {
          this.formData.insurance_company = null
        }
        if (this.formData.financial == '') {
          this.formData.financial = null
        }
        if (this.formData.number_policy == '') {
          this.formData.number_policy = null
        }
        if (this.formData.premium_excluding_vat == '') {
          this.formData.premium_excluding_vat = null
        }
        if (this.formData.premium_with_vat == '') {
          this.formData.premium_with_vat = null
        }
        if (this.formData.observations == '') {
          this.formData.observations = null
        }
        if (this.formData.emergency_number == '') {
          this.formData.emergency_number = null
        }
        if (this.formData.insured_name == '') {
          this.formData.insured_name = null
        }
        if (this.formData.type_document_insured == '') {
          this.formData.type_document_insured = null
        }
        if (this.formData.insured_document == '') {
          this.formData.insured_document = null
        }
        if (this.formData.whatsapp_company == '') {
          this.formData.whatsapp_company = null
        }

        try{
          let { data, error } = await supabase.from('policies').insert([{
            risks: this.formData.risks,
            product: this.formData.product,
            insured_name: this.formData.insured_name,
            type_document_insured: this.formData.type_document_insured,
            insured_document: this.formData.insured_document,
            whatsapp_company: this.formData.whatsapp_company,
            is_insurance_company: this.formData.is_insurance_company,
            insurance_company: this.formData.insurance_company,
            is_financed: this.formData.is_financed,
            financial: this.formData.financial,
            observations_financial: this.formData.observations_financial,
            start_creation: this.formData.start_creation,
            expiration: this.formData.expiration,
            number_policy: this.formData.number_policy,
            premium_excluding_vat: this.formData.premium_excluding_vat,
            premium_with_vat: this.formData.premium_with_vat,
            insured_value: this.formData.insured_value,
            category: this.formData.category,
            observations: this.formData.observations,
            emergency_number: this.formData.emergency_number,
            business_manager_id: this.formData.business_manager_id,
            number_business_manager: this.formData.number_business_manager,
            client_id: this.formData.client_id,
            created_by: this.formData.created_by,
          }])

          /* setTimeout(() => {
            this.viewLoader = 'created'
            this.bg_button = 'bg-green-400'
          }, 1000); */

          setTimeout(() => {
            this.currentPoliceId = data[0].id
            this.viewLoader2 = 'next'
            this.bg_button = 'bg-primario'
            /* this.$emit('create-policy') */
          }, 1000);

          if(error) throw error;
        } catch(error){
          console.log(error.message)
        } 

      }else {
        setTimeout(() => {
          this.viewLoader2 = 'error'
          this.bg_button2 = 'bg-red-400'
          this.classDiv = "w-72 p-4"
          this.classText = "block"
          this.textAlert = " No se admiten campos vacios."
        }, 1000);

        setTimeout(() => {
          this.viewLoader2 = 'next'
        this.bg_button2 = 'bg-primario'
          this.classDiv = "w-0 p-0"
          this.classText = "hidden"
        }, 4000);
      }
    },

    async uploadPdf(event){
      this.generalEvent = event
      let file = event.target.files[0]
      if (file) {
        try {
          const { data, error } = await supabase.storage
          .from('policies')
          .upload(`${this.currentClient.id}/${this.currentPoliceId}/document`, file, {
            cacheControl: '3600',
            upsert: false,
            contentType: file.type
          })
          try{
            let { data, error } = await supabase.from('policies').update({
              file_name: file.name,
            }).eq('id', this.currentPoliceId)

              this.downloadPdf()
              this.formData.file_name = file.name

            if(error) throw error;
          } catch(error){
            console.log(error.message)
          } 
          if(error) throw error;
        } catch (error) {
          if (error) {
            this.delecteProfile()
          }
        }
      } 
    },

    async downloadPdf(){
      try {
        const { signedURL, error } = await supabase.storage.from('policies').createSignedUrl(`${this.currentClient.id}/${this.currentPoliceId}/document`, 60)
        this.documentPolicy = {
          name: this.formData.file_name,
          url : signedURL
        }
        this.documentStatus = true

        if(error) throw error;
      } catch (error) {
        console.log(error.message)
      }
    },

    async delecteProfile(){
      await supabase.storage.from('policies').remove([`${this.currentClient.id}/${this.currentPoliceId}/document`])
      this.documentStatus = false
      this.uploadPdf(this.generalEvent)
    },

    async delectPolice(){
      try {
        const { error } = await supabase.storage.from('policies').remove([`${this.currentClient.id}/${this.currentPoliceId}/document`])
        this.documentStatus = false

        try {
          const { error } = await supabase.from('policies').delete().eq('id', this.currentPoliceId)
          this.currentPoliceId = ''

          if(error) throw error;
        } catch (error) {
          console.log(error.message)
        }

        if(error) throw error;
      } catch (error) {
        console.log(error.message)
      }
    },

    endCreation(){
      this.viewLoader = 'loader'

      setTimeout(() => {
        this.viewLoader = 'created'
        this.bg_button = 'bg-green-400'
          setTimeout(() => {
          this.viewLoader = 'create'
          this.bg_button = 'bg-primario'
          this.$emit('create-policy')
        }, 1000);
      }, 1500);
    },
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    }
  }
}
</script>
<style>
  .loader {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #FFF;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
</style>