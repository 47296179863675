<template>
  <div class="py-10 px-5 w-full h-screen flex justify-center items-center relative">
    <form class=" w-96 flex bg-white" @submit.prevent="onSubmit">
      <div class="w-full">
        <div class="mb-10 w-full flex flex-col items-center">
          <i class="mb-2 flex items-center text-7xl text-primario fi fi-rr-users"></i>
          <h1 class="text-center text-primario font-bold text-xl">Recuperar contraseña</h1>
        </div>
        <div class="mb-5 w-full">
          <label for="email" class="mb-2 text-texto font-semibold  text-sm">Correo electronico</label>
          <div class="border-b border-primario">
            <input name="email" id="email" type="email" placeholder="Ingresa tu correo" v-model="this.email" class="px-5 py-1 w-full border-none"/> 
          </div>
        </div>
        <button v-if="this.viewLoader == 'reset'" @click="metodoPrueba" class="px-2 py-2 mb-2 w-full text-white rounded-lg" :class="this.bg_button">Enviar link de recuperacion</button>
        <button v-if="this.viewLoader == 'loader' || this.viewLoader == 'send' || this.viewLoader == 'error'" class=" mb-2 w-full text-white rounded-xl" :class="this.bg_button">
          <div v-if="this.viewLoader == 'loader'" class="px-2 py-2 w-full flex justify-center cursor-wait">
            <p class="mr-3">enviando</p>
            <div class="flex justify-center items-center">
              <span class="loader"></span>
            </div>
          </div>
          <div v-if="this.viewLoader == 'error'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
            <p class="mr-3">Error</p>
          </div>
          <div v-if="this.viewLoader == 'send'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
            <p class="mr-3">Enviado</p>
          </div>
        </button>
        <div class="mb-2">
          <div class="w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv" role="alert">
            <svg aria-hidden="true" :class="this.classText" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <div class="transition-all" :class="this.classText">
              <span class="font-medium">Alerta!</span>{{this.textAlert}}
            </div>
          </div>
          <router-link to="/login">
          <p class="text-primario">Iniciar sesion</p>
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import router from '@/router'
import { supabase } from "../supabase/init"

export default {
  data() {
    return {
      email: '',

      viewLoader: 'reset',
      bg_button: 'bg-primario',

      verify: false,
      classDiv: "w-0 p-0",
      classText: "hidden",
      textAlert: ""

    }
  },

  mounted() {
    if(supabase.auth.user()){
      router.push('/')
    }
  },

  methods: {
    async metodoPrueba(){
      this.viewLoader = 'loader'
      if (this.email != '') {
        this.email = this.email.toLowerCase()
        try {
          const { data, error } = await supabase.from('agencies').select('*').eq('email', `${this.email}`)
          if (data[0]) {
            if (data[0].status) {
              try {
                const { data, error } = await supabase.auth.api.resetPasswordForEmail(this.email)
                setTimeout(() => {
                  this.viewLoader = 'send'
                  this.bg_button = 'bg-green-400'
                  setTimeout(() => {
                    this.viewLoader = 'reset'
                    this.bg_button = 'bg-primario'
                    
                  }, 3000);
                }, 3000);
                
                if(error) throw error;
              } catch (error) {
                console.log(error.message);
              }
            } else {
              setTimeout(() => {
                this.viewLoader = 'error'
                this.bg_button = 'bg-red-500'
                this.classDiv = "w-72 p-4"
                this.classText = "block"
                this.textAlert = " La cuenta del correo ingresado se encuentra inactiva."
                setTimeout(() => {
                  this.viewLoader = 'reset',
                  this.bg_button = 'bg-primario',
                  this.classDiv = "w-0 p-0"
                  this.classText = "hidden"
                }, 3000);
              }, 1000);
            }
          } else{
            try {
              const { data, error } = await supabase.from('team_agencies').select('*').eq('email', `${this.email}`)
              if (data[0]) {
                if (data[0].status) {
                  try {
                    const { data, error } = await supabase.auth.api.resetPasswordForEmail(this.email)
                    setTimeout(() => {
                      this.viewLoader = 'send'
                      this.bg_button = 'bg-green-400'
                      setTimeout(() => {
                        this.viewLoader = 'reset'
                        this.bg_button = 'bg-primario'
                        
                      }, 3000);
                    }, 3000);
                    
                    if(error) throw error;
                  } catch (error) {
                    console.log(error.message);
                  }
                } else {
                  setTimeout(() => {
                    this.viewLoader = 'error'
                    this.bg_button = 'bg-red-500'
                    this.classDiv = "w-72 p-4"
                    this.classText = "block"
                    this.textAlert = " La cuenta del correo ingresado se encuentra inactiva."
                    setTimeout(() => {
                      this.viewLoader = 'reset',
                      this.bg_button = 'bg-primario',
                      this.classDiv = "w-0 p-0"
                      this.classText = "hidden"
                    }, 3000);
                  }, 1000);
                }
              } else{
                setTimeout(() => {
                  this.viewLoader = 'error'
                  this.bg_button = 'bg-red-500'
                  this.classDiv = "w-72 p-4"
                  this.classText = "block"
                  this.textAlert = " El correo ingresado no existe."
                  setTimeout(() => {
                    this.viewLoader = 'reset',
                    this.bg_button = 'bg-primario',
                    this.classDiv = "w-0 p-0"
                    this.classText = "hidden"
                  }, 3000);
                }, 1000);
              }

              if(error) throw error;
            } catch (error) {
              console.log(error.message);
            }
          }

          if(error) throw error;
        } catch (error) {
          console.log(error.message);
        }
      } else{
        setTimeout(() => {
          this.viewLoader = 'reset'
          this.classDiv = "w-72 p-4"
          this.classText = "block"
          this.textAlert = " No se admiten campos vacios."
          setTimeout(() => {
            this.classDiv = "w-0 p-0"
            this.classText = "hidden"
          }, 3000);
        }, 1000);
      }
      
    },
  },
}
</script>

