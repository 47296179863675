import { createRouter, createWebHashHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import WindowsView from '../views/WindowsView.vue'
import Login from '../views/Login.vue'
/* import Register from '../views/Register.vue' */
import ResetPassword from '../views/ResetPassword.vue'

import { supabase } from '@/supabase/init'

const routes = [
  {
    path: '/',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/dashboard/clientes'}
    },
    meta:{
      title: 'home',
      auth: true
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    children: [
      {
        path: '/dashboard/:view',
        component: WindowsView,
        meta:{
          title: 'WindowsView',
          auth: true
        },
      },
    ],
    meta:{
      title: 'dashboard',
      auth: true
    },
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/dashboard/inicio'}
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      title: 'Login',
      auth: false
    },
  },
  /* {
    path: '/register',
    name: 'Register',
    component: Register,
    meta:{
      title: 'Register',
      auth: false
    },
  }, */
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPassword,
    meta:{
      title: 'ResetPassword',
      auth: false
    },
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) =>{
  const user = supabase.auth.user();
  if(to.matched.some((res)=> res.meta.auth)){
    if(user){
      next();
      return
    }
    next({name: "Login"})
    return
  }
  next();
})

export default router
