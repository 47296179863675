<template>
  <div class="w-full h-full relative">
    <div v-if="this.loaderBefore" class="h-full w-full flex flex-col justify-center items-center bg-white absolute z-10">
      <i class="fi fi-rr-spinner flex justify-center items-center text-6xl text-primario animate-spin"></i>
      <p class="w-full max-w-xs text-center mt-5 animate-bounce text-xl">Estamos cargando esta linda familia para ti</p>
    </div>
    <div class="px-10 pt-10 pb-32 w-full h-screen relative" style="overflow-y:scroll;">
      
      <div v-if="this.modalStatus" class="w-full h-screen flex justify-center items-center bg-primario bg-opacity-30 top-0 left-0 fixed z-10">
        <div v-if="this.typeAlert == 'individual'" class="p-5 w-full bg-white rounded-lg" style="max-width: 600px;">
          <div>
            <p class="mb-5 text-lg text-center font-bold text-primario">POLIZA - {{ this.currentPolicy.category.toUpperCase() }}</p>
            <div class="w-full">

            </div>
            <div class="mb-5">
              <div class="mb-5 w-full flex justify-between">
                <p class="mr-5 font-bold text-texto">Agencia:</p>
                <span v-if="this.viewLoaderInfo" class="loaderInfo"></span>
                <p v-else class=" text-right text-texto">{{this.currentPolicy.agency_name}}</p>
              </div>
              <div class="mb-5 w-full flex justify-between">
                <p class="mr-5 font-bold text-texto">Cliente:</p>
                <span v-if="this.viewLoaderInfo" class="loaderInfo"></span>
                <p v-else class=" text-right text-texto">{{this.currentPolicy.first_name}} {{this.currentPolicy.second_name}} {{this.currentPolicy.first_last_name}} {{this.currentPolicy.second_last_name}}</p>
              </div>
              <div class="mb-5 w-full flex justify-between">
                <p class="font-bold text-texto">Identificacion</p>
                <span v-if="this.viewLoaderInfo" class="loaderInfo"></span>
                <p v-else class=" text-right text-texto">{{this.currentPolicy.document}}</p>
              </div>
              <div class="mb-5 w-full flex justify-between">
                <p class="font-bold text-texto">Producto:</p>
                <span v-if="this.viewLoaderInfo" class="loaderInfo"></span>
                <p v-else class=" text-right text-texto">{{this.currentPolicy.product}}</p>
              </div>
              <div class="mb-5 w-full flex justify-between">
                <p class="font-bold text-texto">Riesgo:</p>
                <span v-if="this.viewLoaderInfo" class="loaderInfo"></span>
                <p v-else class=" text-right text-texto">{{this.currentPolicy.risks}}</p>
              </div>
              <div class="mb-5 w-full flex justify-between">
                <p class="font-bold text-texto">Aseguradora:</p>
                <span v-if="this.viewLoaderInfo" class="loaderInfo"></span>
                <p v-else class=" text-right text-texto">{{this.currentPolicy.insurance_company}}</p>
              </div>
              <div class="mb-5 w-full flex justify-between">
                <p class="font-bold text-texto">Creacion:</p>
                <span v-if="this.viewLoaderInfo" class="loaderInfo"></span>
                <p v-else class=" text-right text-texto">{{this.currentPolicy.start_creation}}</p>
              </div>
              <div class="mb-6 w-full flex justify-between">
                <p class="font-bold text-texto">Vencimiento:</p>
                <span v-if="this.viewLoaderInfo" class="loaderInfo"></span>
                <p v-else class=" text-right text-texto">{{this.currentPolicy.expiration}}</p>
              </div>
            </div>
            <div class="w-full flex justify-end">
              <button @click="closeModal" class="py-2 px-10 text-primario border border-primario rounded-xl">Cerrar</button>
              <!-- <button v-if="this.viewLoader == 'send' && this.currentAlert == 'sms'" @click="sendMessageSms" class="ml-5 py-2 px-10 text-white rounded-xl" :class="this.bg_button">Enviar alerta</button> -->
              <button v-if="this.viewLoader == 'send' && this.currentAlert == 'email'" @click="sendMessageEmail" class="ml-5 py-2 px-10 text-white rounded-xl" :class="this.bg_button">Enviar alerta</button>
              <button v-else class="ml-5 text-white rounded-xl" :class="this.bg_button">
                <div v-if="this.viewLoader == 'loader'" class=" py-2 px-10 flex justify-center cursor-wait">
                  <p class="mr-3">Enviando</p>
                  <div class="flex justify-center items-center">
                    <span class="loader"></span>
                  </div>
                </div>
                <div v-if="this.viewLoader == 'sent'" class="px-10 py-2 w-full flex justify-center cursor-pointer">
                  <p class="">Enviado</p>
                </div>
                <div v-if="this.viewLoader == 'error'" class="px-10 py-2 w-full flex justify-center cursor-pointer">
                  <p class="">Error</p>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div v-else-if="this.typeAlert == 'global'" class="p-5 w-full bg-white rounded-lg" style="max-width: 350px;">
          <div>
            <p class="mb-5 text-lg text-center font-bold text-primario">ALERTA MASIVA</p>
              
            <div class="mb-5">
              <p class=" text-center text-texto">Enviar alerta masiva a todas las polizas a punto de vencerse que no se hayan notificado</p>
            </div>

            <div class="w-full flex justify-between">
              <button @click="closeModal" class="py-2 px-10 text-primario border border-primario rounded-xl">Cerrar</button>
              <!-- <button v-if="this.viewLoader == 'send' && this.currentAlert == 'sms'" @click="sendMessageSmsGlobal" class="py-2 px-10 text-white rounded-xl" :class="this.bg_button">Enviar</button> -->
              <button v-if="this.viewLoader == 'send' && this.currentAlert == 'email'" @click="sendMessageEmailGlobal" class="py-2 px-10 text-white rounded-xl" :class="this.bg_button">Enviar</button>
              <button v-else class="ml-5 text-white rounded-xl" :class="this.bg_button">
                <div v-if="this.viewLoader == 'loader'" class=" py-2 px-5 flex justify-center cursor-wait">
                  <p class="mr-3">Enviando</p>
                  <div class="flex justify-center items-center">
                    <span class="loader"></span>
                  </div>
                </div>
                <div v-if="this.viewLoader == 'sent'" class="px-10 py-2 w-full flex justify-center cursor-pointer">
                  <p class="">Enviado</p>
                </div>
                <div v-if="this.viewLoader == 'error'" class="px-10 py-2 w-full flex justify-center cursor-pointer">
                  <p class="">Error</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-10 flex justify-between items-center">
        <div>
          <p class="text-texto text-3xl font-bold">Vencimientos</p>
          <p class="text-texto">Vista de todas las polizas</p>
        </div>
      </div>
      <!-- <button @click="sendMessage" class="py-2 px-10 text-white bg-primario rounded-xl">Botton prueba whatsapp</button> -->

      <div class="mb-5 flex">
        <div class="py-1 w-80 mr-5">
          <label for="filter" class="pl-2 text-texto">Filtrar por:</label>
          <div class="py-1 w-full flex items-center border border-gray-300 rounded-xl">
            <select name="filter" id="filter" class="w-full border-none" @change="changeFilter" v-model="this.typeFilter">
              <option selected disabled value="seleccionar">Seleccionar</option>
              <option value="placa">Placa</option>
              <option value="categoria">categoria</option>
              <option value="id_client">Id Cliente</option>
              <option value="id_insured">Id Asegurado</option>
            </select>
          </div>
        </div>

        <div v-if="this.typeFilter == 'id_client'" class="flex items-center">
          <div>
            <label for="filter" class="pl-2 text-texto">Ingresa el no. documento del cliente</label>
            <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
              <input v-model="this.documentFilter" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
            </div>
            <p class="mt-1 text-red-500">{{this.alertFilter}}</p>
          </div>

          <div class="mt-6">
            <button  @click="filterClient" class="mr-2 py-2 px-2 text-primario border border-primario rounded-xl">
              <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
            </button>
          </div>
          <div class="mt-6">
            <button @click="removeFilter" v-if="this.filterStatus" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
              <p class="mr-2">Quitar filtro</p>
              <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
            </button>
          </div>
        </div>

        <div v-if="this.typeFilter == 'id_insured'" class="flex items-center">
          <div>
            <label for="filter" class="pl-2 text-texto">Ingresa el no. documento del asegurado</label>
            <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
              <input v-model="this.documentFilter" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
            </div>
            <p class="mt-1 text-red-500">{{this.alertFilter}}</p>
          </div>

          <div class="mt-6">
            <button  @click="filterClient2" class="mr-2 py-2 px-2 text-primario border border-primario rounded-xl">
              <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
            </button>
          </div>
          <div class="mt-6">
            <button @click="removeFilter" v-if="this.filterStatus2" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
              <p class="mr-2">Quitar filtro</p>
              <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
            </button>
          </div>
        </div>

        <div v-else-if="this.typeFilter == 'categoria'" class="flex items-center justify-between">
          <div class="flex items-center">
            <div>
              <div class="mt-1 py-1 w-80">
                <label for="filter2" class="pl-2 text-texto">Seleciona la categoria</label>
                <div class="py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <select name="filter2" id="filter2" class="w-full border-none" v-model="this.documentFilter3" @change="filterClient3">
                    <option selected disabled value="seleccionar">Seleccionar</option>
                    <option value="autos">Autos</option>
                    <option value="vida">vida</option>
                    <option value="hogar">Hogar</option>
                    <option value="salud">Salud</option>
                    <option value="generales">Generales</option>
                  </select>
                </div>
              </div>
              <p class="mt-1 text-red-500">{{this.alertFilter3}}</p>
            </div>
            <div class="mt-6">
              <button  @click="filterClient3" class="mx-2 py-2 px-2 text-primario border border-primario rounded-xl">
                <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
              </button>
            </div>
            <div class="mt-6">
              <button @click="removeFilter" v-if="this.filterStatus3" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
                <p class="mr-2">Quitar filtro</p>
                <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
              </button>
            </div>
          </div>
        </div>

        <div v-if="this.typeFilter == 'placa'" class="flex items-center">
          <div>
            <label for="filter" class="pl-2 text-texto">Ingresa el no. placa</label>
            <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
              <input v-model="this.documentFilter4" @keyup="toUpperCase" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
            </div>
            <p class="mt-1 text-red-500">{{this.alertFilter4}}</p>
          </div>
          <div class="mt-6">
            <button  @click="filterPolicy4" class="mr-2 py-2 px-2 text-primario border border-primario rounded-xl">
              <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
            </button>
          </div>
          <div class="mt-6">
            <button @click="removeFilter" v-if="this.filterStatus4" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
              <p class="mr-2">Quitar filtro</p>
              <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
            </button>
          </div>
        </div>
      </div>


      <div>
        <div class="px-3 relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-left">
            <thead class=" text-texto bg-gray-50 ">
              <tr>
                <th scope="col" class="pr-6 py-3 text-center font-semibold">No.</th>
                <th scope="col" class="pr-6 py-3 text-center font-semibold">Cliente</th>
                <th scope="col" class="pr-6 py-3 text-center font-semibold">Identificacion</th>
                <th scope="col" class="pr-6 py-3 text-center font-semibold">Asegurado</th>
                <th scope="col" class="pr-6 py-3 text-center font-semibold">Id. Asegurado</th>
                <th scope="col" class="pr-6 py-3 text-center font-semibold">Producto</th>
                <th scope="col" class="pr-6 py-3 text-center font-semibold">Riesgo</th>
                <th scope="col" class="pr-6 py-3 text-center font-semibold">Aseguradora</th>
                <th scope="col" class="pr-6 py-3 text-center font-semibold">Vencimiento</th>
                <th scope="col" class="px-6 py-3 text-center font-semibold">Estado</th>
                <th scope="col" class="px-6 py-3 text-center font-semibold">Alerta email</th>
              </tr>
            </thead>
            <tbody v-if="this.policiesFilter.length">
              <tr v-for="(policy, index) in this.policiesFilter" :key="index" class="bg-white text-texto border-b">
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{index + 1}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.first_name}} {{policy.first_last_name}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.document}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.insured_name}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.insured_document}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.product}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.risks}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.insurance_company}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.expiration}}</td>
                <td class="px-6 py-4 text-center cursor-pointer" @click="redirectPolicy(policy)">
                  <div v-if="policy.remainingDays < 1" class="py-1 px-4 rounded-xl bg-red-600"><p class="text-white">Vencido</p></div>
                  <div v-else-if="policy.remainingDays < 60 && policy.remainingDays >= 30" class="py-1 px-4 rounded-xl bg-yellow-100"><p class="text-yellow-400">1 a 2 meses</p></div>
                  <div v-else-if="policy.remainingDays < 30" class="py-1 px-4 rounded-xl bg-red-100"><p class="text-red-600">-1 mes</p></div>
                  <div v-else class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">+2 meses</p></div>
                </td>
                <td class="truncate max-w-xs w-full px-6 py-4 text-center">
                  <button v-if="policy.notification_email" class="px-2 py-1 bg-gray-400 text-white rounded-lg">Notificado</button>
                  <button v-else @click="openModal(policy, 'email', 'individual')" class="px-2 py-1 bg-primario text-white rounded-lg">Notificar</button>
                  <button v-if="policy.notification_email" @click="openModal(policy, 'email', 'individual')" class="px-2 py-2 ml-1 bg-primario text-white rounded-lg">
                    <i class="flex items-center text-white fi fi-rr-rotate-right"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(policy, index) in this.policies" :key="index" class="bg-white text-texto border-b">
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{index + 1}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.first_name}} {{policy.first_last_name}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.document}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.insured_name}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.insured_document}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.product}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.risks}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.insurance_company}}</td>
                <td class="pr-6 py-4 text-center font-medium cursor-pointer" @click="redirectPolicy(policy)">{{policy.expiration}}</td>
                <td class="px-6 py-4 text-center cursor-pointer" @click="redirectPolicy(policy)">
                  <div v-if="policy.remainingDays < 1" class="py-1 px-4 rounded-xl bg-red-600"><p class="text-white">Vencido</p></div>
                  <div v-else-if="policy.remainingDays < 60 && policy.remainingDays >= 30" class="py-1 px-4 rounded-xl bg-yellow-100"><p class="text-yellow-400">1 a 2 meses</p></div>
                  <div v-else-if="policy.remainingDays < 30" class="py-1 px-4 rounded-xl bg-red-100"><p class="text-red-600">-1 mes</p></div>
                  <div v-else class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">+2 meses</p></div>
                </td>
                <td class="truncate max-w-xs w-full px-6 py-4 text-center">
                  <button v-if="policy.notification_email" class="px-2 py-1 bg-gray-400 text-white rounded-lg">Notificado</button>
                  <button v-else @click="openModal(policy, 'email', 'individual')" class="px-2 py-1 bg-primario text-white rounded-lg">Notificar</button>
                  <button v-if="policy.notification_email" @click="openModal(policy, 'email', 'individual')" class="px-2 py-2 ml-1 bg-primario text-white rounded-lg">
                    <i class="flex items-center text-white fi fi-rr-rotate-right"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
    <!-- <div v-else class="h-full flex justify-center items-center">
      <div class="flex items-center">
        <img class="mr-5 w-20" src="../../../assets/logo.png" alt="">
        <p class="text-5xl text-center text-texto font-bold">LO SENTIMOS <br> <span class="text-2xl">No tienes acceso a esta seccion</span> </p>
      </div>
    </div> -->
  </div>
</template>
<script>
import { supabase } from "../../../supabase/init"
import router from '@/router'
import emailjs from '@emailjs/browser';
import axios from 'axios'
export default {
  components: { },
  data() {
    return {
      loaderBefore: true,
      currentUser:{},
      res1:{},
      res2:{},
      policies:[],
      policiesFilter: [
        /* {
          license_plate_name: 'QWE123',
          product: 'SOAT',
          insurance_company: 'insurance_company',
          status: '+2 meses',
        } */
      ],
      typeFilter: 'seleccionar',

      documentFilter: '',
      filterStatus: false,
      alertFilter: '',

      documentFilter2: '',
      filterStatus2: false,
      alertFilter2: '',

      documentFilter3: 'seleccionar',
      filterStatus3: false,
      alertFilter3: '',

      documentFilter4: '',
      filterStatus4: false,
      alertFilter4: '',

      to: 'whatsapp:+521234567890',
      body: 'Bienvenido a helprime',

      modalStatus: false,
      typeAlert: '',
      currentAlert: '',
      currentPolicy: {},
      currentPolicies: [],
      viewLoaderNote: 'update',
      bg_buttonNote: 'text-primario border-primario',
      viewLoaderInfo : true,

      viewLoader: 'send',
      bg_button: 'bg-primario',

      sendEmail: {
        client_name: '',
        email: '',
        description: ''
      },

      meses: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    }
  },

  async mounted() {
    await this.getUser()
    if (this.currentUser.rol == 'Agency') {
      await this.getPolicies()
      if (this.policies) {
        setTimeout(() => {
          this.loaderBefore = false
        }, 3000);
      }
    }else {
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    }
    
  },

  methods: {
    /* async sendMessage() {
      const accountSid = 'AC2ad0eafa258f12f64391fdd4706147c2'
      const authToken = '09492726f888261a25b542690676cc61'
      
      try {
        const response = await axios.post(
          'https://api.twilio.com/2010-04-01/Accounts/' + accountSid + '/Messages.json',
          {
            From: 'whatsapp:+14155238886',
            To: this.to,
            Body: this.body
          },
          { auth: { username: accountSid, password: authToken } }
        )
        console.log(response)
        alert('Felicitaciones se envio el mensaje!')
      } catch (error) {
        console.error(error)
        alert('Buuu no pudimos enviar el mensaje.')
      }
    }, */
    
    async getUser(){
      try{
        const { data, error } = await supabase.from('agencies').select('*').eq('id', this.userAuth.user.id)
        this.res1 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      }  

      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('id', this.userAuth.user.id)
        this.res2 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      } 

      if (this.res1) {
        this.currentUser = this.res1
      } else {
        this.currentUser = this.res2 
      }
    },

    async getPolicies(){
      try{
        let dataPolicies1 = []
        const { data, error } = await supabase.from('policies').select('*').order('created_at', { ascending: true })
        data.forEach(async (policy) => {
          try {
            const { data, error } = await supabase.from('clients_agencies').select('*').eq('id', policy.client_id)

            policy.first_name = data[0].first_name
            policy.second_name = data[0].second_name
            policy.first_last_name = data[0].first_last_name
            policy.second_last_name = data[0].second_last_name
            policy.document = data[0].document
            policy.contact_number = data[0].contact_number
            policy.email = data[0].email

            if(error) throw error;
          } catch (error){
            console.log(error.message)
          }

          const now = new Date();
          const competition = new Date(policy.expiration);
          this.time = competition - now;
          const miliseconds = this.time;
          const seconds = miliseconds/1000;
          const minutes = seconds/60;
          const days = minutes/1440
          const result = Math.round(days)
          policy.remainingDays = result

          let fecha = new Date(policy.expiration)
          policy.expiration = fecha.getDate() + ' de ' + this.meses[fecha.getMonth()] + ' ' + fecha.getUTCFullYear()

          let fecha2 = new Date(policy.start_creation)
          policy.start_creation = fecha2.getDate() + ' de ' + this.meses[fecha2.getMonth()] + ' ' + fecha2.getUTCFullYear()

          this.policies.unshift(policy)
          this.policies.sort(function(a, b){
            return a.remainingDays - b.remainingDays
          })
        });

        this.policies = dataPolicies1

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    concatFilter(){
      this.vencidos = t
    },

    toUpperCase() {
        this.documentFilter4 = this.documentFilter4.toUpperCase();
    },

    async filterClient(){
      this.policiesFilter = this.policies.filter(policy => policy.document == this.documentFilter)

      if (this.policiesFilter.length) {
        this.filterStatus = true

      } else {
        let currenData = this.policies
        this.policies = []
        this.alertFilter = 'No se encontraron resultados'

        setTimeout(() => {
          this.policies = currenData
          this.alertFilter = ''
          this.removeFilter()
        }, 2000);
      }
    },

    async filterClient2(){
      this.policiesFilter = this.policies.filter(policy => policy.insured_document == this.documentFilter)

      if (this.policiesFilter.length) {
        this.filterStatus2 = true

      } else {
        let currenData = this.policies
        this.policies = []
        this.alertFilter2 = 'No se encontraron resultados'

        setTimeout(() => {
          this.policies = currenData
          this.alertFilter2 = ''
          this.removeFilter()
        }, 2000);
      }
    },


    filterClient3(){
      this.policiesFilter = this.policies.filter(policy => policy.category == this.documentFilter3)

      if (this.policiesFilter.length) {
        this.filterStatus3 = true
      } else {
        let currenData = this.policies
        this.policies = []
        this.alertFilter3 = 'No se encontraron resultados'

        setTimeout(() => {
          this.policies = currenData
          this.alertFilter3 = ''
          this.removeFilter()
        }, 2000);
      }
    },

    filterPolicy4(){
      this.policiesFilter = this.policies.filter(policy => policy.risks == this.documentFilter4)

      if (this.policiesFilter.length) {
        this.filterStatus4 = true
      } else {
        let currentData = this.policies
        this.policies = []
        this.alertFilter4 = 'No se encontraron resultados'

        setTimeout(() => {
          this.alertFilter4 = ''
          this.policies = currentData
        }, 2000);
      }
    },

    removeFilter(){
      this.policiesFilter = []
      this.documentFilter = ''
      this.documentFilter2 = ''
      this.documentFilter3 = 'seleccionar'
      this.documentFilter4 = ''
      this.filterStatus = false
      this.filterStatus2 = false
      this.filterStatus3 = false
      this.filterStatus4 = false
    },

    changeFilter(){
      this.removeFilter()
    }, 

    redirectPolicy(policy){
      this.$store.commit("SavePolicyVencimiento", policy)
      this.$store.commit("save_route", "clientes")
      /* this.$emit("changeSectionVencimientos", "clientes") */
      router.push("/dashboard/clientes")
    },

    async openModal(policy, alert, type){
      this.currentAlert = alert
      this.typeAlert = type
      this.currentPolicy = policy
      this.modalStatus = true


      if (this.typeAlert == 'individual') {
        try {
          const { data, error } = await supabase.from('agencies').select('*').eq('id', this.currentPolicy.created_by)
          if(error) throw error;

          this.currentPolicy.agency_name = data[0].fullname
          this.currentPolicy.contact_number_agency = data[0].contact_number
          setTimeout(() => {
            this.viewLoaderInfo = false
          }, 1000);

        } catch (error) {
          console.log(error);
        }
      }
    },

    closeModal(){
      this.viewLoaderInfo = true
      this.modalStatus = false
      this.currentAlert = ''
      this.typeAlert = ''
      this.currentPolicy = {}
    },

    async sendMessageEmail(){
      this.viewLoader = 'loader'
      this.sendEmail.client_name = this.currentPolicy.first_name
      this.sendEmail.email = this.currentPolicy.email

      if (this.currentPolicy.category == 'autos') {
        this.sendEmail.description = `Noti-Helprime te informa que el ${this.currentPolicy.expiration} vence tu ${this.currentPolicy.product} del VEHICULO ${this.currentPolicy.risks}. Por fis contacta a tu agencia ${this.currentPolicy.agency_name} cel:${this.currentPolicy.contact_number_agency}`
        
      } else if (this.currentPolicy.category == 'hogar'){
        this.sendEmail.description = `Noti-Helprime te informa que el ${this.currentPolicy.expiration} vence tu ${this.currentPolicy.product} de ${this.currentPolicy.risks}. Por fis contacta a tu agencia ${this.currentPolicy.agency_name} cel:${this.currentPolicy.contact_number_agency}`
      } else if (this.currentPolicy.category == 'generales'){
        this.sendEmail.description = `Noti-Helprime te informa que el ${this.currentPolicy.expiration} vence tu ${this.currentPolicy.product} de ${this.currentPolicy.risks}. Por fis contacta a tu agencia ${this.currentPolicy.agency_name} cel:${this.currentPolicy.contact_number_agency}`
      } else if (this.currentPolicy.category == 'vida'){
        this.sendEmail.description = `Noti-Helprime te informa que el ${this.currentPolicy.expiration} vence tu Seguro de ${this.currentPolicy.product}. Por fis contacta a tu agencia ${this.currentPolicy.agency_name} cel:${this.currentPolicy.contact_number_agency}`
      } else if (this.currentPolicy.category == 'salud'){
        this.sendEmail.description = `Noti-Helprime te informa que el ${this.currentPolicy.expiration} vence tu Seguro de Salud ${this.currentPolicy.product}. Por fis contacta a tu agencia ${this.currentPolicy.agency_name} cel:${this.currentPolicy.contact_number_agency}`
      }

      try{
        let { data, error } = await supabase.from('policies').update({
          notification_email: true,
        }).eq('id', this.currentPolicy.id)
        if(error) throw error;

        emailjs.send('service_5njuyze', 'template_0chl4ab', this.sendEmail, 'pfz8wFvetYK3c-deO')

        setTimeout(() => {
          this.viewLoader = 'sent'
          setTimeout(() => {
            this.modalStatus = false
            this.viewLoader = 'send'
            this.policies.forEach(policy => {
              if (policy.id == this.currentPolicy.id) {
                policy.notification_email = true
              }
            });
          }, 2000);
        }, 1000);
      } catch(error){
        console.log(error.message)
      }
    },
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    },
  }

  
}
</script>
<style>
  .loaderInfo {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #7D41F0;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>