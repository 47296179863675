import { createStore } from 'vuex'

export default createStore({
  state: {
    currentRoute: "",
    userAuth:null,
    eventAuth: '',
    policyVencimientos:null,
  },
  getters: {
  },
  mutations: {
    sessionChange(state, payload, event ){
      state.userAuth = payload ? payload : null;
      state.event = event
    },

    saveEvent(state, event ){
      state.eventAuth = event
    },

    save_route(state, route){
      state.currentRoute = route
    },

    SavePolicyVencimiento(state, data){
      state.policyVencimientos = data
    }
  },
  actions: {
  },
  modules: {
  }
})
