<template>
  <div class="w-full h-full relative">
    <div v-if="this.loaderBefore" class="h-full w-full flex flex-col justify-center items-center bg-white absolute z-10">
      <i class="fi fi-rr-spinner flex justify-center items-center text-6xl text-primario animate-spin"></i>
      <p class="w-full max-w-xs text-center mt-5 animate-bounce text-xl">Estamos cargando esta linda familia para ti</p>
    </div>

    <cliente-view v-else-if="this.viewClient" :clientId="this.clientId" @closeClient="closeClient"/>
    
    <div v-else-if="this.viewClient == false ">
      <div class="px-10 pt-10 pb-32 w-full h-screen " style="overflow-y:scroll;">
        <div v-if="this.sectionStatus == 'agregar'" class="mb-10 flex justify-between items-center">
          <div>
            <p class="text-texto text-3xl font-bold">Mis Clientes</p>
            <p class="text-texto">Clientes registrados</p>
            <!-- <button @click="sendMessageSms" class="py-2 px-10 text-white bg-primario rounded-xl">Botton prueba whatsapp</button> -->
          </div>
          <div>
            <div>
              <button @click="openSectionCrear" class="py-2 px-10 text-white bg-primario rounded-xl">Agregar cliente</button>
            </div>
          </div>
        </div>

        <agregar-cliente v-if="this.sectionStatus == 'crear'" @create-client="createClient" @cancelar="cancelar"/>

        <div class="mb-5 flex items-center">
          <div>
            <label for="filter" class="pl-2 text-texto">Ingresa el no. documento</label>
            <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
              <input v-model="this.documentFilter" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
            </div>
          </div>
          <div class="mt-6">
            <button  @click="filterClient" class="mr-2 py-2 px-2 text-primario border border-primario rounded-xl">
              <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
            </button>
          </div>
          <div class="mt-6">
            <button @click="removeFilter" v-if="this.filterStatus" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
              <p class="mr-2">Quitar filtro</p>
              <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
            </button>
          </div>
        </div>
        
        <div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-left">
              <thead class="text-texto bg-gray-50 ">
                <tr>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">No.</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Nombre completo</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Alias</th>
                  <th scope="col" class="px-6 py-3 text-center font-semibold">Correo</th>
                  <th scope="col" class="px-6 py-3 text-center font-semibold">Tipo identificacion</th>
                  <th scope="col" class="px-6 py-3 text-center font-semibold">No. identificacion</th>
                  <!-- <th scope="col" class="px-6 py-3 text-center font-semibold">Acciones</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(client, index) in this.clients" :key="index" @click="openSectionUpdate(client.id)" class="bg-white text-texto border-b cursor-pointer">
                  <td class="pr-6 py-4 text-center font-medium">{{index + 1}}</td>
                  <td class="pr-6 py-4 text-center font-medium">{{client.first_name}} {{ client.first_last_name }}</td>
                  <td class="pr-6 py-4 text-center font-medium">{{client.alias}}</td>
                  <td class="px-6 py-4 text-center">{{client.email}}</td>
                  <td class="px-6 py-4 text-center">{{client.type_document}}</td>
                  <td class="px-6 py-4 text-center">{{client.document}}</td>
                  <!-- <td v-if="client.rol != 'SuperAdmin'" class="px-6 py-4 text-center">
                      <i @click="openSectionUpdate(client.id)" class="flex justify-center items-center text-primario fi fi-rr-eye"></i>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="h-full flex justify-center items-center">
      <div class="flex items-center">
        <img class="mr-5 w-20" src="../../../assets/logo.png" alt="">
        <p class="text-5xl text-center text-texto font-bold">LO SENTIMOS <br> <span class="text-2xl">No tienes acceso a esta seccion</span> </p>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../supabase/init"
/* import { twilio } from "../../../twilio/init" */
import AgregarCliente from '../../../components/Mis clientes/AgregarCliente.vue'
import ClienteView from './ClienteView.vue'
import WhatsappMessage from '../../../services/WhatsappMessage'
import SmsMessage from '../../../services/SmsMessage'
/* import axios from 'axios' */




export default {
  components: { AgregarCliente, ClienteView },
  data() {
    return {
      loaderBefore: true,
      filterStatus: false,
      viewClient: false,
      currentUser:{},
      sectionStatus: "agregar",
      clients:[],
      clientId: '',
      proveedorStatus: 'estado',
      documentFilter: '',

      res1: {},
      res2: {},
    }
  },

  async mounted() {
    if (this.policyVencimientos) {
      this.openSectionUpdate(this.policyVencimientos.client_id)
    }
    await this.getUser()
    if (this.currentUser.rol == 'Agency' || this.currentUser.rol == 'Administracion') {
      await this.getClients()
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    }else {
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    }
    
  },

  methods: {
    /* async sendMessageWhatsapp(){
      try {
        await WhatsappMessage.post({
          To: "whatsapp:+573213850671",
          From: "whatsapp:+14155238886",
          Body: "Negro mk"
        }).then(res => {
          console.log(res);
        })
      } catch (error) {
        console.log(error)
      }
    }, */
    async sendMessageSms(){
      try {
        await SmsMessage.post({
          To: "+35699917872",
          MessagingServiceSid: "MG4800ebc74a52979d075a7889370df3fc",
          Body: "Hola Diego"
        }).then(res => {
          console.log(res);
        })
      } catch (error) {
        console.log(error)
      }
    }, 
    async getUser(){
      try{
        const { data, error } = await supabase.from('agencies').select('*').eq('id', this.userAuth.user.id)
        this.res1 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      }  

      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('id', this.userAuth.user.id)
        this.res2 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      } 

      if (this.res1) {
        this.currentUser = this.res1
      } else {
        this.currentUser = this.res2 
      }
    },

    async getClients(){
      if (this.res1) {
        try{
          const { data, error } = await supabase.from('clients_agencies').select('*').eq('agency_id', `${this.currentUser.id}`).order('created_at', { ascending: true })
          this.clients = data

          if(error) throw error;
        } catch (error){
          console.log(error.message)
        }
      } else {
        try{
          const { data, error } = await supabase.from('clients_agencies').select('*').eq('agency_id', `${this.currentUser.agency_id}`).order('created_at', { ascending: true })
          this.clients = data

          if(error) throw error;
        } catch (error){
          console.log(error.message)
        }
      }
    },

    openSectionCrear(){
      this.sectionStatus = "crear"
    },

    openSectionUpdate(id){
      this.loaderBefore = true
      this.clientId = id
      this.viewClient = true
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    },

    createClient(data){
      this.clients.unshift(data)
      this.sectionStatus = "agregar"
    },

    updateAgency(){
      this.getAgencies()
      this.clientView = ""
      this.sectionStatus = "agregar"
    },

    cancelar(){
      this.sectionStatus = "agregar"
    },

    closeClient(){
      this.loaderBefore = true
      this.viewClient = false
      this.clientId = ''
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    },

    async filterClient(){
      this.clients = []
      this.filterStatus = true

      if (this.res1) {
        try{
          const { data, error } = await supabase.from('clients_agencies').select('*').match({ agency_id: this.currentUser.id, document: this.documentFilter }).order('created_at', { ascending: true })
            if (data) {
            this.clients = data
          }

          if(error) throw error;
        } catch (error){
          console.log(error.message)
        }
      } else {
        try{
          const { data, error } = await supabase.from('clients_agencies').select('*').match({ agency_id: this.currentUser.agency_id, document: this.documentFilter }).order('created_at', { ascending: true })
            if (data) {
            this.clients = data
          }

          if(error) throw error;
        } catch (error){
          console.log(error.message)
        }
      }
    },

    removeFilter(){
      this.clients = []
      this.filterStatus = false
      this.documentFilter = ''
      this.getClients()
    }
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    },
    policyVencimientos(){
      return this.$store.state.policyVencimientos
    },
  }
}
</script>
<style>
  
</style>