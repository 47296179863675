import axios from 'axios';

const service = {
   post(data){
      return axios({
         method: "post",
         url: `${process.env.VUE_APP_TWILIO_API}/${process.env.VUE_APP_TWILIO_ACCOUNTSID}/Messages.json`,
         auth: { username: process.env.VUE_APP_TWILIO_ACCOUNTSID, password: process.env.VUE_APP_TWILIO_AUTHTOKEN},
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
         },

         data: data
      })
   },
}

export default service