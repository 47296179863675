<template>
  <div class="pt-5 w-full h-full flex justify-center items-start bg-black bg-opacity-10 left-0 top-0 z-10 rounded-xl absolute">
    <div v-if="this.deletStatus" class="w-full h-screen flex justify-center items-center bg-primario bg-opacity-30 top-0 left-0 fixed z-10">
      <div class="p-8 w-96 bg-white rounded-lg">
        <div class="mb-5 p-4 bg-red-600 bg-opacity-10 rounded-lg">
          <p class="text-center text-red-600 leading-none"> <span class="font-bold">AVISO</span> <br> Deseas eliminar el hogar actual?</p>
        </div>
        <div class="mb-10 w-full">
          <p class="text-texto text-center">Un vez eliminada no se podrá recuperar la informacion del hogar. <strong>Verifica que no tengas una poliza con ella antes de eliminarla!</strong></p>
        </div>
        <div class="grid grid-cols-2">
          <button @click="this.deletStatus = false" class="mr-1 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button v-if="this.viewLoader2 == 'delet'" @click="deletRisk" class="ml-1 py-2 px-10 text-white bg-primario rounded-xl">Eliminar</button>
          <button v-if="this.viewLoader2 == 'loader' || this.viewLoader2 == 'deleted' || this.viewLoader2 == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button2">
            <div v-if="this.viewLoader2 == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
              <p class="mr-3">Eliminado</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader2 == 'deleted'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Eliminado</p>
            </div>
            <div v-if="this.viewLoader2 == 'error'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Error</p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="p-5 max-w-screen-md h-screen bg-white border-gray-50 rounded-xl shadow-lg">
      <div class="mb-5 grid justify-center">
        <div class="">
          <p class="text-texto text-2xl text-center font-bold">Actualizar Riesgo</p>
        </div>
      </div>
      <div class="mb-10 text-texto">
        <div class="pr-5">
          <div class="p-5 w-full">
            <div class="pb-2 mb-5 border-b border-gray-300">
              <p class="font-bold">Datos del riesgo</p>
            </div>

            <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="address" class="">Direccion</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.address" @keyup="toUpperCaseAddress" autocomplete="off" type="text" placeholder="Ingrese la direccion de la casa" name="address" id="address" class="w-full border-none">
              </div>
            </div>
            <div class="pl-5">
              <label for="district" class="">Barrio</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-home"></i>
                <input v-model="this.formData.district" autocomplete="off" type="text" placeholder="Ingrese el barrio de la casa" name="district" id="district" class="w-full border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="mr-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label class="">Departamento</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-building"></i>
                    <select @change="getCities" name="type_schedule" id="type_schedule" class="w-full border-none" v-model="this.formData.department_id">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option v-for="(department, index) in this.departments" :key="index" :value="department.id">{{department.name.toUpperCase()}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label class="">Ciudad</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-home"></i>
                    <select name="type_schedule" id="type_schedule" class="w-full border-none" v-model="this.formData.city_id">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option v-for="(city, index) in this.cities" :key="index" :value="city.id">{{city.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div class="mb-5 grid grid-cols-2">
              <div class="pr-5">
                <div class="h-full grid" style="grid-template-row: auto 1fr;">
                  <div class="mb-auto">
                    <label for="status" class="">Estado</label>
                    <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                      <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                      <select name="status" id="status" class="w-full border-none" v-model="this.formData.status">
                        <option selected disabled value="seleccionar">Seleccionar</option>
                        <option :value="true">Activo</option>
                        <option :value="false">Inactivo</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl-5">
                <div class="h-full grid" style="grid-template-row: auto 1fr;">
                  <div class="mb-auto">
                    <label for="change_insured" class="">El asegurado es el mismo tomador?</label>
                    <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                      <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                      <select name="change_insured" id="change_insured" class="w-full border-none" @change="changeInsured" v-model="this.formData.change_insured">
                        <option selected disabled :value="null">Seleccionar</option>
                        <option :value="true">Si</option>
                        <option :value="false">No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-5 grid grid-cols-2">
              <div v-if="this.formData.change_insured === true" class="pr-5">
                <label for="insured_name" class="text-texto">Nombre del asegurado</label>
                <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-user"></i>
                  <input v-model="this.formData.insured_name" disabled type="text" autocomplete="off" placeholder="ejem... Sanchez Juan" name="insured_name" id="insured_name" class="w-full bg-gray-100 border-none">
                </div>
              </div>
              <div v-else class="pr-5">
                <label for="insured_name" class="text-texto">Nombre del asegurado</label>
                <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-user"></i>
                  <input v-model="this.formData.insured_name" type="text" autocomplete="off" placeholder="ejem... Sanchez Juan" name="insured_name" id="insured_name" class="w-full border-none">
                </div>
              </div>
              <div v-if="this.formData.change_insured === true" class="pl-5 h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="type_document_insured" class="text-texto">Tip. documento del asegurado</label>
                  <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-id-badge"></i>
                    <select name="type_document_insured" id="type_document_insured" disabled class="w-full bg-gray-100 text-texto border-none" v-model="this.formData.type_document_insured">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option value="cc">CC</option>
                      <option value="ce">CE</option>
                      <option value="nit">NIT</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-else class="pl-5 h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="type_document_insured" class="text-texto">Tip. documento del asegurado</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-id-badge"></i>
                    <select name="type_document_insured" id="type_document_insured" class="w-full text-texto border-none" v-model="this.formData.type_document_insured">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option value="cc">CC</option>
                      <option value="ce">CE</option>
                      <option value="nit">NIT</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-5 grid grid-cols-2">
              <div v-if="this.formData.change_insured === true" class="pr-5">
                <label for="insured_document" class="">Documento del asegurado</label>
                <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-hastag"></i>
                  <input v-model="this.formData.insured_document" type="text" disabled autocomplete="off" placeholder="Ingresa el documento del asegurado" name="insured_document" id="insured_document" class="w-full bg-gray-100 border-none">
                </div>
              </div>
              <div v-else class="pr-5">
                <label for="insured_document" class="">Documento del asegurado</label>
                <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-hastag"></i>
                  <input v-model="this.formData.insured_document" type="text" autocomplete="off" placeholder="Ingresa el documento del asegurado" name="insured_document" id="insured_document" class="w-full border-none">
                </div>
              </div>
            </div>

            <div class="mb-5 w-full flex">
              <div class="w-full">
                <div class="w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv" role="alert">
                  <svg aria-hidden="true" :class="this.classText" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                  <div class="transition-all" :class="this.classText">
                    <span class="font-medium">Alerta!</span>{{this.textAlert}}
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full flex justify-center">
              <button @click="$emit('cancelar')" class="mr-5 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
              <button @click="this.deletStatus = true" class="mr-5 py-2 px-10 text-red-600 border border-red-600 rounded-xl">Eliminar</button>
              <button v-if="this.viewLoader == 'update'" @click="updateData" class="ml-1 py-2 px-10 text-white bg-primario rounded-xl">Actualizar</button>
              <button v-if="this.viewLoader == 'loader' || this.viewLoader == 'updated' || this.viewLoader == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button">
                <div v-if="this.viewLoader == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
                  <p class="mr-3">Actualizando</p>
                  <div class="flex justify-center items-center">
                    <span class="loader"></span>
                  </div>
                </div>
                <div v-if="this.viewLoader == 'updated'" class="py-2 px-10 flex justify-center cursor-pointer">
                  <p class="mr-3">Actualizado</p>
                </div>
                <div v-if="this.viewLoader == 'error'" class="py-2 px-10 flex justify-center cursor-pointer">
                  <p class="mr-3">Error</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <p class="mb-5 text-texto text-2xl text-center font-bold">Polizas del Riesgo</p>
      <div class="">
        <div class="" style="height: 300px; overflow-y: scroll;">
          <table class="w-full text-left">
            <thead class="text-texto bg-gray-50 ">
              <tr>
                <th scope="col" class="pr-6 py-3 w-28 text-center font-semibold">No.</th>
                <th scope="col" class="pr-6 py-3 w-56 text-center font-semibold">Direccion</th>
                <th scope="col" class="pr-6 py-3 w-28 text-center font-semibold">Producto</th>
                <th scope="col" class="px-6 py-3 w-28 text-center font-semibold">Estado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(policy, index) in this.policies" :key="index" @click="$emit('redirect-policies')" class="bg-white text-texto border-b cursor-pointer">
                <td class="pr-6 py-4 w-28 text-center">{{index + 1}}</td>
                <td class="pr-6 py-4 w-56 text-center"><p class="w-56 truncate">{{policy.risks}}</p></td>
                <td class="pr-6 py-4 text-center font-medium"><p class="w-28 truncate">{{policy.product}}</p></td>
                <td class="px-6 py-4 text-center">
                    <div v-if="policy.remainingDays < 1" class="py-1 px-4 rounded-xl bg-red-600"><p class="text-white">Vencido</p></div>
                    <div v-else-if="policy.remainingDays < 60 && policy.remainingDays >= 30" class="py-1 px-4 rounded-xl bg-yellow-100"><p class="text-yellow-400">1 a 2 meses</p></div>
                    <div v-else-if="policy.remainingDays < 30" class="py-1 px-4 rounded-xl bg-red-100"><p class="text-red-600">-1 mes</p></div>
                    <div v-else class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">+2 meses</p></div>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <p v-if="this.policies.length" @click="$emit('redirect-policies')" class="mt-3 text-primario text-right cursor-pointer">Ver mas en la seccion de polizas...</p> -->
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../supabase/init"

export default {
  props: {
    riesgoId: String,
    currentClient: Object
  },
  components:{
  },
  data() {
    return {
      formData:{
        address: "",
        district: "",
        department_id: "seleccionar",
        type_vehicle: "seleccionar",
        insured_name: "",
        type_document_insured: "seleccionar",
        insured_document: "",
        change_insured: null,
        city_id: "seleccionar",
        status: '',
      },

      policies: [],
      departments:[],
      cities:[],

      viewLoader: 'update',
      bg_button: 'bg-primario',

      deletStatus: false,
      viewLoader2: 'delet',
      bg_button2: 'bg-primario',

      classDiv: "w-0 p-0",
      classText: "hidden",
      textAlert: "",
    }
  },

  async mounted() {
    await this.getRiesgo()
    await this.getDepartments()
    await this.getCities()
    await this.getPolicies()
  },

  methods: {
    toUpperCaseAddress() {
      this.formData.address = this.formData.address.toUpperCase();
    },

    async getDepartments(){
      try{
        const { data, error } = await supabase.from('departments').select('*').order('name', { ascending: true })
        this.departments = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getCities(){
      try{
        const { data, error } = await supabase.from('cities').select('*').eq('department_id', this.formData.department_id).order('name', { ascending: true })
        this.cities = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getRiesgo(){
      try{
        const { data, error } = await supabase.from('homes').select('*').eq('id', this.riesgoId)

        this.formData.id = data[0].id
        this.formData.address = data[0].address
        this.formData.district = data[0].district
        this.formData.department_id = data[0].department_id
        this.formData.insured_name = data[0].insured_name
        this.formData.type_document_insured = data[0].type_document_insured
        this.formData.insured_document = data[0].insured_document
        this.formData.city_id = data[0].city_id
        this.formData.status = data[0].status

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    changeInsured(){
      if (this.formData.change_insured) {
        this.formData.insured_name = `${this.currentClient.first_name} ${this.currentClient.first_last_name}`
        this.formData.type_document_insured = this.currentClient.type_document
        this.formData.insured_document = this.currentClient.document
      } else {
        this.formData.insured_name = ""
        this.formData.type_document_insured = "seleccionar"
        this.formData.insured_document = ""
      }
    },

    async getPolicies(){
      try{
        const { data, error } = await supabase.from('policies').select('*').match({ category: 'hogar', client_id: this.currentClient.id, risks: this.formData.address }).order('created_at', { ascending: true })
        data.forEach(async (policy) => {
            const now = new Date();
            const competition = new Date(policy.expiration);
            this.time = competition - now;
            const miliseconds = this.time;
            const seconds = miliseconds/1000;
            const minutes = seconds/60;
            const days = minutes/1440
            const result = Math.round(days)
            policy.remainingDays = result

            this.policies.unshift(policy)
        });

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async updateData(){
      this.viewLoader = 'loader'
      if (this.formData.address != '' && this.formData.district != '' && this.formData.department_id != 'seleccionar' && this.formData.city_id != 'seleccionar' && this.formData.insured_name != '' && this.formData.type_document_insured != 'seleccionar' && this.formData.insured_document != '') {
        try{
          let { data, error } = await supabase.from('homes').update({
            address: this.formData.address,
            district: this.formData.district,
            department_id: this.formData.department_id,
            insured_name: this.formData.insured_name,
            type_document_insured: this.formData.type_document_insured,
            insured_document: this.formData.insured_document,
            city_id: this.formData.city_id,
            status: this.formData.status
          }).eq('id', this.riesgoId)

          setTimeout(() => {
            this.viewLoader = 'updated'
            this.bg_button = 'bg-green-400'
          }, 1000);

          setTimeout(() => {
            this.viewLoader = 'update'
            this.bg_button = 'bg-primario'
            this.$emit('update-riesgo')
          }, 2000);
          
          if(error) throw error;
        } catch(error){
          console.log(error.message)
        } 
        
      }else {
        setTimeout(() => {
          this.viewLoader = 'error'
          this.bg_button = 'bg-red-400'
          this.classDiv = "w-72 p-4"
          this.classText = "block"
          this.textAlert = " No se admiten campos vacios."
        }, 1000);

        setTimeout(() => {
          this.viewLoader = 'create'
        this.bg_button = 'bg-primario'
          this.classDiv = "w-0 p-0"
          this.classText = "hidden"
        }, 4000);
      }
    },

    async deletRisk() {
      this.viewLoader2 = 'loader'

      try {
        const { error } = await supabase.from('homes').delete().eq('id', this.formData.id)
        if(error) throw error;

        setTimeout(() => {
          this.viewLoader2 = 'deleted'
          this.bg_button2 = 'bg-green-400'
        }, 1000);
        
        setTimeout(() => {
          this.viewLoader2 = 'delet'
          this.bg_button2 = 'bg-primario'

          this.deletStatus = false
          this.$emit('cancelar')
        }, 3000);
      } catch (error) {
        console.log(error.message)
      }
    },
  },
}
</script>
<style>
  
</style>