<template>
  <div class="">
    <div v-if="this.verifyAuth" class="w-full h-full flex justify-center items-center bg-black bg-opacity-20 left-0 top-0 rounded-lg absolute z-10">
      <div class="p-8 w-96 bg-white rounded-lg">
        <div class="mb-10 p-4 bg-primario bg-opacity-10 rounded-lg">
          <p class="text-center text-primario leading-none"> <span class="font-bold">Verifica la cuenta</span> actual para seguir con el registro</p>
        </div>
        <div class="mb-10 w-full">
          <label for="verifyPassword" class="">Contraseña</label>
          <div class="mb-1 px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
            <input v-model="this.verifyPassword" autocomplete="off" type="password" placeholder="Ingresa la contraseña" id="verifyPassword" class="w-full border-none">
          </div>
          <span class="text-sm text-texto">Ingresa la contraseña del inicio de sesion</span>
          <div class="w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv2" role="alert">
          <svg aria-hidden="true" :class="this.classText2" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <div class="transition-all" :class="this.classText2">
              <span class="font-medium">Alerta!</span>{{this.textAlert2}}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2">
          <button @click="this.verifyAuth = false" class="mr-1 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button v-if="this.viewLoader == 'create'" @click="loginVerify" class="ml-1 py-2 px-10 text-white bg-primario rounded-xl">Crear</button>
          <button v-if="this.viewLoader == 'loader' || this.viewLoader == 'created'" class="ml-1 text-white rounded-xl" :class="this.bg_button">
            <div v-if="this.viewLoader == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
              <p class="mr-3">Creando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader == 'created'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Creado</p>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="mb-10 flex justify-between items-center">
      <div>
        <p class="text-texto text-3xl font-bold">Equipo</p>
        <p class="text-texto">Crea el equipo de helprime para administrar el dashboard</p>
      </div>
      <div>
        <div>
          <button @click="$emit('cancelar')" class="mr-5 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button @click="openModal" class="py-2 px-10 text-white bg-primario rounded-xl">Crear</button>
        </div>
      </div>
    </div>
    <div class="mb-10 text-texto">
      <div class="pr-5">
        <div class="p-5 w-full border border-gray-50 rounded-xl shadow-lg">
          <div class="pb-2 mb-5 border-b border-gray-300">
            <p class="font-bold">Informacion del administrador</p>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="fullname" class="">Nombres</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.fullname" type="text" autocomplete="off" placeholder="Ingresa los nombres" name="fullname" id="fullname" class="w-full border-none">
              </div>
            </div>
            <div class="pl-5">
              <label for="email" class="">Correo electronico</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-at"></i>
                <input v-model="this.formData.email" type="email" autocomplete="off" placeholder="Ingresa el correo electronico" name="email" id="email" class="w-full border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="whatsapp" class="">Numero de whatsapp</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-comment"></i>
                <input v-model="this.formData.whatsapp" type="number" autocomplete="off" placeholder="Ingresa numero de whatsapp" name="whatsapp" id="whatsapp" class="w-full border-none">
              </div>
            </div>
            <div class="pl-5">
              <label for="contact_number" class="">Numero de contacto</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-phone-call"></i>
                <input v-model="this.formData.contact_number" type="number" autocomplete="off" placeholder="Ingresa numero de contacto" name="contact_number" id="contact_number" class="w-full border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="mr-5 flex">
              <div class="mr-5 h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="type_identification" class="">Tip. documento</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-id-badge"></i>
                    <select name="type_identification" id="type_identification" class="w-full border-none" v-model="this.formData.type_document">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option value="cc">CC</option>
                      <option value="ce">CE</option>
                      <option value="nit">NIT</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <label for="document" class="">No. documento</label>
                <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-hastag"></i>
                  <input v-model="this.formData.document" :type="type" autocomplete="off" placeholder="Ingresa documento" name="document" class="w-full border-none">
                </div>
              </div>
            </div>
            <div class="pl-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="rol" class="">Rol</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                    <select name="rol" id="rol" class="w-full border-none" v-model="this.formData.rol">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option value="admin">Administracion</option>
                      <option value="advisor">Asesor</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 w-full flex">
            <div class="w-full">
              <div class="w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv" role="alert">
                <svg aria-hidden="true" :class="this.classText" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div class="transition-all" :class="this.classText">
                  <span class="font-medium">Alerta!</span>{{this.textAlert}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../supabase/init"
import emailjs from '@emailjs/browser';

export default {
  components: {
   
  },
  data() {
    return {
      formData:{
        fullname: "",
        email: "",
        whatsapp: "",
        contact_number: "",
        type_document: "seleccionar",
        document: '',
        rol: "seleccionar",
      },
      type: "text",

      verifyAuth: false,
      verifyPassword: '',
      currentAuth:{},
      viewLoader: 'create',
      bg_button: 'bg-primario',

      classDiv: "w-0 p-0",
      classText: "hidden",
      textAlert: "",

      classDiv2: "w-0 p-0",
      classText2: "hidden",
      textAlert2: "",

      sendNewTeam: {
        contributor_name: '',
        agency_name: '',
        agency_number: '',
        contributor_document: '',
        email: '',
      },
    }
  },

  async mounted() {
    await this.getUser()
  },
  
  methods: {
    async getUser(){
      try{
        const { data, error } = await supabase.from('agencies').select('*').eq('id', this.userAuth.user.id)
        this.res1 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      }  

      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('id', this.userAuth.user.id)
        this.res2 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      } 

      if (this.res1) {
        this.currentUser = this.res1
      } else {
        this.currentUser = this.res2 
      }
    },

    async openModal(){
      this.formData.email = this.formData.email.toLowerCase()
      if (this.formData.fullname != '' && this.formData.email != '' && this.formData.whatsapp != '' && this.formData.contact_number != '' && this.formData.document != '' && this.formData.type_document != 'seleccionar' && this.formData.rol != 'seleccionar') {
        try {
          let { data, error } = await supabase.from('team').select('*').eq('email', `${this.formData.email}`)
          
          if (data[0]) {
            this.classDiv = "w-72 p-4"
            this.classText = "block"
            this.textAlert = " Este correo ya ha sido registrado."

            setTimeout(() => {
              this.classDiv = "w-0 p-0"
              this.classText = "hidden"
            }, 5000);
          }else{
            try {
              let { data, error} = await supabase.from('team_agencies').select('*').eq('email', `${this.formData.email}`)
  
              if (data[0]) {
                this.classDiv = "w-72 p-4"
                this.classText = "block"
                this.textAlert = " Este correo ya ha sido registrado."

                setTimeout(() => {
                  this.classDiv = "w-0 p-0"
                  this.classText = "hidden"
                }, 5000);
              }else{
                try {
                  let { data, error} = await supabase.from('agencies').select('*').eq('email', `${this.formData.email}`)
      
                  if (data[0]) {
                    this.classDiv = "w-72 p-4"
                    this.classText = "block"
                    this.textAlert = " Este correo ya ha sido registrado."

                    setTimeout(() => {
                      this.classDiv = "w-0 p-0"
                      this.classText = "hidden"
                    }, 5000);
                  }else{
                    try {
                      let { data, error} = await supabase.from('clients_agencies').select('*').eq('email', `${this.formData.email}`)
          
                      if (data[0]) {
                        this.classDiv = "w-72 p-4"
                        this.classText = "block"
                        this.textAlert = " Este correo ya ha sido registrado."

                        setTimeout(() => {
                          this.classDiv = "w-0 p-0"
                          this.classText = "hidden"
                        }, 5000);
                      }else{
                        try {
                          let { data, error} = await supabase.from('clients_helprime').select('*').eq('email', `${this.formData.email}`)
              
                          if (data[0]) {
                            this.classDiv = "w-72 p-4"
                            this.classText = "block"
                            this.textAlert = " Este correo ya ha sido registrado."

                            setTimeout(() => {
                              this.classDiv = "w-0 p-0"
                              this.classText = "hidden"
                            }, 5000);
                          }else{
                            if (this.res1) {
                              try{
                                const { data, error } = await supabase.from('team_agencies').select('*').match({ type_document: this.formData.type_document, document: this.formData.document, agency_id: this.currentUser.id}).order('created_at', { ascending: true })
                                if (data.length) {
                                  this.classDiv = "w-72 p-4"
                                  this.classText = "block"
                                  this.textAlert = " El numero de documento ingresado ya existe en el equipo."

                                  setTimeout(() => {
                                    this.classDiv = "w-0 p-0"
                                    this.classText = "hidden"
                                  }, 5000);
                                } else {
                                  this.verifyAuth = true
                                }

                                if(error) throw error;
                              } catch (error){
                                console.log(error.message)
                              }

                            } else {
                              try{
                                const { data, error } = await supabase.from('team_agencies').select('*').match({ type_document: this.formData.type_document, document: this.formData.document, agency_id: this.currentUser.agency_id}).order('created_at', { ascending: true })
                                if (data.length) {
                                  this.classDiv = "w-72 p-4"
                                  this.classText = "block"
                                  this.textAlert = " El numero de documento ingresado ya existe en el equipo."

                                  setTimeout(() => {
                                    this.classDiv = "w-0 p-0"
                                    this.classText = "hidden"
                                  }, 5000);
                                } else {
                                  this.verifyAuth = true
                                }

                                if(error) throw error;
                              } catch (error){
                                console.log(error.message)
                              }
                            }
                          }

                          if(error) throw error;
                        } catch (error) {
                          console.log(error.message);
                        }
                      }

                      if(error) throw error;
                    } catch (error) {
                      console.log(error.message);
                    }
                  }

                  if(error) throw error;
                } catch (error) {
                  console.log(error.message);
                }
              }

              if(error) throw error;
            } catch (error) {
              console.log(error.message);
            }
          }

          if(error) throw error;
        } catch (error) {
          console.log(error.message);
        }

      }else {
        this.classDiv = "w-72 p-4"
        this.classText = "block"
        this.textAlert = " No se admiten campos vacios."

        setTimeout(() => {
          this.classDiv = "w-0 p-0"
          this.classText = "hidden"
        }, 5000);
      }
    },

    async loginVerify(){
      this.currentAuth = this.userAuth.user
      this.viewLoader = 'loader'
      try {
        let { user, error } = await supabase.auth.signIn({
          email: this.currentAuth.email,
          password: this.verifyPassword
        })

        if (user) {
          this.registeredUser()
        } 

        if(error) throw error;
      } catch (error) {
        setTimeout(() => {
          this.viewLoader = 'create'
          if (error.message == "Invalid login credentials") {
            this.classDiv2 = "w-72 p-4"
            this.classText2 = "block"
            this.textAlert2 = " Correo o contraseña incorrectos."
  
            setTimeout(() => {
              this.classDiv2 = "w-0 p-0"
              this.classText2 = "hidden"
            }, 5000);
          }
        }, 2000);
      }
    },

    async registeredUser(){
      try{
        let {user,session, error } = await supabase.auth.signUp({
          email: this.formData.email,
          password: this.formData.document
        })
        this.type = 'text'
        this.createUser(user.id)
        

        if(error) throw error;
      } catch(error){
        console.log(error.message)
      }
    },

    async createUser(id){
      if (this.res1) {
        try{
          let { data, error } = await supabase.from('team_agencies').insert([{
            id: id,
            fullname: this.formData.fullname,
            email: this.formData.email,
            whatsapp: this.formData.whatsapp,
            contact_number: this.formData.contact_number,
            type_document: this.formData.type_document,
            document: this.formData.document,
            rol: this.formData.rol,
            agency_id: this.currentUser.id,
          }])

          try {
            let { user, error } = await supabase.auth.signIn({
              email: this.currentAuth.email,
              password: this.verifyPassword
            })

              this.bg_button = 'bg-green-400'
              this.viewLoader = 'created'
              this.sendEmailNewTeam()
              this.sendNewTeam.contributor_name = ''
              this.sendNewTeam.email = ''
              this.sendNewTeam.agency_name = ''
              this.sendNewTeam.agency_number = ''
              this.sendNewTeam.contributor_document = ''

              setTimeout(() => {
                this.$emit('create-team', data[0])
                this.bg_button = 'create'
                this.viewLoader = 'bg-primario'
              }, 3000);

            if(error) throw error;
          } catch (error) {
            console.log(error.message);
          }

          if(error) throw error;
        } catch(error){
          console.log(error.message)
        }
      } else {
        try{
          let { data, error } = await supabase.from('team_agencies').insert([{
            id: id,
            fullname: this.formData.fullname,
            email: this.formData.email,
            whatsapp: this.formData.whatsapp,
            contact_number: this.formData.contact_number,
            type_document: this.formData.type_document,
            document: this.formData.document,
            rol: this.formData.rol,
            agency_id: this.currentUser.agency_id,
          }])

          try {
            let { user, error } = await supabase.auth.signIn({
              email: this.currentAuth.email,
              password: this.verifyPassword
            })

              this.bg_button = 'bg-green-400'
              this.viewLoader = 'created'
              this.sendEmailNewTeam()
              this.sendNewTeam.contributor_name = ''
              this.sendNewTeam.email = ''
              this.sendNewTeam.agency_name = ''
              this.sendNewTeam.agency_number = ''
              this.sendNewTeam.contributor_document = ''

              setTimeout(() => {
                this.$emit('create-team', data[0])
                this.bg_button = 'create'
                this.viewLoader = 'bg-primario'
              }, 3000);

            if(error) throw error;
          } catch (error) {
            console.log(error.message);
          }

          if(error) throw error;
        } catch(error){
          console.log(error.message)
        }
      }
    },

    async sendEmailNewTeam() {
      if (this.res1) {
        this.sendNewTeam.agency_name = this.currentUser.fullname
        this.sendNewTeam.agency_number = this.currentUser.contact_number
      } else {
        try{
          const { data, error } = await supabase.from('agencies').select('*').eq('id', this.currentUser.agency_id)
          this.sendNewTeam.agency_name = data[0].fullname
          this.sendNewTeam.agency_number = data[0].contact_number
          if(error) throw error;
        } catch (e){
          console.log(e.message)
        } 
      }
      this.sendNewTeam.contributor_name = this.formData.fullname
      this.sendNewTeam.email = this.formData.email
      this.sendNewTeam.contributor_document = this.formData.document

      emailjs.send('service_5njuyze', 'template_bvgi7qb', this.sendNewTeam, 'pfz8wFvetYK3c-deO')/* .then((result) => {
          console.log('SUCCESS!', result.text);
        }, (error) => {
          console.log('FAILED...', error.text);
        }); */
    },
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    }
  }
}
</script>
<style>
  .loader {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #FFF;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
</style>