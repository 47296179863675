<template>
  <div class="w-full h-full relative">

    <div class="flex justify-center">
      <div class="mr-5">
        <button @click="$emit('closeVehicles')" class="py-2 px-10 text-primario border border-primario rounded-xl">Atras</button>
      </div>
      <div class="mr-5">
        <button @click="$emit('changeSection', 'placas')" class="py-2 px-10 text-primario border border-primario bg-white rounded-xl">Riesgos</button>
      </div>
      <div class="mr-5">
        <button class="py-2 px-10 text-white bg-primario rounded-xl">Polizas</button>
      </div>
    </div>

    <div >
      <div ref="scrollContainer" class="px-10 pt-10 pb-32 w-full h-screen " style="overflow-y:scroll;">
        <div v-if="this.sectionStatus == 'agregar'" class="mb-10 flex justify-between items-center">
          <div>
            <p class="text-texto text-3xl font-bold">Polizas de Autos</p>
            <!-- <p class="text-texto">Clientes registrados</p> -->
          </div>
          <div>
            <div>
              <button @click="openSectionCrear" class="py-2 px-10 text-white bg-primario rounded-xl">Agregar poliza</button>
            </div>
          </div>
        </div>

        <agregar-poliza v-if="this.sectionStatus == 'crear'" :currentClient="this.currentClient" @create-policy="createPolicy" @cancelar="cancelar"/>
        <actualizar-poliza v-if="this.sectionStatus == 'actualizar'" :currentClient="this.currentClient" :policyId="this.policyView" @update-policy="updatePolicy" @cancelar="cancelar"/>

        <div class="mb-5 flex">
          <div class="py-1 w-80 mr-5">
            <label for="filter" class="pl-2 text-texto">Filtrar por:</label>
            <div class="py-1 w-full flex items-center border border-gray-300 rounded-xl">
              <select name="filter" id="filter" class="w-full border-none" @change="changeFilter" v-model="this.typeFilter">
                <option selected disabled value="seleccionar">Seleccionar</option>
                <option value="placa">Placa</option>
                <option value="id_insured">Id Asegurado</option>
              </select>
            </div>
          </div>

          <div v-if="this.typeFilter == 'placa'" class="flex items-center">
            <div>
              <label for="filter" class="pl-2 text-texto">Ingresa el no. placa</label>
              <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
                <input v-model="this.documentFilter" @keyup="toUpperCase" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
              </div>
              <p class="mt-1 text-red-500">{{this.alertFilter}}</p>
            </div>
            <div class="mt-6">
              <button  @click="filterPolicy" class="mr-2 py-2 px-2 text-primario border border-primario rounded-xl">
                <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
              </button>
            </div>
            <div class="mt-6">
              <button @click="removeFilter" v-if="this.filterStatus" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
                <p class="mr-2">Quitar filtro</p>
                <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
              </button>
            </div>
          </div>

          <div v-if="this.typeFilter == 'id_insured'" class="flex items-center">
            <div>
              <label for="filter" class="pl-2 text-texto">Ingresa el no. documento del asegurado</label>
              <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
                <input v-model="this.documentFilter2" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
              </div>
              <p class="mt-1 text-red-500">{{this.alertFilter2}}</p>
            </div>

            <div class="mt-6">
              <button  @click="filterPolicy2" class="mr-2 py-2 px-2 text-primario border border-primario rounded-xl">
                <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
              </button>
            </div>
            <div class="mt-6">
              <button @click="removeFilter" v-if="this.filterStatus2" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
                <p class="mr-2">Quitar filtro</p>
                <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
              </button>
            </div>
          </div>
        </div>
        
        <div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-left">
              <thead class="text-texto bg-gray-50 ">
                <tr>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">No.</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Asegurado</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Documento</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Placa</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Producto</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Aseguradora</th>
                  <th scope="col" class="px-6 py-3 text-center font-semibold">Vencimiento</th>
                </tr>
              </thead>
              <tbody v-if="this.policiesFilter.length">
                <tr v-for="(policy, index) in this.policiesFilter" :key="index" class="bg-white text-texto border-b cursor-pointer">
                  <td @click="openSectionUpdate(policy.id)" class="pr-6 py-4 text-center font-medium" >{{index + 1}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="pr-6 py-4 text-center font-medium" >{{policy.insured_name}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="pr-6 py-4 text-center font-medium" >{{policy.insured_document}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="pr-6 py-4 text-center font-medium" >{{policy.risks}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="pr-6 py-4 text-center font-medium" >{{policy.product}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="px-6 py-4 text-center" >{{policy.insurance_company?.name}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="px-6 py-4 text-center" >{{policy.expiration}}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(policy, index) in this.policies" :key="index" class="bg-white text-texto border-b cursor-pointer">
                  <td @click="openSectionUpdate(policy.id)" class="pr-6 py-4 text-center font-medium" >{{index + 1}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="pr-6 py-4 text-center font-medium" >{{policy.insured_name}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="pr-6 py-4 text-center font-medium" >{{policy.insured_document}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="pr-6 py-4 text-center font-medium" >{{policy.risks}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="pr-6 py-4 text-center font-medium" >{{policy.product}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="px-6 py-4 text-center font-medium" >{{policy.insurance_company?.name}}</td>
                  <td @click="openSectionUpdate(policy.id)" class="px-6 py-4 text-center font-medium" >{{policy.expiration}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../../supabase/init"
import AgregarPoliza from '@/components/Autos/polizas/AgregarPoliza.vue'
import ActualizarPoliza from '@/components/Autos/polizas/ActualizarPoliza.vue'
/* import ClienteView from './ClienteView.vue' */
export default {
  components: {AgregarPoliza, ActualizarPoliza },
  props: {
    currentClient: Object
  },
  data() {
    return {
      loaderBefore: true,
      filterStatus: false,
      viewClient: false,
      currentUser:{},
      sectionStatus: "agregar",
      policies:[],
      policiesFilter: [
        /* {
          license_plate_name: 'QWE123',
          product: 'SOAT',
          insurance_company: 'insurance_company',
          status: '+2 meses',
        } */
      ],
      policyView: '',
      proveedorStatus: 'estado',
      
      typeFilter: 'seleccionar',

      documentFilter: '',
      filterStatus: false,
      alertFilter: '',

      documentFilter2: '',
      filterStatus2: false,
      alertFilter2: '',

      meses: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    }
  },

  async mounted() {
    await this.getPolicies()
  },

  methods: {
    async getPolicies(){
      try{
        const { data, error } = await supabase.from('policies').select('*, insurance_company(*)').match({ category: 'autos', client_id: this.currentClient.id }).order('created_at', { ascending: true })
        data.forEach(async (policy) => {
          const now = new Date();
          const competition = new Date(policy.expiration);
          this.time = competition - now;
          const miliseconds = this.time;
          const seconds = miliseconds/1000;
          const minutes = seconds/60;
          const days = minutes/1440
          const result = Math.round(days)
          policy.remainingDays = result

          let fecha = new Date(policy.expiration)
          policy.expiration = fecha.getDate() + ' de ' + this.meses[fecha.getMonth()] + ' ' + fecha.getUTCFullYear()

          this.policies.unshift(policy)
          this.policies.sort(function(a, b){
            return a.remainingDays - b.remainingDays
          })
        });

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    openSectionCrear(){
      this.sectionStatus = "crear"
    },

    openSectionUpdate(id){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      const scrollContainer = this.$refs.scrollContainer;
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }

      if (this.policyView != "") {
        this.sectionStatus = "agregar"
        this.policyView = ""
        setTimeout(() => {
          this.policyView = id
          this.sectionStatus = "actualizar"
        }, 100);
      }else{ 
        this.policyView = id
        this.sectionStatus = "actualizar"
      }
    },

    createPolicy(){
      this.policies = []
      this.getPolicies()
      this.sectionStatus = "agregar"
    },

    updatePolicy(){
      this.policies = []
      this.getPolicies()
      this.removeFilter()
      this.typeFilter = 'seleccionar'
      this.policyView = ""
      this.sectionStatus = "agregar"
    },

    cancelar(){
      this.sectionStatus = "agregar"
      this.policies = []
      this.getPolicies()
    },

    closeClient(){
      this.loaderBefore = true
      this.viewClient = false
      this.clientId = ''
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    },

    toUpperCase() {
        this.documentFilter = this.documentFilter.toUpperCase();
    },

    filterPolicy(){
      this.policiesFilter = this.policies.filter(policy => policy.risks == this.documentFilter)

      if (this.policiesFilter.length) {
        this.filterStatus = true
      } else {
        let currentData = this.policies
        this.policies = []
        this.alertFilter = 'No se encontraron resultados'

        setTimeout(() => {
          this.alertFilter = ''
          this.policies = currentData
        }, 2000);
      }
    },

    filterPolicy2(){
      this.policiesFilter = this.policies.filter(policy => policy.insured_document == this.documentFilter2)

      if (this.policiesFilter.length) {
        this.filterStatus2 = true

      } else {
        let currenData = this.policies
        this.policies = []
        this.alertFilter2 = 'No se encontraron resultados'

        setTimeout(() => {
          this.policies = currenData
          this.alertFilter2 = ''
          this.removeFilter()
        }, 2000);
      }
    },

    removeFilter(){
      this.policiesFilter = []
      this.documentFilter = ''
      this.documentFilter2 = ''
      this.filterStatus = false
      this.filterStatus2 = false
    },

    changeFilter(){
      this.removeFilter()
    }, 

  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    },
  }
}
</script>
<style>
  
</style>