<template>
  <div class="">
    <div class="mb-10 flex justify-between items-center">
      <div>
        <p class="text-texto text-3xl font-bold">Agregar Riesgo</p>
      </div>
      <div>
        <div>
          <button @click="$emit('cancelar')" class="mr-5 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button v-if="this.viewLoader == 'create'" @click="createPlaca" class="ml-1 py-2 px-10 text-white bg-primario rounded-xl">Crear</button>
          <button v-if="this.viewLoader == 'loader' || this.viewLoader == 'created' || this.viewLoader == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button">
            <div v-if="this.viewLoader == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
              <p class="mr-3">Creando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader == 'created'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Creado</p>
            </div>
            <div v-if="this.viewLoader == 'error'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Error</p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="mb-10 text-texto">
      <div class="pr-5">
        <div class="p-5 w-full border border-gray-50 rounded-xl shadow-lg">
          <div class="pb-2 mb-5 border-b border-gray-300">
            <p class="font-bold">Datos del riesgo</p>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="address" class="">Direccion</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.address" @keyup="toUpperCaseAddress" autocomplete="off" type="text" placeholder="Ingrese la direccion de la casa" name="address" id="address" class="w-full border-none">
              </div>
            </div>
            <div class="pl-5">
              <label for="district" class="">Barrio</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-home"></i>
                <input v-model="this.formData.district" @keyup="toUpperCaseDistrict" autocomplete="off" type="text" placeholder="Ingrese el barrio de la casa" name="district" id="district" class="w-full border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="mr-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label class="">Departamento</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-building"></i>
                    <select @change="getCities" name="type_schedule" id="type_schedule" class="w-full border-none" v-model="this.formData.department_id">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option v-for="(department, index) in this.departments" :key="index" :value="department.id">{{department.name.toUpperCase()}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label class="">Ciudad</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-home"></i>
                    <select name="type_schedule" id="type_schedule" class="w-full border-none" v-model="this.formData.city_id">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option v-for="(city, index) in this.cities" :key="index" :value="city.id">{{city.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="change_insured" class="">El asegurado es el mismo tomador?</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                    <select name="change_insured" id="change_insured" class="w-full border-none" @change="changeInsured" v-model="this.formData.change_insured">
                      <option selected disabled :value="null">Seleccionar</option>
                      <option :value="true">Si</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="this.formData.change_insured === true || this.formData.change_insured === null" class="pl-5">
              <label for="insured_name" class="text-texto">Nombre del asegurado</label>
              <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.insured_name" disabled type="text" autocomplete="off" placeholder="ejem... Sanchez Juan" name="insured_name" id="insured_name" class="w-full bg-gray-100 border-none">
              </div>
            </div>
            <div v-else class="pl-5">
              <label for="insured_name" class="text-texto">Nombre del asegurado</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.insured_name" type="text" autocomplete="off" placeholder="ejem... Sanchez Juan" name="insured_name" id="insured_name" class="w-full border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div v-if="this.formData.change_insured === true || this.formData.change_insured === null" class="pr-5 h-full grid" style="grid-template-row: auto 1fr;">
              <div class="mb-auto">
                <label for="type_document_insured" class="text-texto">Tip. documento del asegurado</label>
                <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-id-badge"></i>
                  <select name="type_document_insured" id="type_document_insured" disabled class="w-full bg-gray-100 text-texto border-none" v-model="this.formData.type_document_insured">
                    <option selected disabled value="seleccionar">Seleccionar</option>
                    <option value="cc">CC</option>
                    <option value="ce">CE</option>
                    <option value="nit">NIT</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-else class="pr-5 h-full grid" style="grid-template-row: auto 1fr;">
              <div class="mb-auto">
                <label for="type_document_insured" class="text-texto">Tip. documento del asegurado</label>
                <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-id-badge"></i>
                  <select name="type_document_insured" id="type_document_insured" class="w-full text-texto border-none" v-model="this.formData.type_document_insured">
                    <option selected disabled value="seleccionar">Seleccionar</option>
                    <option value="cc">CC</option>
                    <option value="ce">CE</option>
                    <option value="nit">NIT</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="this.formData.change_insured === true || this.formData.change_insured === null" class="pl-5">
              <label for="insured_document" class="">Documento del asegurado</label>
              <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-hastag"></i>
                <input v-model="this.formData.insured_document" type="text" disabled autocomplete="off" placeholder="Ingresa el documento del asegurado" name="insured_document" id="insured_document" class="w-full bg-gray-100 border-none">
              </div>
            </div>
            <div v-else class="pl-5">
              <label for="insured_document" class="">Documento del asegurado</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-hastag"></i>
                <input v-model="this.formData.insured_document" type="text" autocomplete="off" placeholder="Ingresa el documento del asegurado" name="insured_document" id="insured_document" class="w-full border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 w-full flex">
            <div class="w-full">
              <div class="w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv" role="alert">
                <svg aria-hidden="true" :class="this.classText" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div class="transition-all" :class="this.classText">
                  <span class="font-medium">Alerta!</span>{{this.textAlert}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../supabase/init"

export default {
  components: {},
  props: {
    currentClient: Object
  },
  data() {
    return {
      formData:{
        address: "",
        district: "",
        department_id: "seleccionar",
        insured_name: "",
        type_document_insured: "seleccionar",
        insured_document: "",
        change_insured: null,
        city_id: "seleccionar",
        client_id: '',
      },

      departments:[],
      cities:[],

      viewLoader: 'create',
      bg_button: 'bg-primario',

      classDiv: "w-0 p-0",
      classText: "hidden",
      textAlert: "",
    }
  },

  async mounted() {
    await this.getDepartments()
  },
  
  methods: {
    toUpperCaseAddress() {
      this.formData.address = this.formData.address.toUpperCase();
    },

    toUpperCaseDistrict() {
      this.formData.district = this.formData.district.toUpperCase();
    },

    async getDepartments(){
      try{
        const { data, error } = await supabase.from('departments').select('*').order('name', { ascending: true })
        this.departments = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getCities(){
      try{
        const { data, error } = await supabase.from('cities').select('*').eq('department_id', this.formData.department_id).order('name', { ascending: true })
        this.cities = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    changeInsured(){
      if (this.formData.change_insured) {
        this.formData.insured_name = `${this.currentClient.first_name} ${this.currentClient.first_last_name}`
        this.formData.type_document_insured = this.currentClient.type_document
        this.formData.insured_document = this.currentClient.document
      } else {
        this.formData.insured_name = ""
        this.formData.type_document_insured = "seleccionar"
        this.formData.insured_document = ""
      }
    },

    async createPlaca(){
      this.viewLoader = 'loader'
      if (this.formData.address != '' && this.formData.district != '' && this.formData.department_id != 'seleccionar' && this.formData.city_id != 'seleccionar' && this.formData.insured_name != '' && this.formData.type_document_insured != 'seleccionar' && this.formData.insured_document != '') {
        try {
          let { data, error } = await supabase.from('homes').select('*').match({ address: this.formData.address, client_id: this.currentClient.id })

          if (data[0]) {
            setTimeout(() => {
              this.viewLoader = 'error'
              this.bg_button = 'bg-red-400'
              this.classDiv = "w-72 p-4"
              this.classText = "block"
              this.textAlert = " La casa ingresada ya ha sido creado para el actual cliente."
            }, 1000);

            setTimeout(() => {
              this.viewLoader = 'create'
            this.bg_button = 'bg-primario'
              this.classDiv = "w-0 p-0"
              this.classText = "hidden"
            }, 4000);
          } else {
            this.formData.client_id = this.currentClient.id
            try{
              let { data, error } = await supabase.from('homes').insert([{
                address: this.formData.address,
                district: this.formData.district,
                department_id: this.formData.department_id,
                city_id: this.formData.city_id,
                insured_name: this.formData.insured_name,
                type_document_insured: this.formData.type_document_insured,
                insured_document: this.formData.insured_document,
                client_id: this.formData.client_id,
              }])

              setTimeout(() => {
                this.viewLoader = 'created'
                this.bg_button = 'bg-green-400'
              }, 1000);

              setTimeout(() => {
                this.viewLoader = 'create'
                this.bg_button = 'bg-primario'
                this.$emit('create-riesgo', data[0])
              }, 2000);

              if(error) throw error;
            } catch(error){
              console.log(error.message)
            } 
          }

          if(error) throw error;
        } catch (error) {
          console.log(error.message);
        }
        
      }else {
        setTimeout(() => {
          this.viewLoader = 'error'
          this.bg_button = 'bg-red-400'
          this.classDiv = "w-72 p-4"
          this.classText = "block"
          this.textAlert = " No se admiten campos vacios."
        }, 1000);

        setTimeout(() => {
          this.viewLoader = 'create'
        this.bg_button = 'bg-primario'
          this.classDiv = "w-0 p-0"
          this.classText = "hidden"
        }, 4000);
      }
    },
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    }
  }
}
</script>
<style>
  .loader {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #FFF;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
</style>