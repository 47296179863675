<template>
  <div class="">
    <div v-if="this.verifyAuth" class="w-full h-full flex justify-center items-center bg-black bg-opacity-20 left-0 top-0 rounded-lg absolute z-10">
      <div class="p-8 w-96 bg-white rounded-lg">
        <div class="mb-10 p-4 bg-primario bg-opacity-10 rounded-lg">
          <p class="text-center text-primario leading-none"> <span class="font-bold">Verifica la cuenta</span> actual para seguir con el registro</p>
        </div>
        <div class="mb-10 w-full">
          <label for="verifyPassword" class="">Contraseña</label>
          <div class="mb-1 px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
            <input v-model="this.verifyPassword" autocomplete="off" type="password" placeholder="Ingresa la contraseña" id="verifyPassword" class="w-full border-none">
          </div>
          <span class="text-sm text-texto">Ingresa la contraseña del inicio de sesion</span>
          <div class="w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv2" role="alert">
          <svg aria-hidden="true" :class="this.classText2" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <div class="transition-all" :class="this.classText2">
              <span class="font-medium">Alerta!</span>{{this.textAlert2}}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2">
          <button @click="this.verifyAuth = false" class="mr-1 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button v-if="this.viewLoader == 'create'" @click="loginVerify" class="ml-1 py-2 px-10 text-white bg-primario rounded-xl">Crear</button>
          <button v-if="this.viewLoader == 'loader' || this.viewLoader == 'created' || this.viewLoader == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button">
            <div v-if="this.viewLoader == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
              <p class="mr-3">Creando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader == 'created'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Creado</p>
            </div>
            <div v-if="this.viewLoader == 'error'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Error</p>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="mb-10 flex justify-between items-center">
      <div>
        <p class="text-texto text-3xl font-bold">Mis Clientes</p>
        <p class="text-texto">Crea tus clientes</p>
      </div>
      <div>
        <div>
          <button @click="$emit('cancelar')" class="mr-5 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button v-if="this.viewLoader2 == 'create'" @click="openModal" class="ml-1 py-2 px-10 text-white bg-primario rounded-xl">Crear</button>
          <button v-if="this.viewLoader2 == 'loader' || this.viewLoader2 == 'created' || this.viewLoader2 == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button2">
            <div v-if="this.viewLoader2 == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
              <p class="mr-3">Creando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader2 == 'created'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Creado</p>
            </div>
            <div v-if="this.viewLoader2 == 'error'" class="py-2 px-10 flex justify-center cursor-pointer">
              <p class="mr-3">Error</p>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="mb-10 w-full flex justify-between items-center">
      <div class="w-full ">
        <div class="p-5 w-full border border-gray-50 rounded-xl shadow-lg">
          <div class="pb-2 mb-5 border-b border-gray-300">
            <p class="text-texto font-bold">Informacion del cliente</p>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5 grid grid-cols-2">
              <div class="pr-5">
                <label for="first_name" class="text-texto">Primer nombre</label>
                <div class="px-3 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <!-- <i class="mr-2 flex items-center fi fi-rr-user"></i> -->
                  <input v-model="this.formData.first_name" type="text" autocomplete="off" placeholder="Ingresa tu primer nombre" name="first_name" id="first_name" class="w-full border-none">
                </div>
              </div>
              <div class="pl-5">
                <label for="second_name" class="text-texto">Segundo nombre</label>
                <div class="px-3 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <!-- <i class="mr-2 flex items-center fi fi-rr-user"></i> -->
                  <input v-model="this.formData.second_name" type="text" autocomplete="off" placeholder="Ingresa tu segundo nombre" name="second_name" id="second_name" class="w-full border-none">
                </div>
              </div>
            </div>
            <div class="pl-5 grid grid-cols-2">
              <div class="pr-5">
                <label for="first_last_name" class="text-texto">Primer apellido</label>
                <div class="px-3 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <!-- <i class="mr-2 flex items-center fi fi-rr-user"></i> -->
                  <input v-model="this.formData.first_last_name" type="text" autocomplete="off" placeholder="Ingresa tu primer apellido" name="first_last_name" id="first_last_name" class="w-full border-none">
                </div>
              </div>
              <div class="pl-5">
                <label for="second_last_name" class="text-texto">Segundo apellido</label>
                <div class="px-3 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <!-- <i class="mr-2 flex items-center fi fi-rr-user"></i> -->
                  <input v-model="this.formData.second_last_name" type="text" autocomplete="off" placeholder="Ingresa tu segundo apellido" name="second_last_name" id="second_last_name" class="w-full border-none">
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="alias" class="text-texto">Alias</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.alias" type="text" autocomplete="off" placeholder="Ingresa el alias del cliente" name="alias" id="alias" class="w-full border-none">
              </div>
            </div>
            <div class="pl-5">
              <label for="email" class="text-texto">Correo electronico</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-at"></i>
                <input v-model="this.formData.email" type="email" autocomplete="off" placeholder="Ingresa el correo electronico" name="email" id="email" class="w-full border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="mr-5 h-full grid" style="grid-template-row: auto 1fr;">
              <div class="mb-auto">
                <label for="type_identification" class="text-texto">Tip. documento</label>
                <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-id-badge"></i>
                  <select name="type_identification" id="type_identification" class="w-full text-texto border-none" v-model="this.formData.type_document">
                    <option selected disabled value="seleccionar">Seleccionar</option>
                    <option value="cc">CC</option>
                    <option value="ce">CE</option>
                    <option value="nit">NIT</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="pl-5">
              <div class="w-full">
                <label for="document" class="text-texto">No. documento</label>
                <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-hastag"></i>
                  <input v-model="this.formData.document" :type="type" placeholder="Ingrese documento sin digito de verificacion" name="document" autocomplete="off" class="w-full text-texto border-none">
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <div class="w-full">
                <label for="confirm_document" class="text-texto">Confirmar documento</label>
                <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-hastag"></i>
                  <input v-model="this.formData.confirm_document" :type="type" placeholder="Ingrese documento sin digito de verificacion" name="confirm_document" id="confirm_document" autocomplete="off" class="w-full text-texto border-none">
                </div>
              </div>
            </div>
            <div class="pl-5">
              <label for="contact_number" class="text-texto">Numero de contacto</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.contact_number" type="text" autocomplete="off" placeholder="Ingresa el numero del cliente" name="contact_number" id="contact_number" class="w-full border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="mr-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label class="">Departamento</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-building"></i>
                    <select @change="getCities" name="type_schedule" id="type_schedule" class="w-full border-none" v-model="this.formData.department_id">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option v-for="(department, index) in this.departments" :key="index" :value="department.id">{{department.name.toUpperCase()}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label class="">Ciudad</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-home"></i>
                    <select name="type_schedule" id="type_schedule" class="w-full border-none" v-model="this.formData.city_id">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option v-for="(city, index) in this.cities" :key="index" :value="city.id">{{city.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="date_of_birth" class="text-texto">Fecha de nacimiento</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.date_of_birth" type="date" name="date_of_birth" id="date_of_birth" class="w-full text-texto border-none">
              </div>
            </div>
            <div class="pl-5">
              <label for="gender" class="text-texto">Genero</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-venus-mars"></i>
                <select name="gender" id="gender" class="w-full text-texto border-none" v-model="this.formData.gender">
                  <option selected disabled value="seleccionar">Seleccionar</option>
                  <option value="masculino">Masculino</option>
                  <option value="femenino">Femenino</option>
                  <option value="juridico">Jurídico</option>
                  <option value="otro">otro</option>
                </select>
              </div>
            </div>
          </div>

          <div class="mb-5 w-full flex">
            <div class="w-full">
              <div class="w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv" role="alert">
                <svg aria-hidden="true" :class="this.classText" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div class="transition-all" :class="this.classText">
                  <span class="font-medium ">Alerta!</span>{{this.textAlert}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../supabase/init"
import emailjs from '@emailjs/browser';

export default {
  components: {
   
  },
  data() {
    return {
      formData:{
        first_name: "",
        second_name: "",
        first_last_name: "",
        second_last_name: "",
        email: "",
        alias: "",
        gender: "seleccionar",
        type_document: "seleccionar",
        document: '',
        confirm_document: '',
        contact_number: '',
        date_of_birth: '',
        agency_id: '',
        city_id: "seleccionar",
        department_id: "seleccionar",
        created_by: '',
      },

      sendNewClient: {
        client_name: '',
        client_document: '',
        email: '',
        agency_name: '',
        agency_number: '',
      },

      sendExistClient: {
        client_name: '',
        email: '',
        agency_name: '',
        agency_number: '',
      },

      currentUser:{},
      currentAgencyId:'',
      type: "text",
      departments:[],
      cities:[],
      customerExists: {},

      verifyAuth: false,
      verifyPassword: '',
      currentAuth:{},
      viewLoader: 'create',
      bg_button: 'bg-primario',
      viewLoader2: 'create',
      bg_button2: 'bg-primario',

      classDiv: "w-0 p-0",
      classText: "hidden",
      textAlert: "",

      classDiv2: "w-0 p-0",
      classText2: "hidden",
      textAlert2: ""
    }
  },

  async mounted() {
    await this.getUser()
    await this.getDepartments()
  },
  
  methods: {
    async getUser(){
      try{
        const { data, error } = await supabase.from('agencies').select('*').eq('id', this.userAuth.user.id)
        this.res1 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      }  

      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('id', this.userAuth.user.id)
        this.res2 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      } 

      if (this.res1) {
        this.currentUser = this.res1
      } else {
        this.currentUser = this.res2 
      }
    },

    async getDepartments(){
      try{
        const { data, error } = await supabase.from('departments').select('*').order('name', { ascending: true })
        this.departments = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getCities(){
      try{
        const { data, error } = await supabase.from('cities').select('*').eq('department_id', this.formData.department_id).order('name', { ascending: true })
        this.cities = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    /* async createClient(){
      this.currentAuth = this.userAuth.user
      this.viewLoader = 'loader'
      if (this.formData.fullname && this.formData.email && this.formData.alias && this.formData.gender != 'seleccionar' && this.formData.document && this.formData.confirm_document && this.formData.type_document != 'seleccionar' && this.formData.department_id != 'seleccionar' && this.formData.city_id != 'seleccionar' && this.formData.contact_number && this.formData.date_of_birth) {
        if (this.formData.document === this.formData.confirm_document) {
          try {
            const { user, session, error } = await supabase.auth.signUp({
              email: this.formData.email,
              password: this.formData.document,
            })

            console.log(user);
            console.log(session);

            if(error) throw error;
          } catch (error) {
            setTimeout(() => {
              this.viewLoader = 'error'
              this.bg_button = "bg-red-600"
              if (error.message == "User already registered") {
                this.classDiv = "w-72 p-4"
                this.classText = "block"
                this.textAlert = " Este correo ya se encuentra registrado"
      
                setTimeout(() => {
                  this.viewLoader = 'create'
                  this.bg_button = "bg-primario"
                  this.classDiv = "w-0 p-0"
                  this.classText = "hidden"
                }, 3000);
              }
            }, 1000);
          }
        } else {
          setTimeout(() => {
            this.viewLoader = "error"
            this.bg_button = "bg-red-600"
            this.classDiv = "w-72 p-4"
            this.classText = "block"
            this.textAlert = " El numero de documento no coincide"
            setTimeout(() => {
              this.viewLoader = "create"
              this.bg_button = "bg-primario"
              this.classDiv = "w-0 p-0"
              this.classText = "hidden"
            }, 3000);
          }, 1000);
        }
      } else {
        setTimeout(() => {
          this.viewLoader = "error"
          this.bg_button = "bg-red-600"
          this.classDiv = "w-72 p-4"
          this.classText = "block"
          this.textAlert = " No se admiten campos vacios."

          setTimeout(() => {
            this.viewLoader = "create"
            this.bg_button = "bg-primario"
            this.classDiv = "w-0 p-0"
            this.classText = "hidden"
          }, 3000);
        }, 1000);
      }
    }, */

    async openModal(){  
      this.formData.email = this.formData.email.toLowerCase()
      if (this.formData.first_name && this.formData.first_last_name && this.formData.email && this.formData.alias && this.formData.gender != 'seleccionar' && this.formData.document && this.formData.confirm_document && this.formData.type_document != 'seleccionar' && this.formData.department_id != 'seleccionar' && this.formData.city_id != 'seleccionar' && this.formData.contact_number && this.formData.date_of_birth) {
        if (this.formData.document === this.formData.confirm_document) {
          try {
            let { data, error } = await supabase.from('team').select('*').eq('email', `${this.formData.email}`)
            
            if (data[0]) {
              this.classDiv = "w-72 p-4"
              this.classText = "block"
              this.textAlert = " Este correo ya ha sido registrado."

              setTimeout(() => {
                this.classDiv = "w-0 p-0"
                this.classText = "hidden"
              }, 5000);
            }else{
              try {
                let { data, error} = await supabase.from('team_agencies').select('*').eq('email', `${this.formData.email}`)
    
                if (data[0]) {
                  this.classDiv = "w-72 p-4"
                  this.classText = "block"
                  this.textAlert = " Este correo ya ha sido registrado."

                  setTimeout(() => {
                    this.classDiv = "w-0 p-0"
                    this.classText = "hidden"
                  }, 5000);
                }else{
                  try {
                    let { data, error} = await supabase.from('agencies').select('*').eq('email', `${this.formData.email}`)
        
                    if (data[0]) {
                      this.classDiv = "w-72 p-4"
                      this.classText = "block"
                      this.textAlert = " Este correo ya ha sido registrado."

                      setTimeout(() => {
                        this.classDiv = "w-0 p-0"
                        this.classText = "hidden"
                      }, 5000);
                    }else{
                      try {
                        let { data, error} = await supabase.from('clients_agencies').select('*').eq('email', `${this.formData.email}`)
            
                        if (data[0]) {
                          this.classDiv = "w-72 p-4"
                          this.classText = "block"
                          this.textAlert = " Este correo ya ha sido registrado."

                          setTimeout(() => {
                            this.classDiv = "w-0 p-0"
                            this.classText = "hidden"
                          }, 5000);
                        }else{
                          try {
                            let { data, error} = await supabase.from('clients_helprime').select('*').eq('email', `${this.formData.email}`)
                            this.customerExists = data[0]
                            this.verifyAuth = true

                            if(error) throw error;
                          } catch (error) {
                            console.log(error.message);
                          }
                        }

                        if(error) throw error;
                      } catch (error) {
                        console.log(error.message);
                      }
                    }

                    if(error) throw error;
                  } catch (error) {
                    console.log(error.message);
                  }
                }

                if(error) throw error;
              } catch (error) {
                console.log(error.message);
              }
            }

            if(error) throw error;
          } catch (error) {
            console.log(error.message);
          }

        } else {
          this.classDiv = "w-72 p-4"
          this.classText = "block"
          this.textAlert = " El numero de documento no coincide"
          setTimeout(() => {
            this.classDiv = "w-0 p-0"
            this.classText = "hidden"
          }, 5000);
        }

      }else {
        this.classDiv = "w-72 p-4"
        this.classText = "block"
        this.textAlert = " No se admiten campos vacios."

        setTimeout(() => {
          this.classDiv = "w-0 p-0"
          this.classText = "hidden"
        }, 5000);
      }
    },

    async loginVerify(){
      this.currentAuth = this.userAuth.user
      this.viewLoader = 'loader'
      try {
        let { user, error } = await supabase.auth.signIn({
          email: this.currentAuth.email,
          password: this.verifyPassword
        })

        if (user) {
          if (this.customerExists) {
            if (this.res1) {
              this.currentAgencyId = this.currentUser.id
            } else {
              this.currentAgencyId = this.currentUser.agency_id
            }
            let clientId = this.customerExists.id

            try{
              let { data, error } = await supabase.from('clients_agencies').insert([{
                id: clientId,
                first_name: this.formData.first_name,
                second_name: this.formData.second_name,
                first_last_name: this.formData.first_last_name,
                second_last_name: this.formData.second_last_name,
                email: this.formData.email,
                alias: this.formData.alias,
                gender: this.formData.gender,
                type_document: this.formData.type_document,
                document: this.formData.document,
                contact_number: this.formData.contact_number,
                date_of_birth: this.formData.date_of_birth,
                agency_id: this.currentAgencyId,
                department_id: this.formData.department_id,
                city_id: this.formData.city_id,
                created_by: this.currentUser.id
              }])

              try{
                let { data, error } = await supabase.from('clients_helprime').update({
                  agency_id: this.currentAgencyId,
                }).eq('email', this.formData.email)

                if(error) throw error;
              } catch(error){
                console.log(error.message)
              }

              this.bg_button = 'bg-green-400'
              this.viewLoader = 'created'
              this.sendEmailClientExists()
              this.sendNewClient.to_name = ''
              this.sendNewClient.email = ''
              this.sendNewClient.agency_name = ''

              setTimeout(() => {
                this.$emit('create-client', data[0])
                this.bg_button = 'create'
                this.viewLoader = 'bg-primario'
              }, 3000);

              if(error) throw error;
            } catch(error){
              console.log(error.message)
            }
          } else {
            this.registeredAgency()
          }
        }

        if(error) throw error;
      } catch (error) {
        setTimeout(() => {
          this.viewLoader = 'create'
          if (error.message == "Invalid login credentials") {
            this.classDiv2 = "w-72 p-4"
            this.classText2 = "block"
            this.textAlert2 = " Correo o contraseña incorrectos."
  
            setTimeout(() => {
              this.classDiv2 = "w-0 p-0"
              this.classText2 = "hidden"
            }, 5000);
          }
        }, 2000);
      }
    },

    async registeredAgency(){
      try{
        let {user,session, error } = await supabase.auth.signUp({
          email: this.formData.email,
          password: this.formData.document
        })
        if(error) throw error;
        this.type = 'text'
        this.createAgency(user.id)
        

      } catch(error){
        console.log(error.message)
      }
    },

    async createAgency(id){
      if (this.res1) {
        this.currentAgencyId = this.currentUser.id
      } else {
        this.currentAgencyId = this.currentUser.agency_id
      }
      try{
        let { data, error } = await supabase.from('clients_agencies').insert([{
          id: id,
          first_name: this.formData.first_name,
          second_name: this.formData.second_name,
          first_last_name: this.formData.first_last_name,
          second_last_name: this.formData.second_last_name,
          email: this.formData.email,
          alias: this.formData.alias,
          gender: this.formData.gender,
          type_document: this.formData.type_document,
          document: this.formData.document,
          contact_number: this.formData.contact_number,
          date_of_birth: this.formData.date_of_birth,
          agency_id: this.currentAgencyId,
          department_id: this.formData.department_id,
          city_id: this.formData.city_id,
          created_by: this.currentUser.id
        }])

        try {
          let { user, error } = await supabase.auth.signIn({
            email: this.currentAuth.email,
            password: this.verifyPassword
          })

            this.bg_button = 'bg-green-400'
            this.viewLoader = 'created'
            this.sendEmailNewClient()
            this.sendNewClient.to_name = ''
            this.sendNewClient.email = ''
            this.sendNewClient.agency_name = ''

            setTimeout(() => {
              this.$emit('create-client', data[0])
              this.bg_button = 'create'
              this.viewLoader = 'bg-primario'
            }, 3000);

          if(error) throw error;
        } catch (error) {
          console.log(error.message);
        }

        if(error) throw error;
      } catch(error){
        console.log(error.message)
      }
    },

    async sendEmailNewClient() {
      if (this.res1) {
        this.sendNewClient.agency_name = this.currentUser.fullname
        this.sendNewClient.agency_number = this.currentUser.contact_number
      } else {
        try{
          const { data, error } = await supabase.from('agencies').select('*').eq('id', this.currentUser.agency_id)
          this.sendNewClient.agency_name = data[0].fullname
          this.sendNewClient.agency_number = data[0].contact_number
          if(error) throw error;
        } catch (e){
          console.log(e.message)
        } 
      }
      this.sendNewClient.client_name = this.formData.first_name
      this.sendNewClient.email = this.formData.email
      this.sendNewClient.client_document = this.formData.document
      emailjs.send('service_5njuyze', 'template_2f24bke', this.sendNewClient, 'pfz8wFvetYK3c-deO')/* .then((result) => {
          console.log('SUCCESS!', result.text);
        }, (error) => {
          console.log('FAILED...', error.text);
        }); */
    },

    async sendEmailClientExists() {
      if (this.res1) {
        this.sendExistClient.agency_name = this.currentUser.fullname
        this.sendExistClient.agency_number = this.currentUser.contact_number
      } else {
        try{
          const { data, error } = await supabase.from('agencies').select('*').eq('id', this.currentUser.agency_id)
          this.sendExistClient.agency_name = data[0].fullname
          this.sendExistClient.agency_number = data[0].contact_number
          if(error) throw error;
        } catch (e){
          console.log(e.message)
        } 
      }
      this.sendExistClient.client_name = this.formData.first_name
      this.sendExistClient.email = this.formData.email
      emailjs.send('service_5njuyze', 'template_glq4d19', this.sendExistClient, 'pfz8wFvetYK3c-deO')/*.then((result) => {
          console.log('SUCCESS!', result.text);
        }, (error) => {
          console.log('FAILED...', error.text);
        });*/
    }
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    }
  }
}

/* $('#confirm_document').bind("cut copy paste",function(e) {
  e.preventDefault();
}); */
</script>
<style>
  
</style>