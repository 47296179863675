<template>
  <div>
    <div>
      <div class="mb-10 flex flex-col justify-center items-center">
        <div class="w-full">
          <div class="w-full flex justify-center">
            <div class="mb-5 h-40 w-40 flex justify-center items-center rounded-full bg-white border-4 border-white shadow-lg">
              <i class="flex justify-center items-center text-5xl font-bold text-primario fi fi-rr-user"></i>
            </div>
          </div>
          <div class="mb-5 w-full text-center">
            <p class="text-texto font-bold text-2xl">{{this.currentClient.first_name}} {{this.currentClient.second_name}} {{this.currentClient.first_last_name}} {{this.currentClient.second_last_name}}</p>
            <p class=" leading-none text-primario">{{this.currentClient.alias}}</p>
          </div>
        </div>

        <riesgos v-if="this.sectionView == 'riesgos'" :currentClient="this.currentClient" @closeCategory="closeCategory" @changeSection="changeSection"/>
        <polizas v-else-if="this.sectionView == 'polizas'" :currentClient="this.currentClient" @closeCategory="closeCategory" @changeSection="changeSection"/>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../../supabase/init"
import Riesgos from './Riesgos.vue'
import Polizas from './Polizas.vue'
export default {
  components: { Riesgos, Polizas },
  props: {
    clientId: String,
    currentClient: Object,
    currentSection: Object,
  },
  data() {
    return {
      sectionView:'riesgos'
    }
  },
  
  async mounted() {
    if (this.currentSection) {
      this.sectionView = 'polizas'
      this.$store.commit("SavePolicyVencimiento", null)
    }
  },

  methods: {

    changeSection(section){
      this.sectionView = section
    },

    closeCategory(){
      this.$emit('closeCategory')
    },
  },
}
</script>
<style>
  
</style>