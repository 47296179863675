<template >
  <div>
    <div>
      <p class="mb-5 text-texto text-3xl font-bold">Polizas Totales</p>
    </div>
    <div class="mb-10 flex items-center justify-between">
      <div class="mb-5 flex">
        <div class="py-1 w-80 mr-5">
          <label for="filter" class="pl-2 text-texto">Filtrar por:</label>
          <div class="py-1 w-full flex items-center border border-gray-300 rounded-xl">
            <select name="filter" id="filter" class="w-full border-none" v-model="this.typeFilter">
              <option selected disabled value="seleccionar">Seleccionar</option>
              <option value="categoria">categoria</option>
              <option value="id_insured">Id Asegurado</option>
            </select>
          </div>
        </div>

        <!-- <div v-if="this.typeFilter == 'id_client'" class="flex items-center">
          <div>
            <label for="filter" class="pl-2 text-texto">Ingresa el no. documento del cliente</label>
            <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
              <input v-model="this.documentFilter" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
            </div>
            <p class="mt-1 text-red-500">{{this.alertFilter}}</p>
          </div>

          <div class="mt-6">
            <button  @click="filterClient" class="mr-2 py-2 px-2 text-primario border border-primario rounded-xl">
              <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
            </button>
          </div>
          <div class="mt-6">
            <button @click="removeFilter" v-if="this.filterStatus" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
              <p class="mr-2">Quitar filtro</p>
              <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
            </button>
          </div>
        </div> -->

        <div v-if="this.typeFilter == 'id_insured'" class="flex items-center">
          <div>
            <label for="filter" class="pl-2 text-texto">Ingresa el no. documento del asegurado</label>
            <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
              <input v-model="this.documentFilter" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
            </div>
            <p class="mt-1 text-red-500">{{this.alertFilter}}</p>
          </div>

          <div class="mt-6">
            <button  @click="filterClient2" class="mr-2 py-2 px-2 text-primario border border-primario rounded-xl">
              <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
            </button>
          </div>
          <div class="mt-6">
            <button @click="removeFilter" v-if="this.filterStatus2" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
              <p class="mr-2">Quitar filtro</p>
              <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
            </button>
          </div>
        </div>

        <div v-else-if="this.typeFilter == 'categoria'" class="flex items-center justify-between">
          <div class="flex items-center">
            <div>
              <div class="mt-1 py-1 w-80">
                <label for="filter2" class="pl-2 text-texto">Seleciona la categoria</label>
                <div class="py-1 w-full flex items-center border border-gray-300 rounded-xl">
                  <select name="filter2" id="filter2" class="w-full border-none" v-model="this.documentFilter3" @change="filterClient3">
                    <option selected disabled value="seleccionar">Seleccionar</option>
                    <option value="autos">Autos</option>
                    <option value="vida">vida</option>
                    <option value="hogar">Hogar</option>
                    <option value="salud">Salud</option>
                    <option value="generales">Generales</option>
                  </select>
                </div>
              </div>
              <p class="mt-1 text-red-500">{{this.alertFilter3}}</p>
            </div>
            <div class="mt-6">
              <button  @click="filterClient3" class="mx-2 py-2 px-2 text-primario border border-primario rounded-xl">
                <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
              </button>
            </div>
            <div class="mt-6">
              <button @click="removeFilter" v-if="this.filterStatus3" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
                <p class="mr-2">Quitar filtro</p>
                <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="mr-5">
          <button @click="$emit('changeSection', 'riesgos')" class="py-2 px-10 text-primario border border-primario bg-white rounded-xl">Riesgos</button>
        </div>
        <div class="mr-5">
          <button class="py-2 px-10 text-white bg-primario rounded-xl">Polizas</button>
        </div>
      </div>
    </div>
    <div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-left">
          <thead class="text-texto bg-gray-50 ">
            <tr>
              <th scope="col" class="pr-6 py-3 text-center font-semibold">No.</th>
              <th scope="col" class="pr-6 py-3 text-center font-semibold">Asegurado</th>
              <th scope="col" class="pr-6 py-3 text-center font-semibold">Id. Asegurado</th>
              <th scope="col" class="pr-6 py-3 text-center font-semibold">Producto</th>
              <th scope="col" class="pr-6 py-3 text-center font-semibold">Riesgo</th>
              <th scope="col" class="pr-6 py-3 text-center font-semibold">Categoria</th>
              <th scope="col" class="px-6 py-3 text-center font-semibold">Estado</th>
            </tr>
          </thead>
          <tbody v-if="this.policiesFilter.length">
            <tr v-for="(policy, index) in this.policiesFilter" @click="redirectSection(policy)" :key="index" class="bg-white text-texto border-b cursor-pointer">
              <td class="pr-6 py-4 text-center font-medium">{{index + 1}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{policy.insured_name}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{policy.insured_document}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{policy.product}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{policy.risks}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{policy.category}}</td>
              <td class="px-6 py-4 text-center">
                <div v-if="policy.remainingDays < 1" class="py-1 px-4 rounded-xl bg-red-600"><p class="text-white">Vencido</p></div>
                <div v-else-if="policy.remainingDays < 60 && policy.remainingDays >= 30" class="py-1 px-4 rounded-xl bg-yellow-100"><p class="text-yellow-400">1 a 2 meses</p></div>
                <div v-else-if="policy.remainingDays < 30" class="py-1 px-4 rounded-xl bg-red-100"><p class="text-red-600">-1 mes</p></div>
                <div v-else class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">+2 meses</p></div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(policy, index) in this.policies" :key="index" @click="redirectSection(policy)" class="bg-white text-texto border-b cursor-pointer">
              <td class="pr-6 py-4 text-center font-medium">{{index + 1}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{policy.insured_name}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{policy.insured_document}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{policy.product}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{policy.risks}}</td>
              <td class="pr-6 py-4 text-center font-medium">{{policy.category}}</td>
              <td class="px-6 py-4 text-center">
                <div v-if="policy.remainingDays < 1" class="py-1 px-4 rounded-xl bg-red-600"><p class="text-white">Vencido</p></div>
                <div v-else-if="policy.remainingDays < 60 && policy.remainingDays >= 30" class="py-1 px-4 rounded-xl bg-yellow-100"><p class="text-yellow-400">1 a 2 meses</p></div>
                <div v-else-if="policy.remainingDays < 30" class="py-1 px-4 rounded-xl bg-red-100"><p class="text-red-600">-1 mes</p></div>
                <div v-else class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">+2 meses</p></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../../supabase/init"
export default {
  props: {
    currentClient: Object
  },
  data() {
    return {
      filterStatus: false,
      policies:[],
      policiesFilter: [
        /* {
          license_plate_name: 'QWE123',
          product: 'SOAT',
          insurance_company: 'insurance_company',
          status: '+2 meses',
        } */
      ],
      typeFilter: 'seleccionar',

      documentFilter: '',
      filterStatus: false,
      alertFilter: '',

      documentFilter2: 'seleccionar',
      filterStatus2: false,
      alertFilter2: '',

      documentFilter3: 'seleccionar',
      filterStatus3: false,
      alertFilter3: '',
    }
  },

  async mounted() {
    setTimeout(async() => {
      await this.getPolicies()
    }, 500);
  },

  methods: {
    async getPolicies(){
      try{
        const { data, error } = await supabase.from('policies').select('*').match({client_id: this.currentClient.id }).order('created_at', { ascending: true })
        data.forEach(async (policy) => {
          const now = new Date();
          const competition = new Date(policy.expiration);
          this.time = competition - now;
          const miliseconds = this.time;
          const seconds = miliseconds/1000;
          const minutes = seconds/60;
          const days = minutes/1440
          const result = Math.round(days)
          policy.remainingDays = result

          this.policies.unshift(policy)
        });

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    toUpperCase() {
        this.documentFilter = this.documentFilter.toUpperCase();
    },

    /* async filterClient(){
      this.policiesFilter = this.policies.filter(policy => policy.document == this.documentFilter)

      if (this.policiesFilter.length) {
        this.filterStatus = true

      } else {
        let currenData = this.policies
        this.policies = []
        this.alertFilter = 'No se encontraron resultados'

        setTimeout(() => {
          this.policies = currenData
          this.alertFilter = ''
          this.removeFilter()
        }, 2000);
      }
    }, */

    async filterClient2(){
      this.policiesFilter = this.policies.filter(policy => policy.insured_document == this.documentFilter)

      if (this.policiesFilter.length) {
        this.filterStatus2 = true

      } else {
        let currenData = this.policies
        this.policies = []
        this.alertFilter2 = 'No se encontraron resultados'

        setTimeout(() => {
          this.policies = currenData
          this.alertFilter2 = ''
          this.removeFilter()
        }, 2000);
      }
    },


    filterClient3(){
      this.policiesFilter = this.policies.filter(policy => policy.category == this.documentFilter3)

      if (this.policiesFilter.length) {
        this.filterStatus3 = true
      } else {
        let currenData = this.policies
        this.policies = []
        this.alertFilter3 = 'No se encontraron resultados'

        setTimeout(() => {
          this.policies = currenData
          this.alertFilter3 = ''
          this.removeFilter()
        }, 2000);
      }
    },

    removeFilter(){
      this.policiesFilter = []
      this.documentFilter = ''
      this.documentFilter2 = ''
      this.documentFilter3 = 'seleccionar'
      this.filterStatus = false
      this.filterStatus2 = false
      this.filterStatus3 = false
    },

    redirectSection(policy){
      this.$emit("redirectSection", policy.category.toLowerCase())
    },
  },

}
</script>
<style >
  
</style>