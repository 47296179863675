<template>
  <div>
    <div class="mb-10 flex justify-between items-center">
      <div>
        <p class="text-texto text-3xl font-bold">Equipo</p>
        <p class="text-texto">Actualiza los datos del equipo</p>
      </div>
      <div>
        <div>
          <button @click="$emit('cancelar')" class="mr-5 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button @click="updateData" class="py-2 px-10 text-white bg-primario rounded-xl">Actualizar</button>
        </div>
      </div>
    </div>
    <div class="mb-10 text-texto">
      <div class="pr-5">
        <div class="p-5 w-full border border-gray-50 rounded-xl shadow-lg">
          <div class="pb-2 mb-5 border-b border-gray-300">
            <p class="font-bold">Informacion del administrador</p>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="fullname" class="">Nombres</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-user"></i>
                <input v-model="this.formData.fullname" type="text" placeholder="Ingresa los nombres" name="fullname" id="fullname" class="w-full border-none">
              </div>
            </div>
            <div class="pl-5">
              <label for="email" class="">Correo electronico</label>
              <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-at"></i>
                <input v-model="this.formData.email" type="email" disabled placeholder="Ingresa el correo electronico" name="email" id="email" class="w-full border-none bg-gray-100">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <label for="whatsapp" class="">Numero de whatsapp</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-comment"></i>
                <input v-model="this.formData.whatsapp" type="number" placeholder="Ingresa numero de whatsapp" name="whatsapp" id="whatsapp" class="w-full border-none">
              </div>
            </div>
            <div class="pl-5">
              <label for="contact_number" class="">Numero de contacto</label>
              <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                <i class="mr-2 flex items-center fi fi-rr-phone-call"></i>
                <input v-model="this.formData.contact_number" type="number" placeholder="Ingresa numero de contacto" name="contact_number" id="contact_number" class="w-full border-none">
              </div>
            </div>
          </div>

          <div class="mb-5 grid grid-cols-2">
            <div class="mr-5 flex">
              <div class="mr-5 h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="type_identification" class="">Tip. documento</label>
                  <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-id-badge"></i>
                    <select name="type_identification" disabled id="type_identification" class="w-full border-none bg-gray-100" v-model="this.formData.type_document">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option value="cc">CC</option>
                      <option value="ce">CE</option>
                      <option value="nit">NIT</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <label for="identification" class="">No. documento</label>
                <div class="px-5 py-1 w-full flex items-center bg-gray-100 border border-gray-300 rounded-xl">
                  <i class="mr-2 flex items-center fi fi-rr-hastag"></i>
                  <input v-model="this.formData.document" type="number" disabled placeholder="Ingresa documento" name="identification" id="identification" class="w-full border-none bg-gray-100">
                  <input v-model="this.password" type="password" disabled placeholder="Ingresa documento" name="identification" id="identification" class="w-full hidden border-none bg-gray-100">
                </div>
              </div>
            </div>
            <div class="pl-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="rol" class="">Rol</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                    <select name="rol" id="rol" class="w-full border-none" v-model="this.formData.rol">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option value="admin">Administracion</option>
                      <option value="advisor">Asesor</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-5 grid grid-cols-2">
            <div class="pr-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label for="status" class="">Estado</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-portrait"></i>
                    <select name="status" id="status" class="w-full border-none" v-model="this.formData.status">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option :value="true">Activo</option>
                      <option :value="false">Inactivo</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../supabase/init"

export default {
  props: {
    userId: String,
  },
  components:{
  },
  data() {
    return {
      formData:{
        fullname: "",
        email: "",
        whatsapp: "",
        contact_number: "",
        type_document: "seleccionar",
        document: '',
        rol: "seleccionar",
        status: null,
      },
      currentUser:{},
      res1:{},
      res2:{},
      password: '',

      currentProveedor: {},
      proveedorStatus: 'seleccionar',
    }
  },

  async mounted() {
    await this.getUser()
    await this.getUserTeam()
  },

  methods: {
    async getUser(){
      try{
        const { data, error } = await supabase.from('agencies').select('*').eq('id', this.userAuth.user.id)
        this.res1 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      }  

      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('id', this.userAuth.user.id)
        this.res2 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      } 

      if (this.res1) {
        this.currentUser = this.res1
      } else {
        this.currentUser = this.res2 
      }
    },

    async getUserTeam(){
      if (this.res1) {
        try{
          const { data, error } = await supabase.from('team_agencies').select('*').match({id: this.userId, agency_id: this.currentUser.id})
          this.currentProveedor = data[0]

          this.formData.fullname = this.currentProveedor.fullname
          this.formData.email = this.currentProveedor.email
          this.formData.whatsapp = this.currentProveedor.whatsapp
          this.formData.contact_number = this.currentProveedor.contact_number
          this.formData.type_document = this.currentProveedor.type_document
          this.formData.document = this.currentProveedor.document
          this.formData.rol = this.currentProveedor.rol
          this.formData.status = this.currentProveedor.status

          if(error) throw error;
        } catch (error){
          console.log(error.message)
        }
      } else {
        console.log(this.currentUser);
        try{
          const { data, error } = await supabase.from('team_agencies').select('*').match({id: this.userId, agency_id: this.currentUser.agency_id})
          this.currentProveedor = data[0]

          this.formData.fullname = this.currentProveedor.fullname
          this.formData.email = this.currentProveedor.email
          this.formData.whatsapp = this.currentProveedor.whatsapp
          this.formData.contact_number = this.currentProveedor.contact_number
          this.formData.type_document = this.currentProveedor.type_document
          this.formData.document = this.currentProveedor.document
          this.formData.rol = this.currentProveedor.rol
          this.formData.status = this.currentProveedor.status

          if(error) throw error;
        } catch (error){
          console.log(error.message)
        }
      }
      
    },

    async updateData(){
      try{
        let { data, error } = await supabase.from('team_agencies').update({
          fullname: this.formData.fullname,
          email: this.formData.email,
          whatsapp: this.formData.whatsapp,
          contact_number: this.formData.contact_number,
          type_document: this.formData.type_document,
          document: this.formData.document,
          rol: this.formData.rol,
          status: this.formData.status,
        }).eq('id', this.userId)
        this.$emit('update-team')

        if(error) throw error;
      } catch(error){
        console.log(error.message)
      }
    },
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    }
  }
}
</script>
<style>
  
</style>