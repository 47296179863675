<template>
  <div class="p-10 h-screen overflow-y-auto relative">
    <div v-if="this.modalReset" class="w-full h-screen flex justify-center items-center bg-black bg-opacity-20 left-0 top-0 rounded-lg absolute z-10">
      <div class="p-8 w-96 bg-white rounded-lg">
        <p class="mb-3 text-lg text-primario text-center font-bold">Cambiar contraseña</p>
        <p class="mb-5 text-texto text-center">Se te enviara un link de confirmacion a tu correo electronico para realizar el cambio de contraseña.</p>
        <div class="mb-5 w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv" role="alert">
          <svg aria-hidden="true" :class="this.classText" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <div class="transition-all" :class="this.classText">
            <span class="font-medium">Alerta!</span>{{this.textAlert}}
          </div>
        </div>
        <div class="grid grid-cols-2">
          <button @click="closeModalReset" class="mr-1 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button v-if="this.viewLoader2 == 'reset'" @click="sendLink" class="ml-1 px-2 py-2 w-full text-white rounded-lg" :class="this.bg_button">Enviar</button>
          <button v-if="this.viewLoader2 == 'loader' || this.viewLoader2 == 'send' || this.viewLoader2 == 'error'" class="ml-1 w-full text-white rounded-xl" :class="this.bg_button2">
            <div v-if="this.viewLoader2 == 'loader'" class="px-2 py-2 w-full flex justify-center cursor-wait">
              <p class="mr-3">enviando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader2 == 'error'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
              <p class="mr-3">Error</p>
            </div>
            <div v-if="this.viewLoader2 == 'send'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
              <p class="mr-3">Enviado</p>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div v-if="this.loaderBefore" class="h-full w-full flex flex-col justify-center items-center bg-white absolute z-10">
      <i class="fi fi-rr-spinner flex justify-center items-center text-6xl text-primario animate-spin"></i>
      <p class="w-full max-w-xs text-center mt-5 animate-bounce text-xl">Estamos cargando esta linda familia para ti</p>
    </div>

    <div v-else-if="this.currentUser.rol == 'Agency' || this.currentUser.rol == 'admin' || this.currentUser.rol == 'advisor'">
      <div class="w-full h-64 mb-5 bg-primario bg-opacity-40 rounded-lg"></div>
      <div class="flex justify-between items-start mt-10">
        <div class="flex">
          <div class="ml-12 flex flex-col items-center">
            <label for="uploadProfile" class=" w-40 h-40 rounded-full border-4 border-white shadow-lg relative -top-14">
              <div v-if="this.src" class="w-full h-full flex justify-center items-center group cursor-pointer relative transition-all hover:opacity-80">
                <img :src="this.src" alt="profile" class="w-40 h-40 rounded-full object-cover">
                <div class="w-full h-full flex justify-center items-center absolute">
                  <div class="p-2 flex justify-center items-center bg-gray-50 rounded-full transition-all opacity-0 group-hover:opacity-80">
                    <i class="flex items-center text-xl fi fi-rr-cross "></i>
                  </div>
                </div>
              </div>
              <div v-else class="w-full h-full flex justify-center">
                <div class="w-full h-full flex flex-col items-center justify-center bg-gray-50 rounded-full cursor-pointer hover:bg-gray-100">
                  <i class="flex justify-center items-center text-5xl font-bold text-primario fi fi-rr-user"></i>
                  <div class="mt-2 flex items-center">
                    <!-- <i class="mr-2 flex items-center text-primario text-lg fi fi-rr-upload"></i> -->
                    <p class="text-xs text-center text-primario">PNG o JPG<br>(MAX. 1024 kB)</p>
                  </div>
                </div>
              </div>
            </label>
            <input @change="uploadProfile" type="file" id="uploadProfile" class="hidden">
          </div>
          <div class="ml-5">
            <p class="mb-1 text-texto font-bold text-2xl">{{this.currentUser.fullname}}</p>
            <p class=" leading-none text-primario">{{this.currentUser.rol}}</p>
          </div>
        </div>
        <div class="flex h-full">
          <button v-if="this.viewLoader == 'update'" @click="updateInfo" class="ml-1 py-2 px-10 text-white rounded-xl" :class="this.bg_button">Actualizar</button>
          <button v-if="this.viewLoader == 'loader' || this.viewLoader == 'updated' || this.viewLoader == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button">
            <div v-if="this.viewLoader == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
              <p class="mr-3">Actualizando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader == 'updated'" class="px-10 py-2 w-full flex justify-center cursor-pointer">
              <p class="">Actualizado</p>
            </div>
            <div v-if="this.viewLoader == 'error'" class="px-10 py-2 w-full flex justify-center cursor-pointer">
              <p class="">error</p>
            </div>
          </button>
        </div>
      </div>
      <div class="flex flex-col w-4/5 mx-auto">
        <div class="mb-10 grid grid-cols-2 w-full">
          <div class="flex flex-col w-full pr-3">
            <label for="fullname" class="text-texto font-light text-sm">Nombres completos</label>
            <input v-model="this.formData.fullname" type="text" id="fullname" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto"/>
          </div>
          <div class="flex flex-col w-full pl-3">
            <label for="email" class="text-texto font-light text-sm">Correo electronico</label>
            <input v-model="this.formData.email" type="text" id="email" disabled class="bg-gray-100 border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto rounded-t-lg"/>
          </div>
        </div>
        <div class="mb-10 grid grid-cols-2 w-full">
          <div class="flex flex-col w-full pr-3">
            <label for="type_document" class="text-texto font-light text-sm">Tipo de documento</label>
            <input v-model="this.formData.type_document" type="text" id="type_document" disabled class="bg-gray-100 border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto rounded-t-lg"/>
          </div>
          <div class="flex flex-col w-full pl-3">
            <label for="document" class="text-texto font-light text-sm">No. de documento</label>
            <input v-model="this.formData.document" type="text" id="document" disabled class="bg-gray-100 border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto rounded-t-lg"/>
          </div>
        </div>
        <div class="mb-10 grid grid-cols-2 w-full">
          <div class="flex flex-col w-full pr-3">
            <label for="whatsapp" class="text-texto font-light text-sm">Whatsapp</label>
            <input v-model="this.formData.whatsapp" type="text" id="whatsapp" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto"/>
          </div>
          <div class="flex flex-col w-full pl-3">
            <label for="contact_number" class="text-texto font-light text-sm">No. de contacto</label>
            <input v-model="this.formData.contact_number" type="text" id="contact_number" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto"/>
          </div>
        </div>
        <div v-if="this.res1" class="mb-10 grid grid-cols-2 w-full">
          <div class="flex flex-col w-full pr-3">
            <label for="department" class="text-texto font-light text-sm">Departamento</label>
            <select @change="getCities" name="department" id="department" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto" v-model="this.formData.department_id">
              <option selected disabled value="seleccionar">Seleccionar</option>
              <option v-for="(department, index) in this.departments" :key="index" :value="department.id">{{department.name.toUpperCase()}}</option>
            </select>
          </div>
          <div class="flex flex-col w-full pl-3">
            <label for="city" class="text-texto font-light text-sm">No. de contacto</label>
            <select name="city" id="city" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto" v-model="this.formData.city_id">
              <option selected disabled value="seleccionar">Seleccionar</option>
              <option v-for="(city, index) in this.cities" :key="index" :value="city.id">{{city.name}}</option>
            </select>
          </div>
        </div>
        <div v-if="this.res1" class="mb-10 w-full flex">
          <div class="flex flex-col w-full">
            <label for="web" class="text-texto font-light text-sm">Url web</label>
            <input v-model="this.formData.web" type="text" id="web" class="border-b border-t-0 border-r-0 border-l-0 border-gray-400 w-full text-texto"/>
          </div>
        </div>
        <div v-if="this.res1" class="w-full flex">
          <div class="flex flex-col w-full">
            <label for="description" class="text-texto font-light text-sm">Descripcion</label>
            <textarea v-model="this.formData.description" name="description" id="description"  rows="3" placeholder="Ingresa aqui la descripcion de tu negocio para que la gente sepa que realizas" class="w-full text-sm text-texto border border-gray-400 rounded-xl"></textarea>
          </div>
        </div>
        <p class="mb-5 text-gris font-light my-5">Deseas actualizar tu contraseña?,<span @click="this.modalReset = true" class="underline text-primario font-medium cursor-pointer"> da click aqui</span></p>
        <div class="mb-5 w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv" role="alert">
          <svg aria-hidden="true" :class="this.classText" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <div class="transition-all" :class="this.classText">
            <span class="font-medium">Alerta!</span>{{this.textAlert}}
          </div>
        </div>
      </div>
    </div>

    <div v-else class="h-full flex justify-center items-center">
      <div class="flex items-center">
        <img class="mr-5 w-20" src="../../../assets/logo.png" alt="">
        <p class="text-5xl text-center text-texto font-bold">LO SENTIMOS <br> <span class="text-2xl">No tienes acceso a esta seccion</span> </p>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../supabase/init"
import router from '@/router'

export default {
  data() {
    return {
      viewLoader: 'update',
      bg_button: 'bg-primario',
      classDiv: "w-0 p-0",
      classText: "hidden",
      textAlert: "",

      viewLoader2: 'reset',
      bg_button2: 'bg-primario',
      classDiv2: "w-0 p-0",
      classText2: "hidden",
      textAlert2: "",

      modalReset : false,

      currentUser: {},

      formData:{
        fullname: "",
        email: "",
        type_document: "",
        document: "",
        whatsapp: "",
        contact_number: "",
        web: "",
        department_id: "",
        city_id: "",
      },

      generalEvent: {},
      imgProfile: {},
      src: "",

      loaderBefore: true,
    }
  },

  async mounted() {
    await this.getUser()
    if (this.currentUser.rol == 'Agency') {
      await this.downloadImage()
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    }else {
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    }
    await this.getDepartments()
    await this.getCities()

  },

  methods: {
    async getUser(){
      try{
        const { data, error } = await supabase.from('agencies').select('*').eq('id', this.userAuth.user.id)
        this.res1 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      }  

      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('id', this.userAuth.user.id)
        this.res2 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      } 

      if (this.res1) {
        this.currentUser = this.res1
        this.formData.web = this.currentUser.web
        this.formData.description = this.currentUser.description
      } else {
        this.currentUser = this.res2 
      }
      this.formData.fullname = this.currentUser.fullname
      this.formData.email = this.currentUser.email
      this.formData.type_document = this.currentUser.type_document
      this.formData.document = this.currentUser.document
      this.formData.whatsapp = this.currentUser.whatsapp
      this.formData.contact_number = this.currentUser.contact_number
      this.formData.department_id = this.currentUser.department_id
      this.formData.city_id = this.currentUser.city_id
    },

    async getDepartments(){
      try{
        const { data, error } = await supabase.from('departments').select('*').order('name', { ascending: true })
        this.departments = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getCities(){
      try{
        const { data, error } = await supabase.from('cities').select('*').eq('department_id', this.formData.department_id).order('name', { ascending: true })
        this.cities = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async updateInfo(){
      this.viewLoader = 'loader'

      if (this.formData.fullname != '' && this.formData.whatsapp != '' && this.formData.contact_number != ''){
        if (this.res1) {
          try{
            let { data, error } = await supabase.from('agencies').update({
              fullname: this.formData.fullname,
              whatsapp: this.formData.whatsapp,
              contact_number: this.formData.contact_number,
              web: this.formData.web,
              description: this.formData.description,
              department_id: this.formData.department_id,
              city_id: this.formData.city_id,
            }).eq('id', this.currentUser.id)


            setTimeout(() => {
              this.viewLoader = 'updated'
              this.bg_button = 'bg-green-400'
      
              setTimeout(() => {
                this.viewLoader = 'update'
                this.bg_button = 'bg-primario'
                this.classDiv = "w-0 p-0"
                this.classText = "hidden"
                router.push("/login")
              }, 3000);
            }, 1000);

            if(error) throw error;
          } catch(error){
            console.log(error.message)
          }
        } else {
          try{
            let { data, error } = await supabase.from('team_agencies').update({
              fullname: this.formData.fullname,
              whatsapp: this.formData.whatsapp,
              contact_number: this.formData.contact_number,
              department_id: this.formData.department_id,
              city_id: this.formData.city_id,
            }).eq('id', this.currentUser.id)


            setTimeout(() => {
              this.viewLoader = 'updated'
              this.bg_button = 'bg-green-400'
      
              setTimeout(() => {
                this.viewLoader = 'update'
                this.bg_button = 'bg-primario'
                this.classDiv = "w-0 p-0"
                this.classText = "hidden"
                router.push("/login")
              }, 3000);
            }, 1000);

            if(error) throw error;
          } catch(error){
            console.log(error.message)
          }
        }
      } else {
        setTimeout(() => {
          this.viewLoader = 'error'
          this.bg_button = 'bg-red-400'
          this.classDiv = "w-72 p-4"
          this.classText = "block"
          this.textAlert = " No se admiten campos vacios."
  
          setTimeout(() => {
            this.viewLoader = 'update'
            this.bg_button = 'bg-primario'
            this.classDiv = "w-0 p-0"
            this.classText = "hidden"
          }, 3000);
        }, 1000);
      }
      
    },

    async sendLink(){
      this.viewLoader2 = 'loader'
      try {
        const { data, error } = await supabase.auth.api.resetPasswordForEmail(this.formData.email)
        setTimeout(() => {
          this.viewLoader2 = 'send'
          this.bg_button2 = 'bg-green-400'
          setTimeout(() => {
            this.viewLoader2 = 'reset'
            this.bg_button2 = 'bg-primario'
            this.modalReset = false
            
          }, 3000);
        }, 1000);
        
        if(error) throw error;
      } catch (error) {
        console.log(error.message);
      }
    },

    async uploadProfile(event){
      this.generalEvent = event
      let file = event.target.files[0]
      if (file) {
        try {
          const { data, error } = await supabase.storage
          .from('agencies')
          .upload(`${this.userAuth.user.id}/profile/imgprofile`, file, {
            cacheControl: '3600',
            upsert: false,
            contentType: file.type
          })
          if (error == null) {
            this.downloadImage()
            this.updateStatusApp()
          }
          if(error) throw error;
        } catch (error) {
          if (error) {
            this.delecteProfile()
          }
        }
      } 
    },

    async updateStatusApp (){
      try{
        let { data, error } = await supabase.from('agencies').update({
          app_status: true,
        }).eq('id', this.currentUser.id)
        if(error) throw error;
      } catch(error){
        console.log(error.message)
      }
    },

    async downloadImage(){
      try {
        const { signedURL, error } = await supabase.storage.from('agencies').createSignedUrl(`${this.userAuth.user.id}/profile/imgprofile`, 60)
        this.imgProfile = {
          name: "imgprofile",
          url : signedURL
        }
        this.src = this.imgProfile.url
        if(error) throw error;
      } catch (error) {
        console.log(error.message)
      }
    },

    async delecteProfile(){
      await supabase.storage.from('agencies').remove([`${this.userAuth.user.id}/profile/imgprofile`])
      this.src = ''
      this.uploadProfile(this.generalEvent)
    },

    closeModalReset(){
      this.modalReset = false
    }
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    },
  }
};
</script>
<style>
</style>