<template>
  <div class="w-full h-full relative">
    <actualizar-placa v-if="this.sectionStatus == 'actualizar'" :currentClient="this.currentClient" :placaId="this.placaView" @redirect-policies="redirectPolicies" @update-placa="updatePlaca" @cancelar="cancelar"/>

    <div class="flex justify-center">
      <div class="mr-5">
        <button @click="$emit('closeVehicles')" class="py-2 px-10 text-primario border border-primario rounded-xl">Atras</button>
      </div>
      <div class="mr-5">
        <button class="py-2 px-10 text-white bg-primario rounded-xl">Riesgos</button>
      </div>
      <div class="mr-5">
        <button @click="$emit('changeSection', 'polizas')" class="py-2 px-10 text-primario border border-primario bg-white rounded-xl">Polizas</button>
      </div>
    </div>

    <div >
      <div ref="scrollContainer" class="px-10 pt-10 pb-32 w-full h-screen" style="overflow-y:scroll;">
        <div v-if="this.sectionStatus == 'agregar' || this.sectionStatus == 'actualizar'" class="mb-10 flex justify-between items-center">
          <div>
            <p class="text-texto text-3xl font-bold">Riesgos del cliente</p>
            <p class="text-texto text-lg font-bold">Categoria: Autos</p>
            <!-- <p class="text-texto">Clientes registrados</p> -->
          </div>
          <div>
            <div>
              <button @click="openSectionCrear" class="py-2 px-10 text-white bg-primario rounded-xl">Agregar riesgo</button>
            </div>
          </div>
        </div>

        <agregar-placa v-if="this.sectionStatus == 'crear'" :currentClient="this.currentClient" @create-placa="createPlaca" @cancelar="cancelar"/>

        <div class="mb-5 flex">
          <div class="py-1 w-80 mr-5">
            <label for="filter" class="pl-2 text-texto">Filtrar por:</label>
            <div class="py-1 w-full flex items-center border border-gray-300 rounded-xl">
              <select name="filter" id="filter" class="w-full border-none" @change="changeFilter" v-model="this.typeFilter">
                <option selected disabled value="seleccionar">Seleccionar</option>
                <option value="placa">Placa</option>
                <option value="id_insured">Id Asegurado</option>
              </select>
            </div>
          </div>

          <div v-if="this.typeFilter == 'placa'" class="flex items-center">
            <div>
              <label for="filter" class="pl-2 text-texto">Ingresa el no. placa</label>
              <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
                <input v-model="this.documentFilter" @keyup="toUpperCase" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
              </div>
              <p class="mt-1 text-red-500">{{this.alertFilter}}</p>
            </div>
            <div class="mt-6">
              <button  @click="filterPlaca" class="mr-2 py-2 px-2 text-primario border border-primario rounded-xl">
                <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
              </button>
            </div>
            <div class="mt-6">
              <button @click="removeFilter" v-if="this.filterStatus" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
                <p class="mr-2">Quitar filtro</p>
                <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
              </button>
            </div>
          </div>

          <div v-if="this.typeFilter == 'id_insured'" class="flex items-center">
            <div>
              <label for="filter" class="pl-2 text-texto">Ingresa el no. documento del asegurado</label>
              <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
                <input v-model="this.documentFilter2" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
              </div>
              <p class="mt-1 text-red-500">{{this.alertFilter2}}</p>
            </div>

            <div class="mt-6">
              <button  @click="filterPlaca2" class="mr-2 py-2 px-2 text-primario border border-primario rounded-xl">
                <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
              </button>
            </div>
            <div class="mt-6">
              <button @click="removeFilter" v-if="this.filterStatus2" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
                <p class="mr-2">Quitar filtro</p>
                <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
              </button>
            </div>
          </div>
        </div>
        
        <div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-left">
              <thead class="text-texto bg-gray-50 ">
                <tr>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">No.</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Asegurado</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Documento</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Placa</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Marca</th>
                  <th scope="col" class="px-6 py-3 text-center font-semibold">Modelo</th>
                  <th scope="col" class="px-6 py-3 text-center font-semibold">Tipo</th>
                  <th scope="col" class="px-6 py-3 text-center font-semibold">Estado</th>
                </tr>
              </thead>
              <tbody v-if="this.placasFilter.length">
                <tr v-for="(placa, index) in this.placasFilter" :key="index" class="bg-white text-texto border-b cursor-pointer">
                  <td @click="openSectionUpdate(placa.id)" class="pr-6 py-4 text-center font-medium">{{index + 1}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="pr-6 py-4 text-center font-medium" >{{placa.insured_name}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="pr-6 py-4 text-center font-medium" >{{placa.insured_document}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="pr-6 py-4 text-center font-medium">{{placa.license_plate}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="pr-6 py-4 text-center font-medium">{{placa.brand}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="px-6 py-4 text-center">{{placa.model}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="px-6 py-4 text-center">{{placa.type_vehicle}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="px-6 py-4 text-center">
                    <div v-if="placa.status" class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">Activo</p></div>
                    <div v-else class="py-1 px-4 rounded-xl bg-gray-100"><p class="text-gray-600">Inactivo</p></div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(placa, index) in this.placas" :key="index" class="bg-white text-texto border-b cursor-pointer">
                  <td @click="openSectionUpdate(placa.id)" class="pr-6 py-4 text-center font-medium">{{index + 1}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="pr-6 py-4 text-center font-medium" >{{placa.insured_name}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="pr-6 py-4 text-center font-medium" >{{placa.insured_document}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="pr-6 py-4 text-center font-medium">{{placa.license_plate}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="pr-6 py-4 text-center font-medium">{{placa.brand}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="px-6 py-4 text-center">{{placa.model}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="px-6 py-4 text-center">{{placa.type_vehicle}}</td>
                  <td @click="openSectionUpdate(placa.id)" class="px-6 py-4 text-center">
                    <div v-if="placa.status" class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">Activo</p></div>
                    <div v-else class="py-1 px-4 rounded-xl bg-gray-100"><p class="text-gray-600">Inactivo</p></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../../supabase/init"
import AgregarPlaca from '@/components/Autos/Placas/AgregarPlaca.vue'
import ActualizarPlaca from '@/components/Autos/Placas/ActualizarPlaca.vue'
/* import ClienteView from './ClienteView.vue' */
export default {
  components: {AgregarPlaca, ActualizarPlaca },
  props: {
    currentClient: Object
  },
  data() {
    return {
      loaderBefore: true,
      filterStatus: false,
      viewClient: false,
      currentUser:{},
      sectionStatus: "agregar",
      placas:[],
      placasFilter:[],
      placaView: '',
      proveedorStatus: 'estado',

      typeFilter: 'seleccionar',

      documentFilter: '',
      filterStatus: false,
      alertFilter: '',

      documentFilter2: '',
      filterStatus2: false,
      alertFilter2: '',

      res1: {},
      res2: {},
    }
  },

  async mounted() {
    await this.getPlacas()
  },

  methods: {
    async getPlacas(){
      try{
        const { data, error } = await supabase.from('license_plates_agencies').select('*').eq('client_id', this.currentClient.id).order('created_at', { ascending: true })
        this.placas = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    openSectionCrear(){
      this.sectionStatus = "crear"
    },

    openSectionUpdate(id){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      const scrollContainer = this.$refs.scrollContainer;
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
      if (this.placaView != "") {
        this.sectionStatus = "agregar"
        this.placaView = ""
        setTimeout(() => {
          this.placaView = id
          this.sectionStatus = "actualizar"
        }, 100);
      }else{ 
        this.placaView = id
        this.sectionStatus = "actualizar"
      }
    },

    createPlaca(data){
      this.placas.unshift(data)
      this.sectionStatus = "agregar"
    },

    updatePlaca(){
      this.getPlacas()
      this.removeFilter()
      this.typeFilter = 'seleccionar'
      this.clientView = ""
      this.sectionStatus = "agregar"
    },

    cancelar(){
      this.sectionStatus = "agregar"
      this.placas = []
      this.getPlacas()
    },
    
    redirectPolicies(){
      this.sectionStatus = "agregar"
      this.$emit('changeSection', 'polizas')
    },

    closeClient(){
      this.loaderBefore = true
      this.viewClient = false
      this.clientId = ''
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    },

    toUpperCase() {
        this.documentFilter = this.documentFilter.toUpperCase();
    },

    filterPlaca(){
      this.placasFilter = this.placas.filter(placa => placa.license_plate == this.documentFilter)

      if (this.placasFilter.length) {
        this.filterStatus = true
      } else {
        let currentData = this.placas
        this.placas = []
        this.alertFilter = 'No se encontraron resultados'

        setTimeout(() => {
          this.alertFilter = ''
          this.placas = currentData
        }, 2000);
      }
    },

    async filterPlaca2(){
      this.placasFilter = this.placas.filter(placa => placa.insured_document == this.documentFilter2)

      if (this.placasFilter.length) {
        this.filterStatus2 = true

      } else {
        let currenData = this.placas
        this.placas = []
        this.alertFilter2 = 'No se encontraron resultados'

        setTimeout(() => {
          this.placas = currenData
          this.alertFilter2 = ''
          this.removeFilter()
        }, 2000);
      }
    },

    removeFilter(){
      this.placasFilter = []
      this.documentFilter = ''
      this.documentFilter2 = ''
      this.filterStatus = false
      this.filterStatus2 = false
    }
  },

  changeFilter(){
      this.removeFilter()
    }, 


  computed: {
    userAuth(){
      return this.$store.state.userAuth
    },
  }
}
</script>
<style>
  
</style>