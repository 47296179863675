<template>
  <div class="w-full h-full relative">
    <actualizar-riesgo v-if="this.sectionStatus == 'actualizar'" :currentClient="this.currentClient" :riesgoId="this.riesgoView" @redirect-policies="redirectPolicies" @update-riesgo="updateRiesgo" @cancelar="cancelar"/>

    <div class="flex justify-center">
      <div class="mr-5">
        <button @click="$emit('closeCategory')" class="py-2 px-10 text-primario border border-primario rounded-xl">Atras</button>
      </div>
      <div class="mr-5">
        <button class="py-2 px-10 text-white bg-primario rounded-xl">Riesgos</button>
      </div>
      <div class="mr-5">
        <button @click="$emit('changeSection', 'polizas')" class="py-2 px-10 text-primario border border-primario bg-white rounded-xl">Polizas</button>
      </div>
    </div>

    <div >
      <div ref="scrollContainer" class="px-10 pt-10 pb-32 w-full h-screen" style="overflow-y:scroll;">
        <div v-if="this.sectionStatus == 'agregar' || this.sectionStatus == 'actualizar'" class="mb-10 flex justify-between items-center">
          <div>
            <p class="text-texto text-3xl font-bold">Riesgos del cliente</p>
            <p class="text-texto text-lg font-bold">Categoria: Generales</p>
            <!-- <p class="text-texto">Clientes registrados</p> -->
          </div>
          <div>
            <div>
              <button @click="openSectionCrear" class="py-2 px-10 text-white bg-primario rounded-xl">Agregar riesgo</button>
            </div>
          </div>
        </div>

        <agregar-riesgo v-if="this.sectionStatus == 'crear'" :currentClient="this.currentClient" @create-riesgo="createRiesgo" @cancelar="cancelar"/>

        <div class="mb-5 flex items-center">
          <div>
            <label for="filter" class="pl-2 text-texto">Ingresa el no. documento del asegurado</label>
            <div class="mt-1 mr-2 px-5 py-1 w-80 flex items-center border border-gray-300 rounded-xl">
              <input v-model="this.documentFilter2" type="search" placeholder="Buscador" name="filter" id="filter" autocomplete="off" class="w-full border-none">
            </div>
            <p class="mt-1 text-red-500">{{this.alertFilter2}}</p>
          </div>

          <div class="mt-6">
            <button  @click="filterRisk2" class="mr-2 py-2 px-2 text-primario border border-primario rounded-xl">
              <i class="flex items-center justify-center text-2xl fi fi-rr-search"></i>
            </button>
          </div>
          <div class="mt-6">
            <button @click="removeFilter" v-if="this.filterStatus2" class="py-2 px-2 flex items-center text-red-500 border border-red-500 rounded-xl">
              <p class="mr-2">Quitar filtro</p>
              <i class="flex items-center justify-center text-lg fi fi-rr-cross-circle"></i>
            </button>
          </div>
        </div>
        
        <div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-left">
              <thead class="text-texto bg-gray-50 ">
                <tr>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">No.</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Asegurado</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Documento</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Nombre</th>
                  <th scope="col" class="pr-6 py-3 text-center font-semibold">Tipo</th>
                  <th scope="col" class="px-6 py-3 text-center font-semibold">Departamento</th>
                  <th scope="col" class="px-6 py-3 text-center font-semibold">Ciudad</th>
                  <th scope="col" class="px-6 py-3 text-center font-semibold">Estado</th>
                </tr>
              </thead>
              <tbody v-if="this.risksFilter.length">
                <tr v-for="(risk, index) in this.risksFilter" :key="index" class="bg-white text-texto border-b cursor-pointer">
                  <td @click="openSectionUpdate(risk.id)" class="pr-6 py-4 text-center font-medium">{{index + 1}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="pr-6 py-4 text-center font-medium" >{{risk.insured_name}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="pr-6 py-4 text-center font-medium" >{{risk.insured_document}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="pr-6 py-4 text-center font-medium">{{risk.fullname}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="pr-6 py-4 text-center font-medium">{{risk.type_risk}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="px-6 py-4 text-center">{{risk.department}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="px-6 py-4 text-center">{{risk.city}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="px-6 py-4 text-center">
                  <div v-if="risk.status" class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">Activo</p></div>
                    <div v-else class="py-1 px-4 rounded-xl bg-gray-100"><p class="text-gray-600">Inactivo</p></div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(risk, index) in this.risks" :key="index" class="bg-white text-texto border-b cursor-pointer">
                  <td @click="openSectionUpdate(risk.id)" class="pr-6 py-4 text-center font-medium">{{index + 1}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="pr-6 py-4 text-center font-medium" >{{risk.insured_name}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="pr-6 py-4 text-center font-medium" >{{risk.insured_document}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="pr-6 py-4 text-center font-medium">{{risk.fullname}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="pr-6 py-4 text-center font-medium">{{risk.type_risk}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="px-6 py-4 text-center">{{risk.department}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="px-6 py-4 text-center">{{risk.city}}</td>
                  <td @click="openSectionUpdate(risk.id)" class="px-6 py-4 text-center">
                  <div v-if="risk.status" class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">Activo</p></div>
                    <div v-else class="py-1 px-4 rounded-xl bg-gray-100"><p class="text-gray-600">Inactivo</p></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../../supabase/init"
import AgregarRiesgo from '@/components/Generales/Riesgos/AgregarRiesgo.vue'
import ActualizarRiesgo from '@/components/Generales/Riesgos/ActualizarRiesgo.vue'
/* import ClienteView from './ClienteView.vue' */
export default {
  components: {AgregarRiesgo, ActualizarRiesgo },
  props: {
    currentClient: Object
  },
  data() {
    return {
      loaderBefore: true,
      filterStatus: false,
      viewClient: false,
      currentUser:{},
      sectionStatus: "agregar",
      risks:[],
      risksFilter:[],
      riesgoView: '',
      proveedorStatus: 'estado',
      
      documentFilter2: '',
      filterStatus2: false,
      alertFilter2: '',

      res1: {},
      res2: {},
    }
  },

  async mounted() {
    await this.getRiesgos()
  },

  methods: {
    async getRiesgos(){
      try{
        const { data, error } = await supabase.from('general_risks').select('*').eq('client_id', this.currentClient.id).order('created_at', { ascending: true })
        this.risks = data

        this.getDepartments()
        this.getCities()

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getDepartments(){
      this.risks.forEach(async (risk) => {
        try{
          const { data, error } = await supabase.from('departments').select('*').eq('id', risk.department_id)
          risk.department = data[0].name.toUpperCase()

          if(error) throw error;
        } catch (error){
          console.log(error.message)
        }
      });
    },

    async getCities(){
      this.risks.forEach(async (risk) => {
        try{
          const { data, error } = await supabase.from('cities').select('*').eq('id', risk.city_id)
          risk.city = data[0].name
  
          if(error) throw error;
        } catch (error){
          console.log(error.message)
        }
      });
    },

    openSectionCrear(){
      this.sectionStatus = "crear"
    },

    openSectionUpdate(id){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      const scrollContainer = this.$refs.scrollContainer;
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
      if (this.riesgoView != "") {
        this.sectionStatus = "agregar"
        this.riesgoView = ""
        setTimeout(() => {
          this.riesgoView = id
          this.sectionStatus = "actualizar"
        }, 100);
      }else{ 
        this.riesgoView = id
        this.sectionStatus = "actualizar"
      }
    },

    createRiesgo(data){
      this.risks.unshift(data)
      this.getDepartments()
      this.getCities()
      this.sectionStatus = "agregar"
    },

    updateRiesgo(){
      this.getRiesgos()
      this.removeFilter()
      this.typeFilter = 'seleccionar'
      this.clientView = ""
      this.sectionStatus = "agregar"
    },

    cancelar(){
      this.sectionStatus = "agregar"
      this.risks = []
      this.getRiesgos()
    },
    
    redirectPolicies(){
      this.sectionStatus = "agregar"
      this.$emit('changeSection', 'polizas')
    },

    closeClient(){
      this.loaderBefore = true
      this.viewClient = false
      this.clientId = ''
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    },

    async filterRisk2(){
      this.risksFilter = this.risks.filter(risk => risk.insured_document == this.documentFilter2)

      if (this.risksFilter.length) {
        this.filterStatus2 = true

      } else {
        let currenData = this.risks
        this.risks = []
        this.alertFilter2 = 'No se encontraron resultados'

        setTimeout(() => {
          this.risks = currenData
          this.alertFilter2 = ''
          this.removeFilter()
        }, 2000);
      }
    },

    removeFilter(){
      this.risksFilter = []
      this.documentFilter2 = ''
      this.filterStatus2 = false
    }
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    },
  }
}
</script>
<style>
  
</style>