<template>
  <div class="w-full h-full relative">
    <div v-if="this.loaderBefore" class="h-full w-full flex flex-col justify-center items-center bg-white absolute z-10">
      <i class="fi fi-rr-spinner flex justify-center items-center text-6xl text-primario animate-spin"></i>
      <p class="w-full max-w-xs text-center mt-5 animate-bounce text-xl">Estamos cargando esta linda familia para ti</p>
    </div>
    <div v-if="this.currentUser.rol == 'Agency' || this.currentUser.rol == 'Administracion'" class="px-10 pt-10 pb-32 w-full h-screen relative" style="overflow-y:scroll;">

      <div v-if="this.sectionStatus == 'agregar'" class="mb-10 flex justify-between items-center">
        <div>
          <p class="text-texto text-3xl font-bold">Equipo</p>
          <p class="text-texto">Equipo administrativo del dashboard</p>
        </div>
        <div>
          <div>
            <button @click="openSectionCrear" class="py-2 px-10 text-white bg-primario rounded-xl">Agregar equipo</button>
          </div>
        </div>
      </div>

      <agregar-equipo v-if="this.sectionStatus == 'crear'" @create-team="createTeam" @cancelar="cancelar"/>
      <actualizar-equipo v-if="this.sectionStatus == 'actualizar'"  @update-team="updateTeam" @cancelar="cancelar" :userId="this.teamView"/>
      
      <div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-left">
            <thead class="text-texto bg-gray-50 ">
              <tr>
                <th scope="col" class="px-6 py-3 text-center font-semibold">Nombre completo</th>
                <th scope="col" class="px-6 py-3 text-center font-semibold">Correo</th>
                <th scope="col" class="px-6 py-3 text-center font-semibold">Contacto</th>
                <th scope="col" class="px-6 py-3 text-center font-semibold">Estado</th>
                <th scope="col" class="px-6 py-3 text-center font-semibold">Rol</th>
                <th scope="col" class="px-6 py-3 text-center font-semibold">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in this.team" :key="index" class="bg-white text-texto border-b cursor-pointer">
                <td class="px-6 py-4 text-center">{{user.fullname}}</td>
                <td class="px-6 py-4 text-center">{{user.email}}</td>
                <td class="px-6 py-4 text-center">{{user.contact_number}}</td>
                <td class="px-6 py-4 text-center">
                  <div v-if="user.status" class="py-1 px-4 rounded-xl bg-green-100"><p class="text-green-400">Activo</p></div>
                  <div v-else class="py-1 px-4 rounded-xl bg-gray-100"><p class="text-gray-600">Inactivo</p></div>
                </td>
                <td class="px-6 py-4 text-center">{{user.rol}}</td>
                <td v-if="user.rol != 'SuperAdmin'" class="px-6 py-4 text-center">
                  <i @click="openSectionUpdate(user.id)" class="flex justify-center items-center text-primario fi fi-rr-eye"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else class="h-full flex justify-center items-center">
      <div class="flex items-center">
        <img class="mr-5 w-20" src="../../../assets/logo.png" alt="">
        <p class="text-5xl text-center text-texto font-bold">LO SENTIMOS <br> <span class="text-2xl">No tienes acceso a esta seccion</span> </p>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "../../../supabase/init"
import AgregarEquipo from '@/components/Equipo/AgregarEquipo.vue'
import ActualizarEquipo from '@/components/Equipo/ActualizarEquipo.vue'
export default {
  components: { AgregarEquipo, ActualizarEquipo },
  data() {
    return {
      loaderBefore: true,
      sectionStatus: "agregar",
      team:[],
      teamView: '',
      proveedorStatus: 'estado',
      currentUser:{},
      res1:{},
      res2:{},
    }
  },

  async mounted() {
    await this.getUser()
    if (this.currentUser.rol == 'Agency') {
      await this.getTeam()
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    }else {
      setTimeout(() => {
        this.loaderBefore = false
      }, 1000);
    }
    
  },

  methods: {
    async getUser(){
      try{
        const { data, error } = await supabase.from('agencies').select('*').eq('id', this.userAuth.user.id)
        this.res1 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      }  

      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('id', this.userAuth.user.id)
        this.res2 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      } 

      if (this.res1) {
        this.currentUser = this.res1
      } else {
        this.currentUser = this.res2 
      }
    },

    async getTeam(){
      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('agency_id', this.userAuth.user.id).order('created_at', { ascending: false })
        this.team = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    openSectionCrear(){
      this.sectionStatus = "crear"
    },

    openSectionUpdate(id){
      if (this.teamView != "") {
        this.sectionStatus = "agregar"
        this.teamView = ""
        setTimeout(() => {
          this.teamView = id
          this.sectionStatus = "actualizar"
        }, 100);
      }else{ 
        this.teamView = id
        this.sectionStatus = "actualizar"
      }
    },

    createTeam(data){
      this.team.unshift(data)
      this.sectionStatus = "agregar"
    },

    updateTeam(){
      this.getTeam()
      this.teamView = ""
      this.sectionStatus = "agregar"
    },

    cancelar(){
      this.sectionStatus = "agregar"
    },
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    },
  }

  
}
</script>
<style>
  
</style>