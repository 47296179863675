<template>
  <div class="w-full h-screen grid overflow-y-hidden" style="grid-template-columns: auto 1fr;">
    <div v-if="this.modalStatus" class="w-full h-full flex justify-center items-center bg-black bg-opacity-20 left-0 top-0 rounded-lg absolute z-50">
      <div v-if="this.termsStatus" class="p-8 w-96 bg-white rounded-lg">
        <p class="mb-3 text-lg text-primario text-center font-bold">Términos y condiciones</p>
        <p class="mb-5 text-texto text-center">Por seguridad, Helprime expide unos términos y condiciones a los empleados para defenderse en caso de abuso con el manejo de la data</p>
        <div class="mb-5 flex items-center">
          <input v-model="this.termsCheck" type="checkbox" id="" class="mr-5 border-primario rounded-md">
          <p @click="openModalTerms" class="text-primario cursor-pointer">Términos y condiciones</p>
        </div>
        <div class="mb-5 w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv" role="alert">
          <svg aria-hidden="true" :class="this.classText" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <div class="transition-all" :class="this.classText">
            <span class="font-medium">Alerta!</span>{{this.textAlert}}
          </div>
        </div>
        <div class="grid grid-cols-2">
          <button @click="closeModal" class="mr-1 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button v-if="this.viewLoader == 'acept'" @click="aceptTerms" class="ml-1 py-2 px-10 text-white rounded-xl" :class="this.bg_button">Aceptar</button>
          <button v-if="this.viewLoader == 'loader' || this.viewLoader == 'acepted' || this.viewLoader == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button">
            <div v-if="this.viewLoader == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
              <p class="mr-3">Cargando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader == 'acepted'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
              <p class="">Aceptado</p>
            </div>
            <div v-if="this.viewLoader == 'error'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
              <p class="">error</p>
            </div>
          </button>
        </div>
      </div>
      <div v-else class="p-8 w-96 h-96 bg-white rounded-lg">
        <button @click="this.termsStatus = true" class="mr-1 py-2 px-10 text-primario border border-primario rounded-xl">Atras</button>
      </div>
    </div>
    <div v-if="this.modalStatus2" class="w-full h-full flex justify-center items-center bg-black bg-opacity-20 left-0 top-0 rounded-lg absolute z-50">
      <div class="p-8 w-96 bg-white rounded-lg">
        <p class="mb-5 text-lg text-primario text-center font-bold">Recuperacion de contraseña</p>
        <div class="mb-6">
          <div class="mb-2 w-full">
            <label for="newPassword" class="text-sm text-texto">Nueva contraseña</label>
            <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
              <input v-model="this.newPassword" type="password" placeholder="Ingresa la nueva contraseña" name="newPassword" class="w-full border-none">
            </div>
          </div>
          <div class="w-full">
            <label for="newPasswordConfirm" class="text-sm text-texto">Confirmar contraseña</label>
            <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
              <input v-model="this.newPasswordConfirm" type="password" placeholder="Confirma la nueva contraseña" name="newPasswordConfirm" class="w-full border-none">
            </div>
          </div>
        </div>
        <div class="mb-5 w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv2" role="alert">
          <svg aria-hidden="true" :class="this.classText2" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <div class="transition-all" :class="this.classText2">
            <span class="font-medium">Alerta!</span>{{this.textAlert2}}
          </div>
        </div>
        <div class="grid grid-cols-2">
          <button @click="closeModalReset" class="mr-1 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button v-if="this.viewLoader2 == 'acept'" @click="resetPassword" class="ml-1 py-2 px-10 text-white rounded-xl" :class="this.bg_button2">Actualizar</button>
          <button v-if="this.viewLoader2 == 'loader' || this.viewLoader2 == 'reset' || this.viewLoader2 == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button2">
            <div v-if="this.viewLoader2 == 'loader'" class=" py-2 px-10 flex justify-center cursor-wait">
              <p class="mr-3">Actualizando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader2 == 'reset'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
              <p class="">Actualizado</p>
            </div>
            <div v-if="this.viewLoader2 == 'error'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
              <p class="">Error</p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div v-if="this.currentUser.rol == 'Agency' && this.currentUser.department_id == null || this.currentUser.department_id == ''" class="w-full h-full flex justify-center items-center bg-black bg-opacity-20 left-0 top-0 rounded-lg absolute z-50">
      <div class="p-8 bg-white rounded-lg" style="max-width: 700px;">
        <p class="mb-5 text-lg text-primario text-center font-bold">Completar informacion</p>
        <div class="mb-5 grid grid-cols-2">
          <div class="pr-5">
            <label for="fullname" class="text-texto">Nombres</label>
            <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
              <i class="mr-2 flex items-center fi fi-rr-user"></i>
              <input v-model="this.formData.fullname" autocomplete="off" type="text" placeholder="Ingresa los nombres" name="fullname" id="fullname" class="w-full text-texto border-none">
            </div>
          </div>
          <div class="pl-5">
            <label for="email" class="text-texto">Correo electronico</label>
            <div class="px-5 py-1 w-full flex items-center bg-gray-50 border border-gray-300 rounded-xl">
              <i class="mr-2 flex items-center fi fi-rr-at"></i>
              <input v-model="this.currentUser.email" autocomplete="off" type="email" disabled placeholder="Ingresa el correo electronico" name="email" id="email" class="w-full bg-gray-50 text-texto border-none">
            </div>
          </div>
        </div>

        <div class="mb-5 grid grid-cols-2">
          <div class="pr-5">
            <label for="whatsapp" class="text-texto">Numero de whatsapp</label>
            <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
              <i class="mr-2 flex items-center fi fi-rr-comment"></i>
              <input v-model="this.formData.whatsapp" autocomplete="off" type="number" placeholder="Ingresa numero de whatsapp" name="whatsapp" id="whatsapp" class="w-full text-texto border-none">
            </div>
          </div>
          <div class="pl-5">
            <label for="contact_number" class="text-texto">Numero de contacto</label>
            <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
              <i class="mr-2 flex items-center fi fi-rr-phone-call"></i>
              <input v-model="this.formData.contact_number" autocomplete="off" type="number" placeholder="Ingresa numero de contacto" name="contact_number" id="contact_number" class="w-full text-texto border-none">
            </div>
          </div>
        </div>

        <div class="mb-5 grid grid-cols-2">
            <div class="mr-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label class="text-texto">Departamento</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-building"></i>
                    <select @change="getCities" name="type_schedule" id="type_schedule" class="w-full text-texto border-none" v-model="this.formData.department_id">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option v-for="(department, index) in this.departments" :key="index" :value="department.id">{{department.name.toUpperCase()}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-5">
              <div class="h-full grid" style="grid-template-row: auto 1fr;">
                <div class="mb-auto">
                  <label class="text-texto">Ciudad</label>
                  <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
                    <i class="mr-2 flex items-center fi fi-rr-home"></i>
                    <select name="type_schedule" id="type_schedule" class="w-full text-texto border-none" v-model="this.formData.city_id">
                      <option selected disabled value="seleccionar">Seleccionar</option>
                      <option v-for="(city, index) in this.cities" :key="index" :value="city.id">{{city.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div class="mb-5 w-full flex">
          <div class="w-full">
            <label for="web" class="text-texto">Url web</label>
            <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
              <i class="mr-2 flex items-center text-texto fi fi-rr-globe"></i>
              <input v-model="this.formData.web" type="text" autocomplete="off" placeholder="Ingresa los nombres" name="web" id="web" class="w-full text-texto border-none">
            </div>
          </div>
        </div>
        <div class="mb-5 w-full flex">
          <div class="w-full">
            <label for="description" class="text-texto">Descripcion</label>
            <div class="px-5 py-1 w-full flex items-center border border-gray-300 rounded-xl">
              <textarea v-model="this.formData.description" name="description" id="description" autocomplete="off"  rows="3" placeholder="Ingresa aqui la descripcion de tu negocio para que la gente sepa que realizas" class="w-full text-texto border-none"></textarea>
            </div>
          </div>
        </div>

        <p class="text-center text-red-500">Recuerda ir a <strong>Ajustes</strong> y colocar la foto de tu agencia para que sea visible en <strong>HELPRIME</strong></p>

        <div class="mb-5 w-full flex">
          <div class="w-full">
            <div class="w-full flex text-sm text-red-700 bg-red-100 rounded-lg top-5 right-5 transition-all" :class="this.classDiv3" role="alert">
              <svg aria-hidden="true" :class="this.classText3" class="inline flex-shrink-0 mr-3 w-5 h-5 transition-all" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <div class="transition-all" :class="this.classText3">
                <span class="font-medium">Alerta!</span>{{this.textAlert3}}
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2">
          <button @click="closeModalInfo" class="mr-1 py-2 px-10 text-primario border border-primario rounded-xl">Cancelar</button>
          <button v-if="this.viewLoader3 == 'update'" @click="updateInfoAgency" class="ml-1 py-2 px-10 text-white rounded-xl" :class="this.bg_button3">Aceptar</button>
          <button v-if="this.viewLoader3 == 'loader' || this.viewLoader3 == 'updated' || this.viewLoader3 == 'error'" class="ml-1 text-white rounded-xl" :class="this.bg_button3">
            <div v-if="this.viewLoader3 == 'loader'" class="ml-1 py-2 px-10 flex justify-center cursor-wait">
              <p class="mr-3">Actualizando</p>
              <div class="flex justify-center items-center">
                <span class="loader"></span>
              </div>
            </div>
            <div v-if="this.viewLoader3 == 'updated'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
              <p class="">Actualizado</p>
            </div>
            <div v-if="this.viewLoader3 == 'error'" class="px-2 py-2 w-full flex justify-center cursor-pointer">
              <p class="">error</p>
            </div>
          </button>
        </div>
      </div>
    </div>
    
    <Sidebar />
    <div class="h-screen overflow-y-auto">
      <router-view  />
    </div>
  </div>
</template>

<script>
import { supabase } from "../supabase/init"
import router from '@/router'
import Sidebar from '@/components/layout/Sidebar.vue'
// @ is an alias to /src

export default {
  name: 'DashboardView',
  components: {
    Sidebar,
  },

  data() {
    return {
      newPassword: '',
      newPasswordConfirm: '',
      
      currentUser: {},
      termsStatus: true,
      termsCheck: false,
      
      modalStatus: false,
      viewLoader: 'acept',
      bg_button: 'bg-primario',
      classDiv: "w-0 p-0",
      classText: "hidden",
      textAlert: "",

      modalStatus2: false,
      viewLoader2: 'acept',
      bg_button2: 'bg-primario',
      classDiv2: "w-0 p-0",
      classText2: "hidden",
      textAlert2: "",

      date:'',
      departments:[],
      cities:[],

      formData:{
        fullname: "",
        whatsapp: "",
        contact_number: "",
        web: "",
        description: "",
        city_id: "seleccionar",
        department_id: "seleccionar",
      },

      res1: {},
      res2: {},

      viewLoader3: 'update',
      bg_button3: 'bg-primario',
      classDiv3: "w-0 p-0",
      classText3: "hidden",
      textAlert3: "",

      currentVencimiento:{},
    }
  },

  
  async mounted() {
    await this.getUser()
    if (!this.currentUser.terms_conditions) {
      this.modalStatus = true
    }
    await this.getDepartments()
  },

  methods: {
    async getUser(){
      try{
        const { data, error } = await supabase.from('agencies').select('*').eq('id', this.userAuth.user.id)
        this.res1 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      }  

      try{
        const { data, error } = await supabase.from('team_agencies').select('*').eq('id', this.userAuth.user.id)
        this.res2 = data[0]
        if(error) throw error;
      } catch (e){
        console.log(e.message)
      } 

      if (this.res1) {
        this.currentUser = this.res1
        this.formData.fullname = this.currentUser.fullname
      } else {
        this.currentUser = this.res2 
      }
    },

    async getDepartments(){
      try{
        const { data, error } = await supabase.from('departments').select('*').order('name', { ascending: true })
        this.departments = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async getCities(){
      try{
        const { data, error } = await supabase.from('cities').select('*').eq('department_id', this.formData.department_id).order('name', { ascending: true })
        this.cities = data

        if(error) throw error;
      } catch (error){
        console.log(error.message)
      }
    },

    async closeModal(){
      await supabase.auth.signOut()
      router.push("/login")
    },

    async closeModalInfo(){
      await supabase.auth.signOut()
      router.push("/login")
    },

    async closeModalReset(){
      await supabase.auth.signOut()
      router.push("/login")
    },

    currentDate(){
      let today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      this.date = `${year}-${month}-${day}`
    },

    openModalTerms(){
      this.termsStatus = false
    },

    async aceptTerms(){
      this.viewLoader = 'loader'
      if (this.termsCheck) {
        this.currentDate()

        if (this.res1) {
          try {
            let { data, error } = await supabase.from('agencies').update({
            terms_conditions: this.termsCheck,
            acept_terms: this.date,
          }).eq('id', this.currentUser.id)

          setTimeout(() => {
            this.viewLoader = 'acepted'
            this.bg_button = 'bg-green-400'

            setTimeout(() => {
              this.modalStatus = false
              this.viewLoader = 'acept'
              this.bg_button = 'bg-primario'
            }, 3000);
          }, 3000);

          if(error) throw error;
          } catch(error){
            console.log(error.message)
          }
        } else {
          try {
            let { data, error } = await supabase.from('team_agencies').update({
            terms_conditions: this.termsCheck,
            acept_terms: this.date,
          }).eq('id', this.currentUser.id)

          setTimeout(() => {
            this.viewLoader = 'acepted'
            this.bg_button = 'bg-green-400'

            setTimeout(() => {
              this.modalStatus = false
              this.viewLoader = 'acept'
              this.bg_button = 'bg-primario'
            }, 3000);
          }, 3000);

          if(error) throw error;
          } catch(error){
            console.log(error.message)
          }
        }
      
      }else{
        setTimeout(() => {
          this.viewLoader = 'error'
          this.bg_button = 'bg-red-400'
          this.classDiv = "w-72 p-4"
          this.classText = "block"
          this.textAlert = " No puede seguir sin aceptar los términos y condiciones."
  
          setTimeout(() => {
            this.viewLoader = 'acept'
            this.bg_button = 'bg-primario'
            this.classDiv = "w-0 p-0"
            this.classText = "hidden"
          }, 4000);
        }, 1000);
      }
    },

    async resetPassword(){
      this.viewLoader2 = 'loader'
      if (this.newPassword != '' && this.newPasswordConfirm != '') {
        if (this.newPassword == this.newPasswordConfirm) {
          try {
            const { error, data } = await supabase.auth.update({password: this.newPassword,})
            setTimeout(() => {
              this.viewLoader2 = 'reset'
              this.bg_button2 = 'bg-green-400'
              setTimeout(() => {
                this.modalStatus2 = false
                this.viewLoader2 = 'acept'
                this.bg_button2 = 'bg-primario'
              }, 3000);
            }, 2000);

            if(error) throw error;
          } catch (error) {
            console.log(error.message);
          }
          
        }else{
          setTimeout(() => {
            this.viewLoader2 = 'error'
            this.bg_button2 = 'bg-red-400'
            this.classDiv2 = "w-72 p-4"
            this.classText2 = "block"
            this.textAlert2 = " Las incotraseñas ingresadas no coinciden."
    
            setTimeout(() => {
              this.viewLoader2 = 'acept'
              this.bg_button2 = 'bg-primario'
              this.classDiv2 = "w-0 p-0"
              this.classText2 = "hidden"
            }, 4000);
          }, 1000);
        }
      }else {
        setTimeout(() => {
          this.viewLoader2 = 'error'
          this.bg_button2 = 'bg-red-400'
          this.classDiv2 = "w-72 p-4"
          this.classText2 = "block"
          this.textAlert2 = " No se admiten campos vacios."
  
          setTimeout(() => {
            this.viewLoader2 = 'acept'
            this.bg_button2 = 'bg-primario'
            this.classDiv2 = "w-0 p-0"
            this.classText2 = "hidden"
          }, 4000);
        }, 1000);
      }
    },

    async updateInfoAgency(){
      this.viewLoader3 = 'loader'

      if (this.formData.fullname != '' && this.formData.whatsapp != '' && this.formData.contact_number != '' && this.formData.web != '' ){
        try{
          let { data, error } = await supabase.from('agencies').update({
            fullname: this.formData.fullname,
            whatsapp: this.formData.whatsapp,
            contact_number: this.formData.contact_number,
            web: this.formData.web,
            description: this.formData.description,
            department_id: this.formData.department_id,
            city_id: this.formData.city_id,
          }).eq('id', this.currentUser.id)

          setTimeout(() => {
            this.viewLoader3 = 'updated'
            this.bg_button3 = 'bg-green-400'
    
            setTimeout(() => {
              this.viewLoader3 = 'update'
              this.bg_button3 = 'bg-primario'
              this.classDiv3 = "w-0 p-0"
              this.classText3 = "hidden"
              router.push("/login")
            }, 3000);
          }, 1000);

          if(error) throw error;
        } catch(error){
          console.log(error.message)
        }
      } else {
        setTimeout(() => {
          this.viewLoader3 = 'error'
          this.bg_button3 = 'bg-red-400'
          this.classDiv3 = "w-72 p-4"
          this.classText3 = "block"
          this.textAlert3 = " No se admiten campos vacios."
  
          setTimeout(() => {
            this.viewLoader3 = 'update'
            this.bg_button3 = 'bg-primario'
            this.classDiv3 = "w-0 p-0"
            this.classText3 = "hidden"
          }, 3000);
        }, 1000);
      }
      
    },

    /* changeSectionVencimientos(url){
      this.currentVencimiento = url
    }, */
  },

  computed: {
    userAuth(){
      return this.$store.state.userAuth
    },

    eventAuth(){
      return this.$store.state.eventAuth
    }
  }
}
</script>
<style >
  .loader {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #FFF;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
</style>
