<template>
  <mis-clientes v-if="this.route == 'clientes'" />
  <vencimientos v-if="this.route == 'vencimientos'"/>
  <mi-perfil v-if="this.route == 'mi-perfil'"/>
  <asistencia v-if="this.route == 'asistencia'"/>
  <notificaciones v-if="this.route == 'notificaciones'"/>
  <tickets v-if="this.route == 'tickets'"/>
  <equipo v-if="this.route == 'equipo'"/>
  <produccion v-if="this.route == 'produccion'"/>
  <ajustes v-if="this.route == 'ajustes'"/>
</template>
<script>
import MisClientes from './dashboard/menu/MisClientes.vue'
import Vencimientos from './dashboard/menu/Vencimientos.vue'
import Ajustes from './dashboard/menu/Ajustes.vue'
import MiPerfil from './dashboard/menu/MiPerfil.vue'
import Asistencia from './dashboard/menu/Asistencia.vue'
import Notificaciones from './dashboard/menu/Notificaciones.vue'
import Equipo from './dashboard/menu/Equipo.vue'
import Tickets from './dashboard/menu/Tickets.vue'
import Produccion from './dashboard/menu/Produccion.vue'
export default {
  components: { MisClientes, Vencimientos, Ajustes, MiPerfil, Asistencia, Notificaciones, Tickets, Equipo, Produccion },
  data() {
    return {
    }
  },

  methods: {
    /* changeSectionVencimientos(url){
      this.$emit("changeSectionVencimientos", url)
    }, */
  },

  computed:{
    route(){
      return this.$store.state.currentRoute
    }
  }
}
</script>
<style>
  
</style>